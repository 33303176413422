import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  globalDataLoader=false;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  formatPrice(price) {
    return this.formatter.format(price); /* $2,500.00 */
  }
  statesList: any = [
    {
      "id": 1,
      "name": "Alabama",
      "code": "AL"
    },
    {
      "id": 2,
      "name": "Alaska",
      "code": "AK"
    },
    {
      "id": 3,
      "name": "Arizona",
      "code": "AZ"
    },
    {
      "id": 4,
      "name": "Arkansas",
      "code": "AR"
    },
    {
      "id": 5,
      "name": "California",
      "code": "CA"
    },

    {
      "id": 6,
      "name": "Colorado",
      "code": "CO"
    },
    {
      "id": 7,
      "name": "Connecticut",
      "code": "CT"
    },
    {
      "id": 8,
      "name": "Delaware",
      "code": "DE"
    },
    {
      "id": 9,
      "name": "District of Columbia",
      "code": "DC"
    },
    {
      "id": 10,
      "name": "Florida",
      "code": "FL"
    },
    {
      "id": 11,
      "name": "Georgia",
      "code": "GA"
    },
    {
      "id": 12,
      "name": "Hawaii",
      "code": "HI"
    },
    {
      "id": 13,
      "name": "Idaho",
      "code": "ID"
    },
    {
      "id": 14,
      "name": "Illinois",
      "code": "IL"
    },
    {
      "id": 15,
      "name": "Indiana",
      "code": "IN"
    },
    {
      "id": 16,
      "name": "Iowa",
      "code": "IA"
    },
    {
      "id": 17,
      "name": "Kansas",
      "code": "KS"
    },
    {
      "id": 18,
      "name": "Kentucky",
      "code": "KY"
    },
    {
      "id": 19,
      "name": "Louisiana",
      "code": "LA"
    },
    {
      "id": 20,
      "name": "Maine",
      "code": "ME"
    },
    {
      "id": 21,
      "name": "Maryland",
      "code": "MD"
    },
    {
      "id": 22,
      "name": "Massachusetts",
      "code": "MA"
    },

    {
      "id": 23,
      "name": "Michigan",
      "code": "MI"
    },
    {
      "id": 24,
      "name": "Minnesota",
      "code": "MN"
    },
    {
      "id": 25,
      "name": "Mississippi",
      "code": "MS"
    },
    {
      "id": 26,
      "name": "Missouri",
      "code": "MO"
    },
    {
      "id": 27,
      "name": "Montana",
      "code": "MT"
    },
    {
      "id": 28,
      "name": "Nebraska",
      "code": "NE"
    },
    {
      "id": 29,
      "name": "Nevada",
      "code": "NV"
    },
    {
      "id": 30,
      "name": "New Hampshire",
      "code": "NH"
    },
    {
      "id": 31,
      "name": "New Jersey",
      "code": "NJ"
    },
    {
      "id": 32,
      "name": "New Mexico",
      "code": "NM"
    },
    {
      "id": 33,
      "name": "New York",
      "code": "NY"
    },
    {
      "id": 34,
      "name": "North Carolina",
      "code": "NC"
    },
    {
      "id": 35,
      "name": "North Dakota",
      "code": "ND"
    },
    {
      "id": 36,
      "name": "Ohio",
      "code": "OH"
    },
    {
      "id": 37,
      "name": "Oklahoma",
      "code": "OK"
    },
    {
      "id": 38,
      "name": "Oregon",
      "code": "OR"
    },
    {
      "id": 39,
      "name": "Pennsylvania",
      "code": "PA"
    },
    {
      "id": 40,
      "name": "Rhode Island",
      "code": "RI"
    },
    {
      "id": 41,
      "name": "South Carolina",
      "code": "SC"
    },
    {
      "id": 42,
      "name": "South Dakota",
      "code": "SD"
    },
    {
      "id": 43,
      "name": "Wyoming",
      "code": "WY"

    },
    {
      "id": 44,
      "name": "Tennessee",
      "code": "TN"
    },
    {
      "id": 45,
      "name": "Texas",
      "code": "TX"
    },

    {
      "id": 46,
      "name": "Utah",
      "code": "UT"
    },
    {
      "id": 47,
      "name": "Vermont",
      "code": "VT"
    },
    {
      "id": 48,
      "name": "Virginia",
      "code": "VA"
    },
    {
      "id": 49,
      "name": "Washington",
      "code": "WA"
    },
    {
      "id": 50,
      "name": "West Virginia",
      "code": "WV"
    },
    {
      "id": 51,
      "name": "Wisconsin",
      "code": "WI"
    }
  ];
  cities: any = ['Amsterdam'];
  countriesList: any = [{ name: 'USA' }];


  constructor(
    private _location: Location,
    private http: HttpClient,
    private router: Router,
    private store: StoreService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  currentUser$: any;
  currency$ = environment.currency;
  logo_svg$ = environment.logo_svg;
  auth_bg$ = environment.auth_bg;


  register(data: any): Observable<any> {
    let requestData = JSON.stringify({
      companyFDIN: data.companyFDIN,
      companyName: data.companyName,
      defaultBilling: {
        addLine1: data.shippingAddress.addLine1,
        addLine2: data.shippingAddress.addLine2,
        addState: data.shippingAddress.addState,
        addressType: 'DEFAULT_SHIPPING',
        city: data.shippingAddress.city,
        country: data.shippingAddress.country,
        customerFullName: data.fullname,
        lattitude: 0,
        longitude: 0,
        zipCode: data.shippingAddress.zipCode,
      },
      defaultShipping: {
        addLine1: data.billingAddress.addLine1,
        addLine2: data.billingAddress.addLine2,
        addState: data.billingAddress.addState,
        addressType: 'DEFAULT_BILLING',
        city: data.billingAddress.city,
        country: data.billingAddress.country,
        customerFullName: data.fullname,
        lattitude: 0,
        longitude: 0,
        zipCode: data.billingAddress.zipCode,
      },
      desiredRole: data.desiredRole,
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
        user_id: 0,
      },
      email: data.email,
      fullname: data.fullname,
      loginToken: '',
      merchant: false,
      dob: 0,
      password: data.password,
      // phone: data.countryCode + data.phone,
      storeId: environment.storeId,
      username: data.phone,
    });

    const url = environment.rootCloudUrl + 'auth/registerUserWithAddress';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  registerAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      email: data.email,
      loginToken: data.loginToken,
      merchant: true,
      password: data.password,
      username: data.username,
    });
    const url = environment.rootCloudUrl + 'merchant/registerMerchant';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  registerUserAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      email: data.email,
      loginToken: data.loginToken,
      merchant: true,
      password: data.password,
      username: data.username,
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'merchant/registerUserAsMerchant';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  addNewStore(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      loginToken: data.loginToken,
      masterStore: false,

      storeEmailPrefix: data.storeEmailPrefix,


      merchantPhoneNo: data.username,
      phoneNumbers: [],
      storeEmployees: [],

      storeAccessPassword: data.password,
      storeEnabled: true,
      storeName: data.userFirstName + ' ' + data.userLastName,
      storeOwnerName: data.userFirstName + ' ' + data.userLastName,
      storeRunningCost: 0,
      storeType: 'Auto',
      storeId: localStorage.getItem('storeId')
    });

    const url = environment.rootCloudUrl + 'merchant/addNewStore';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  verifyOtp(data: any): Observable<any> {
    let requestData = JSON.stringify(
      {
        password: '',
        phoneNumber: data.mobile,
        token: data.otp,
        storeId: environment.storeId,
        deviceFingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
      }
      //   {
      //   deviceFingerprint: {
      //     createdAt: 0,
      //     deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      //     jsonOtherInfo: 'string',
      //     user_id: 0,
      //   },
      //   password: '',
      //   phoneNumber: data.mobile,
      //   storeId: environment.storeId,
      //   token: data.otp,
      // }
    );

    const url = environment.rootCloudUrl + 'auth/verifyOtp';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  login(data: any): Observable<any> {
    let requestData = JSON.stringify({
      password: data.password,
      storeId: environment.storeId,
      usernameOrEmail: data.usernameOrEmail,
    });
    const url = environment.rootCloudUrl + 'auth/login';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }


  saveContactUs(data: any): Observable<any> {
    let requestData = {
      "fingerprint": {
        "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
        "jsonOtherInfo": ""
      },
      "fullname": data.fullname,
      "phoneNumber": "",
      "responses": {
        "message": data.message
      },
      "storeId": environment.storeId,
      "userEmail": data.userEmail
    };

    const url = environment.rootCloudUrl + 'store/web/saveContactUs';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  validateToken(data: any): Observable<any> {
    let requestData = JSON.stringify({
      fingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      loginToken: data.loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/validateToken';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  passwordReset(data: any): Observable<any> {
    let requestData = JSON.stringify({
      phoneNumber: data.phoneNumber,
      password: data.password,
      storeId: environment.storeId,
      token: data.token,
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
    });
    const url = environment.rootCloudUrl + 'auth/passwordReset';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  sendOtp(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      storeId: environment.storeId,
      password: '',
      phoneNumber: data.mobile,
      token: '',
    });
    const url = environment.rootCloudUrl + 'auth/sendOtp';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  goBack() {
    this._location.back();
  }

  logout() {

    this.spinner.show();
    let t = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;
    this.spinner.show();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('deviceFingerPrint');
    localStorage.removeItem('usernameOrEmail');
    localStorage.removeItem('currentUserProfile');
    localStorage.removeItem('loginToken');
    localStorage.removeItem('cartId');
    localStorage.removeItem('deviceFingerPrint');
    localStorage.removeItem('userAddress');
    localStorage.removeItem('signupUserData');
    localStorage.removeItem('userMobileNumber');
    localStorage.removeItem('company_name');
    localStorage.removeItem('storeId');
    localStorage.removeItem('deviceFingerPrint');
    localStorage.removeItem('deviceFingerPrintJson');
    localStorage.removeItem('txnId');




    this.store.cartProducts$ = [];
    this.store.totalMessages$ = 0;
    this.store.totalCart$ = 0;
    this.router.navigate(['/home']).then((res) => {
      this.currentUser$ = null;
      this.store.viewCart();
      this.spinner.hide();
    });

  }


  backendLogout(t: any): Observable<any> {
    let requestData = {};
    const url = environment.rootCloudUrl + 'store/logout';
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + t,
      }),
    };
    return this.http
      .get(url, httpOptions1)
      .pipe(map((response: Response) => response));

  }
  getCurrentUser() {
    let data = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUser$ = data;
    this.store.getAllMessages();
  }

}
