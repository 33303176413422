import { HttpHeaders } from '@angular/common/http';
export const environment = {

  production: true,

  //staging url
  // rootCloudUrl: 'https://ncr2.com/trg-v1.0/encriss/v1/',
  // storeId: '86110a3d-f8ba-41c9-9eae-29c04c836ff3',

  // PROD URL
  rootCloudUrl: 'https://api.salvagescout.com:8443/trg-v1.0/encriss/v1/',
  storeId: 'f0611c31-832a-4e5c-a84b-0b6c5b5ba9ae',

  // Config variables
  currency: '$',
  auth_bg: 'https://salvagescout.s3.amazonaws.com/theretailgrid/2/1/tagFolder/1642671452898_1.png',
  logo_svg: 'https://salvagescout.s3.amazonaws.com/theretailgrid/2/1/tagFolder/1642671556623_logo.svg',
  no_store: 'https://salvagescout.s3.amazonaws.com/theretailgrid/2/1/tagFolder/1643876087779_store.png',
  no_products: 'https://salvagescout.s3.amazonaws.com/theretailgrid/2/1/tagFolder/1643875972685_no-shopping-cart.png',
  globalPushNotificationsTopic: 'ecommerce_app',
};


export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
