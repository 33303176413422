import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  constructor(
    public auth: AuthService,
    public store: StoreService,
    public router: Router,
    
    public toastController: ToastController,
    private activatedRoute: ActivatedRoute,
  ) { 

    this.activatedRoute.params.subscribe((res) => {

      if (localStorage.getItem('loginToken') && localStorage.getItem('loginToken') !== null && localStorage.getItem('loginToken') !== 'null') {
        const data = {
          loginToken: localStorage.getItem('loginToken')
        };
        this.auth.validateToken(data).subscribe(
          (result) => {
            if (!result) {
              this.warningToast('Session timeout, Login Again to continue!');
         
              this.auth.logout();
              this.router.navigate(["/auth/login"]);
            }else{
              this.store.viewCart();
              this.store.getAllMessages();
            }
          },
          (err) => {
            this.warningToast('Session timeout, Login Again to continue!');
        
            this.auth.logout();
            this.router.navigate(["/auth/login"]);
          }
        );
     
      }

    });


  }
  async warningToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-warning-toast',
    });
    toast.present();
  }
  categoryId: any;
  currentUser: any;
  @Input() hideSeconderyMenu;
  pageName: any;
  searchForm: FormGroup;
  ngOnInit() {


    if (this.router.url.split('/')[1]) {
      this.pageName = this.router.url.split('/')[1];
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.searchForm = new FormGroup({
      searchInput: new FormControl(),
    });
    this.store.getCategories();
    this.store.viewCart();
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (t === null) {
      return;
    } else {
      this.auth.getCurrentUser();
    }
  }
  onSubmit() {
    this.router.navigate(['/browse-listing/ALL']);
  }
  getProducts(id, subId) {
    let data = {
      categoryId: id,
      filter: 'HotSelling',
      subCategoryId: subId,
      pageNo: '0',
      pageSize: 9,
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'Desc',
    };
    this.store.getProduuctsByCategoryId(data);
  }
}
