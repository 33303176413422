import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home-banner-sell',
  templateUrl: './home-banner-sell.component.html',
  styleUrls: [
    './home-banner-sell.component.scss',
    '../home-banner-buy/home-banner-buy.component.scss',
  ],
})
export class HomeBannerSellComponent implements OnInit {
  currentUser: any;
  constructor(
    // public auth: AuthService,
    public store: StoreService
  ) {}

  @Input('bannerInfo') banner: any;
  ngOnInit() {
  //   this.currentUser = JSON.parse(localStorage.getItem('currentUserProfile'));
  //   var t = JSON.parse(localStorage.getItem('currentUserProfile'));
  //   if (t === null) {
  //     return;
  //   } else {
  //     this.auth.getCurrentUser();
  //   }
  }
}
