import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { NgZone } from '@angular/core';

import swal from 'sweetalert2';
declare var Stripe;
@Component({
  selector: 'app-mail-box',
  templateUrl: './mail-box.component.html',
  styleUrls: ['./mail-box.component.scss'],
})
export class MailBoxComponent implements OnInit {
  @Output() onLoad = new EventEmitter();
  @Output() closeMailBox = new EventEmitter();

  @Input('lastOpenedMessageContent') lastOpenedMessageContent: any;
  @Input('lastOpenedMessageKey') lastOpenedMessageKey: any;

  @Input('lastOpenedMessageRecipientUsername')
  lastOpenedMessageRecipientUsername: any;

  @Input('lastOpenedMessages') lastOpenedMessages: any;
  ClickedOut(event) {
    if (
      event.target.className === 'emoji-mart-emoji' &&
      event.target.className === ''
    ) {
      this.showEmojiPicker = true;
    } else if (
      event.target.className === 'email-box' ||
      event.target.className === 'email-data' ||
      event.target.className === 'emoji-popup' ||
      event.target.className === 'native-textarea sc-ion-textarea-md' ||
      event.target.className === 'ng-star-inserted' ||
      event.target.className === 'mail-data' ||
      event.target.className === 'comment-box'
    ) {
      this.showEmojiPicker = false;
    }
  }
  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private zone: NgZone
  ) {}

  ngOnInit() {}

  goBack() {
    this.closeMailBox.emit();
  }
  message: any = '';
  showEmojiPicker: boolean = false;
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    const { message } = this;
    const text = `${message}${event.emoji.native}`;
    this.message = text;
  }
  public sendMessage(form: any) {
    if (form.value.message === undefined) {
      this.toaster.error('Invalid message!');
      return;
    }
    const data = {
      loginToken: localStorage.getItem('loginToken'),
      message: form.value.message,
      parentMessageKey: this.lastOpenedMessageKey,
      productId: '',
      recipientUsername: this.lastOpenedMessageRecipientUsername,
    };
    //  this.spinner.show();

    this.store.sendMessage(data).subscribe(
      (res) => {
        form.reset();
        this.spinner.hide();
        this.toaster.success(res.Status);
        this.goBack();
      },
      (err: any) => {
        this.spinner.hide();

        //this.presentToast(err);
        if (err.error.text) {
          this.toaster.error(err.error.text);
        } else {
          this.toaster.error(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }
}
