import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { AddressDialogComponent } from '../../address-dialog/address-dialog.component';
import { CountriesAndStatesService } from 'src/app/services/countries-and-states.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss'],
})
export class MyCartComponent implements OnInit {
  addressToggle: boolean = false;
  constructor(
    public toaster: ToastrService,
    public store: StoreService,
    private toastr: ToastrService,
    route: ActivatedRoute,

    public spinner: NgxSpinnerService,
    public router: Router,
    public dialog: MatDialog,
    private countriesServices: CountriesAndStatesService,
    private auth: AuthService
  ) {
    if (!this.auth.currentUser$) {
      this.router.navigate(['/auth/login']);
    }
    //this.onLoad();
    route.params.subscribe(val => {

      if (localStorage.getItem('cartId')) {
        this.store.resetCartShippingData(localStorage.getItem('cartId')).subscribe((res: any) => {

        });
      }
      
    });
  }

  ionViewDidEnter() {
    //  this.onLoad();

  }


  cartRecreate() {
    this.store.viewCart();
    this.getUserAddressesList();

    this.getShippingOptions();

  }
  cartRecreateFromShipping() {
    this.store.viewCart();
    this.getUserAddressesList();

  }
  cartRecreateFromAddress() {
    this.store.viewCart();
    this.getShippingOptions();
  }

  onLoad() {
    this.store.viewCart();
    this.selectedShippingOption.value = "none";


    this.address.addressType = 'SHIPPING';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (localStorage.getItem('accessToken') === null) {

      return;
    } else {
      this.cartRecreate();
      // if (this.userAddressesList) {
      //   if (this.userAddressesList.length) {
      //     this.mapAddressToCart(this.store.cart$.shippingAddress.addressId, this.getAddressIndex(this.store.cart$.shippingAddress.addressId));
      //   }
      // }

      // try {
      //   setTimeout(() => {
      //     if (this.shippingOptions) {
      //       if (this.shippingOptions.length) {
      //         if (this.store.cart$?.shippingOption) {
      //           if (this.store.cart$.shippingOption?.rateId) {
      //             this.selectedShippingOption.value = this.getShippingOptionIndex(this.store.cart$.shippingOption.rateId);
      //             this.mapShippingToCart(this.getShippingOptionIndex(this.store.cart$.shippingOption.rateId));
      //           }
      //         }
      //       }
      //     }
      //   }, 5000);
      // } catch (error) {
      //   //do nothing
      // }


    }
  }
  getAddressIndex(addressId) {
    let address_index = null;
    this.userAddressesList.forEach((element, index) => {
      if (element.addressId === addressId) {
        address_index = index;
        return address_index;
      }
    });

    return address_index;
  }

  getShippingOptionIndex(rateId) {
    let shipping_index = null;
    this.shippingOptions.forEach((element, index) => {
      if (element.rateId === rateId) {
        shipping_index = index;
        return shipping_index;
      }
    });
    return shipping_index;
  }

  addressList = {
    firstName: '',
  };

  selectCountryValue: any;
  selectStateValue: any;
  userAddressesList: any = [];
  address: any = {};
  ngOnInit() {
    // this.onLoad();

    // this.countriesServices.getAllCountries().subscribe((res: any) => {
    //   this.countriesList = [{ country: 'USA' }];
    // });
    // let data = {
    //   country: 'united states',
    // };
    // this.countriesServices.getStatesByCountry(data).subscribe((res: any) => {
    //   this.statesList = res.data.states;
    // });
    // let citiesReq = {
    //   country: 'united states',
    //   state: 'Alabama',
    // };
    // this.countriesServices.getCitiesByState(citiesReq).subscribe((res: any) => {
    //   this.cities = res.data;
    // });
  }

  statesList = this.auth.statesList;
  cities = this.auth.cities;
  countriesList = this.auth.countriesList;

  contactInformationForm(form: any) {
    let data: any = {
      addLine1: form.value.address1,
      addLine2: form.value.address2,
      addState: this.selectStateValue,
      addressType: this.address.addressType,
      city: form.value.city,
      country: this.selectCountryValue,
      customerFullName: form.value.firstName + ' ' + form.value.lastName,
      zipCode: form.value.zipCode,
    };

    this.store.addCustomerAddress(data).subscribe((res) => {
      if (res) {
        this.toaster.success('Address added!');
        this.getUserAddressesList();
        form.reset();
      } else {
        this.toaster.warning('Something went wrong');
      }
      return this.userAddressesList;
    });
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  deleteAddress(addressId) {
    let data = {
      addressId: addressId,
    };
    this.store.deleteUserAddress(data).subscribe((res: any) => {
      if (res === true) {
        this.toaster.success('Address deleted!');
        this.getUserAddressesList();
      } else {
        this.toaster.warning('Something went wrong');
        this.getUserAddressesList();
      }
    });
  }

  selectCityValue: any;
  changeCountry(val) {
    let data = {
      country: 'United States',
    };
    this.countriesServices.getStatesByCountry(data).subscribe((res: any) => {
      this.statesList = res.data.states;
    });
  }
  changeState(val) {
    this.selectStateValue = val;
    let citiesReq = {
      country: 'United States',
      state: this.selectStateValue,
    };
    this.countriesServices.getCitiesByState(citiesReq).subscribe((res: any) => {
      this.cities = res.data;
    });
  }
  changeCity(val) {
    this.selectCityValue = val.detail.value;
  }
  getUserAddressesList() {
    this.store.getUserAddressList().subscribe((res) => {
      this.userAddressesList = res;
    });
  }

  shippingOptions = null;
  getShippingOptions() {

    let cartId = localStorage.getItem('cartId');
    if (!cartId) {

      this.shippingOptions = [];
      return;
    }

    this.shippingOptions = null;
    this.store.getShippingOptions().subscribe((res: any) => {
      if (res?.rates)
        this.shippingOptions = res.rates;
      else
        this.shippingOptions = [];
    });
  }
  mapAddressToCart(id, index) {
    let data = {
      addressId: id,
    };
    this.store.mapAddressToCart(data).subscribe((res) => {
      this.toaster.success('Shipping address changed!');
      localStorage.setItem(
        'userAddress',
        JSON.stringify(this.userAddressesList[index])
      );

      this.cartRecreateFromAddress();

    });
  }

  selectedShippingOption: any = {};

  mapShippingToCart(index, event = null) {
    if (index === 'none' || !index) {
      return;
    }
    let data = {
      shippingOption: this.shippingOptions[index]
    };

try {
  this.store.mapShippingToCart(data).subscribe((res) => {
    if (res) {
      this.selectedShippingOption.value = index;
      this.toaster.success('Shipping option changed!');
      this.cartRecreateFromShipping();
    } else {
      this.toaster.warning("Unable to fetch shipping details from vendor, Reloading the page!")
      window.location.reload();
    }
  });
} catch (error) {
  this.toastr.warning('Unable to map shipping option, Try again!');
   this.spinner.hide();
}
    
  
  }

  isVisible: any = false;
  changeShippingMethod(val) {
    if (val === 'Freight') {
      this.isVisible = false;
    } else {
      this.isVisible = true;
    }
  }
  productDetails: any;
  addToCart() {

    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.cartRecreate();
        this.toastr.success('Added to cart');
      }
    });
  }
  prodCount: any;
  checkInCart(product: any) {
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          this.prodCount = element.quantity;
        }
      }
    } else {
      this.prodCount = 0;
    }
    return this.prodCount;
  }
  addQuantity(product, qty) {

    if (+qty >= product.productAvailableQuantity) {
      this.toastr.warning('Available qty exceeded!');
      return;
    }

    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {


      let minqtycheck = 0;
      if ((+prodQuantity.quantity + 1) < (+product.minBuyingQuantity)) {
        minqtycheck = product.minBuyingQuantity;
      } else {
        minqtycheck = +prodQuantity.quantity + 1;
      }

      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: minqtycheck
      };

      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.cartRecreate();
        }
      });

    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;

    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;



      if (i !== 0) {
        i--;
        i = i;
        // i = i - (product.minBuyingQuantity ? product.minBuyingQuantity : 1);
        if (i < product.minBuyingQuantity) {
          i = 0;
        }
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.cartRecreate();
          }
        });
      }
      if (i === 0) {
        let data = {
          cartId: cartId ? cartId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          quantity: 0,
          isOffer: product.hasOffer,
          dealId: product.dealId,
          productId: product.productId,
        };
        this.store.removeFormCart(data).subscribe((res) => {
        });
      }
    }
  }
  clearCartOld() {
    let cartId = localStorage.getItem('cartId');
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      const element = this.store.cartProducts$[i];
      let data = {
        cartId: cartId ? cartId : '',
        discountCode: element.productDto.discountCode
          ? element.productDto.discountCode
          : '',
        quantity: 0,
        isOffer: element.productDto.hasOffer,
        productId: element.productDto.productId,
      };
      this.store.addToCart(data).subscribe((res) => {
        this.cartRecreate();
      });
    }
    this.store.totalCart$ = 0;
    this.toaster.success('Cart Cleared!');
  }
  clearCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: '',
      discountCode: '',
      offer: '',
      productId: 0,
      quantity: 0,
    };
    this.store.clearCart(data).subscribe((res: any) => {
      localStorage.removeItem('cartId');
      this.cartRecreate();
    });
  }
  // clearCart() {
  //   localStorage.removeItem('cartId');
  //   this.store.viewCart();
  //   this.store.cartProducts$ = [];
  //   this.store.totalCart$ = 0;
  //   this.toaster.success('Cart Cleared!');
  // }

  // Address Dialog
  openEditDialog() {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: '860px',
      data: { title: 'Edit Address' },
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  openAddAddressDialog() {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: '860px',
      data: { title: 'Add New Address' },
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
