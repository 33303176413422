import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'underscore';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  filterTitle: any = 'Most Relevant';
  filterSort = { title: 'Most Relevant', value: 'mostRelevant' };
  manName: any = "";
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) { }
  min = 0;
  max = 25000;
  brandIds$ = [];
  currentUserProfile$: any;
  categories$: any = [];
  subCategories$: any = [];
  homePageDetails$: any = [];
  homePageSectionDetails$: any = [];
  heroBanner$: any = [];
  homeSectionCategories: any = [];
  newListing$: any = [];
  noListingProducts$: any = false;
  categoryId$: any;
  filteredCategories$: any = [];
  cart$: any = {};
  cartProducts$: any = [];
  headerSearchTerm$: any;

  getStoreHomePageDetails() {
    // let requestData = JSON.stringify({
    //   displayPage: 'Homepage',
    //   storeId: environment.storeId,
    //   token: {
    //     fingerprint: {
    //       createdAt: 0,
    //       deviceFingerprint: JSON.parse(
    //         localStorage.getItem('deviceFingerPrint')
    //       ),
    //       jsonOtherInfo: '',
    //       user_id: 0,
    //     },
    //     loginToken: '',
    //   },
    // });
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
        user_id: 0,
      },
      loginToken: localStorage.getItem('loginToken'),
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'store/getUserWelcome';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addNewProduct(cat, merchantStoreData, data) {
    alert(JSON.stringify(data));
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;

    let barcode = Math.floor(100000000 + Math.random() * 900000000);

    let requestData = {
      active: true,
      alernateId: data.barcode,
      availableQuantity: data.availableQty,
      brandName: data.brandName,
      categoryId: parseInt(cat.categoryId),
      categoryName: cat.categoryName,
      subCategoryname: cat.subCategory[0].subCategoryName,
      featuredProduct: false,
      hasOffer: false,
      hotSelling: false,
      hsnCode: data.hsnNumber,
      interestPercentage: 0,
      imageUrl: '',
      listMedia: [],
      message: '',
      minBuyingQuantity: 1,
      new: true,
      newArrival: false,
      product: true,
      productAvailableQuantity: data.availableQty,
      productBarCode: data.barcode,
      productCreditDuration: 0,
      productDescription: data.description,
      productHowToUse: '',
      productId: Math.floor(100000000 + Math.random() * 900000000),
      productMeasure: '',
      productMinSellingPrice: data.minSellingPrice,
      productName: data.productName,
      productOfferPrice: data.offerPrice,
      productPurchasePrice: data.purchasePrice,
      productSellingPrice: data.sellingPrice,
      productShortDescription: data.shortDescription,
      productTags: '',
      productWSP: 0,
      productWSPMinQuantity: 0,
      shortName: data.shortName,
      storeId: merchantStoreData.storeId,
      taxInclusive: false,
      taxExempted: true,
      taxOnPurchase: 0,
      token: {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: localStorage.getItem('deviceFingerPrint'),
        },
        loginToken: localStorage.getItem('loginToken'),
      },
      totalTaxPercentage: 0,
      trackInventory: true,
    };
    const url = environment.rootCloudUrl + 'store/addNewProduct';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  addDummyNewProduct(merchantStoreData, data, type) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;

    let barcode = Math.floor(100000000 + Math.random() * 900000000);

    if (!data.categoryId) {
      alert('Choose Category id');
      return;
    }

    if (!data.subCategoryId) {
      alert('Choose Sub-category id');
      return;
    }

    if (!data.categoryName) {
      alert('Choose category name');
      return;
    }
    if (!data.subCategoryname) {
      alert('Choose Sub-category name');
      return;
    }

    data.length = undefined;
    data.width = undefined;
    data.height = undefined;
    data.weight = undefined;

    let url = environment.rootCloudUrl + 'store/addNewProduct';
    if (type) {
      if (type === 'edit') {
        url = environment.rootCloudUrl + 'store/updateProductById';
      }
    }

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, data, httpOptions)
      .pipe(map((response: Response) => response));
  }
  viewLoginUser(data) {
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        createdAt: 0,
        deviceFingerprint: '',
        jsonOtherInfo: '',
        user_id: 0,
      },
      loginToken: data.loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/viewProfile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  registerMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      username: '9176270693',
      password: 'string',
      email: 'abc@gmail1.com',
      loginToken: localStorage.getItem('loginToken'),
      merchant: true,
    });
    const url = environment.rootCloudUrl + 'merchant/registerMerchant';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  registerUserAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      username: localStorage.getItem('usernameOrEmail'),
      password: 'string',
      email: 'abc@gmail1.com',
      loginToken: localStorage.getItem('loginToken'),
      merchant: true,
    });
    const url = environment.rootCloudUrl + 'merchant/registerUserAsMerchant';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addStoreAddress(data: any): Observable<any> {
    let requestData = JSON.stringify({
      active: true,
      addLine1: data.addLine1,
      addLine2: data.addLine2,
      addState: data.addState,
      addressType: 'SHIPPING',
      city: data.city,
      country: data.country,
      createdAt: 0,
      lastUpdated: 0,
      lattitude: data.lattitude,
      longitude: data.longitude,
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),

      zipCode: data.zipCode,
    });
    const url = environment.rootCloudUrl + 'merchant/addStoreAddress';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addNewStore(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      loginToken: localStorage.getItem('loginToken'),
      merchantPhoneNo: localStorage.getItem('usernameOrEmail'),
      phoneNumbers: data.phoneNumbers,
      storeAccessPassword: 'string',
      storeEmployees: data.storeEmployees,
      storeEnabled: true,
      storeName: data.userFirstName + ' ' + data.userLastName,
      storeOwnerName: data.userFirstName + ' ' + data.userLastName,
      storeRunningCost: data.storeRunningCost,
      storeType: 'Auto',
      storeId: localStorage.getItem('storeId'),
    });
    const url = environment.rootCloudUrl + 'merchant/addNewStore';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateStoreTime(data: any): Observable<any> {
    let requestData = JSON.stringify({
      setWeekDay: data.setWeekDay,
      storeId: localStorage.getItem('storeId'),
      token: {
        fingerprint: {
          createdAt: 0,
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: 'string',
          user_id: 0,
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    });
    const url = environment.rootCloudUrl + 'merchant/updateStoreTime';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }


  mapShippingToBid(data): Observable<any> {


    let formData = {
      "cartId": data.bidKey,
      "costBreakup": data.shippingOption.costBreakup,
      "deliveryDays": data.shippingOption.deliveryDays,
      "rateId": data.shippingOption.rateId,
      "serviceType": data.shippingOption.serviceType,
      // "serviceType": "FedEx 2Day",
      "shippingAmount": data.shippingOption.shippingAmount,
      "token": {
        "fingerprint": {
          "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
          "jsonOtherInfo": "string"
        },
        "loginToken": localStorage.getItem('loginToken'),
      }
    };

    const url = environment.rootCloudUrl + 'freight/shipengine/mapShippingToBid';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  mapShippingToCart(data): Observable<any> {


    let formData = {
      "cartId": localStorage.getItem('cartId'),
      "costBreakup": data.shippingOption.costBreakup,
      "deliveryDays": data.shippingOption.deliveryDays,
      "rateId": data.shippingOption.rateId,
      "serviceType": data.shippingOption.serviceType,
      // "serviceType": "FedEx 2Day",
      "shippingAmount": data.shippingOption.shippingAmount,
      "token": {
        "fingerprint": {
          "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
          "jsonOtherInfo": "string"
        },
        "loginToken": localStorage.getItem('loginToken'),
      }
    };

    const url = environment.rootCloudUrl + 'freight/shipengine/mapShippingToCart';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  mapAddressToCart(data): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('addressId', data.addressId);
    formData.append('addressType', 'SHIPPING');
    formData.append('cartId', localStorage.getItem('cartId'));

    const url = environment.rootCloudUrl + 'store/mapAddressToCart';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  uploadStoreProductsDownload(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeKey', localStorage.getItem('storeId'));
    formData.append('type', localStorage.getItem('download'));

    const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  export(link: any) {
    return this.http.get(link, { responseType: 'blob' });
  }

  uploadStoreProductsUpload(event: any): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeKey', localStorage.getItem('storeId'));
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  fetchHomeScreenSlides(data: any): Observable<any> {
    const url = environment + 'slides.php';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getHomePageSectionDetails() {
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        deviceFingerprint: '',
        jsonOtherInfo: '',
      },
      pageType: 'HomePage',
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'store/web/getPageMetaDataByStore';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getPageSectionBySectionKey(data) {
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      pageType: 'HomePage',
      sectionKey: data.sectionKey,
      storeId: environment.storeId,
    });
    const url =
      environment.rootCloudUrl + 'store/web/getPageSectionBySectionKey';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  cancelBidRequest(data) {
    let formData: FormData = new FormData();
    formData.append('bidKey ', data.bidKey);
    const url = environment.rootCloudUrl + 'bidding/cancelBidRequest';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  rejectBidRequest(data) {
    let formData: FormData = new FormData();
    formData.append('bidKey ', data.bidKey);
    const url = environment.rootCloudUrl + 'bidding/rejectBidRequest';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateProductSellingStatus(data) {
    let formData: FormData = new FormData();
    formData.append('productId', data.id);
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeId', data.storeId);
    formData.append('sellingStatus', data.sellingStatus);
    const url = environment.rootCloudUrl + 'store/updateProductSellingStatus';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  acceptBidRequest(data) {
    let formData: FormData = new FormData();
    formData.append('bidKey ', data.bidKey);
    const url = environment.rootCloudUrl + 'bidding/acceptBidRequest';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  isProductBarcodeUnique(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData: FormData = new FormData();
    formData.append('barcode', data.barcode);
    formData.append('storeId', environment.storeId);
    const url =
      environment.rootCloudUrl + 'store/isProductBarcodeUnique/' + data.barcode;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  isProductShortnameUnique(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData: FormData = new FormData();
    formData.append('shortname', data.shortname);
    formData.append('storeId', environment.storeId);
    const url =
      environment.rootCloudUrl +
      'store/isProductShortnameUnique/' +
      data.shortname;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getPaymentDetails(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const url = environment.rootCloudUrl + 'payments/getPaymentDetails/' + data.id;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getOrderDetails(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      orderId: data.id,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };
    const url = environment.rootCloudUrl + 'store/getOrderDetails';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getNewCustomerMetricsByStore(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {};
    const url = environment.rootCloudUrl + 'store/getNewCustomerMetricsByStore';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getMetricsForStore(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    // let formData: FormData = new FormData();
    // formData.append('fromDate', data.fromDate);
    // formData.append('toDate', data.toDate);
    const url =
      environment.rootCloudUrl +
      'store/getMetricsForStore/' +
      data.fromDate +
      '/' +
      data.toDate;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  initiatePayment(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      cartId: localStorage.getItem('cartId'),
      paymentMode: 'OnlineCreditCard',
      provider: 'Stripe',
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'payments/initiatePayment';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  initiatePaymentBidRequest(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData: FormData = new FormData();
    formData.append('bidKey', data.bidKey);
    formData.append('storeId', environment.storeId);
    const url = environment.rootCloudUrl + 'bidding/initiatePaymentBidRequest';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getBidRequests(data): Observable<any> {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (data.pageNo < 0) {
      data.pageNo = 0;
    }
    let formData = {
      bidFilter: {
        communicationStatus: data.status,
        primaryStatus: data.primaryStatus,
        sortColumnName: 'lastUpdated',
        sortOrder: 'DESCENDING',
      },

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: 8,
      requestType: data.type,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'bidding/getBidRequests';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }


  trackOrder(orderId): Observable<any> {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const url = environment.rootCloudUrl + 'freight/track/' + orderId;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getArtifacts(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let formData: FormData = new FormData();
    formData.append('artifactType ', data.artifactType);

    const url =
      environment.rootCloudUrl +
      'merchant/getUserArtifact?artifactType=' +
      data.artifactType;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getArtifactsOld(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // let formData: FormData = new FormData();
    // formData.append('artifactType ', data.artifactType);
    let formData = {
      artifactType: data.artifactType,
    };

    const url = environment.rootCloudUrl + 'merchant/getUserArtifact';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getOrders(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    if (data.pageNo < 0) {
      data.pageNo = 0;
    }
    let formData = {
      customerId: '',

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: 8,
      sortOption: 'orderCreationDate',
      sortOrder: 'Desc',
      storeId: environment.storeId,
      requestType: data.type,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'store/getOrderSummary';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getCartSellOrders(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    if (data.pageNo < 0) {
      data.pageNo = 0;
    }
    // let formData = {
    //   customerId: '',

    //   pageNo: data.pageNo > 0 ? data.pageNo : 0,
    //   pageSize: 8,
    //   sortOption: 'orderCreationDate',
    //   sortOrder: 'Desc',
    //   storeId: data.storeId,
    //   requestType: data.type,
    //   token: {
    //     fingerprint: {
    //       deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
    //       jsonOtherInfo: '',
    //     },
    //     loginToken: localStorage.getItem('loginToken'),
    //   },
    // };



    let formData = {
      otherReferenceId: "",
      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: 8,
      sortOption: 'orderCreationDate',
      sortOrder: 'Desc',
      storeId: data.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    }

    const url = environment.rootCloudUrl + 'store/getOrderListForStore';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  sendMessage(data: any): Observable<any> {
    let requestData = {
      fingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: data.loginToken,
      message: data.message,
      parentMessageKey: data.parentMessageKey,
      productId: parseInt(data.productId),
      recipientUsername: data.recipientUsername,
    };
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const url = environment.rootCloudUrl + 'messaging/sendMessage';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  messageRead(messageKey) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const url =
      environment.rootCloudUrl + 'messaging/messageRead/' + messageKey;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  removeMessage(messageKey) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const url =
      environment.rootCloudUrl + 'messaging/removeMessage/' + messageKey;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .delete(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  totalMessages$: any = 0;
  getMessages(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      listLabels: data.listLabels,

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      sortColumnName: data.sortColumnName,
      sortOrder: data.sortOrder,
    };

    const url = environment.rootCloudUrl + 'messaging/getMessages';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getAllMessages() {
    if (!localStorage.getItem('loginToken')) {
      this.totalMessages$ =0;
      return;
    }

    let data = {
      listLabels: ['Inbox', 'Read', 'Sent'],
      pageNo: 0,
      pageSize: 8,
      sortColumnName: 'lastUpdated',
      sortOrder: 'DESCENDING',
    };
    this.spinner.show();

    this.getMessages(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.Status === 'Success') {

        try {
          this.totalMessages$ = res.Inbox.messages.content.length;
          
        } catch (error) {
          this.totalMessages$ = 0;
          
        }
        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }

  getStoreCategories() {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', environment.storeId);
    formData.append('categoryId', '');
    const url = environment.rootCloudUrl + 'store/web/getCategoriesByStore';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateProductPrimaryImage(data) {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', data.storeId);
    formData.append('imageId', data.imageId);
    formData.append('productId', data.productId);
    const url = environment.rootCloudUrl + 'store/updateProductPrimaryImage';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }



  deleteProductImage(data) {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', data.storeId);
    formData.append('imageId', data.imageId);
    formData.append('productId', data.productId);
    formData.append('imageUrl ', data.imageUrl);
    formData.append('loginToken  ', localStorage.getItem('loginToken'));

    const url = environment.rootCloudUrl + 'store/deleteProductImage';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getStoreKey() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const file1 = event.target.files;
    const url = environment.rootCloudUrl + 'merchant/getStoreKey';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getMasterStoreSettings() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const file1 = event.target.files;
    const url =
      environment.rootCloudUrl +
      'store/web/getMasterStoreSettings/' +
      environment.storeId;
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getCategories() {
    this.getStoreCategories().subscribe((res: any) => {
      if (!res) {
        return;
      } else {
        this.categories$ = res.Categories;
        for (let i = 0; i < this.categories$.length; i++) {
          const element = this.categories$[i].subCategory;
          this.subCategories$ = element;
          this.subCategories$.sort((a, b) => {
            return Number(b.subCategoryId) - Number(a.subCategoryId);
          });
        }
        this.categories$.sort((a, b) => {
          return Number(a.categoryId) - Number(b.categoryId);
        });
      }
    });
  }
  getHomePageListSectionDetails() {
    this.getHomePageSectionDetails().subscribe((res: any) => {
      if (!res) {
        return;
      } else {
        this.homePageDetails$ = res.listSection;
        for (let i = 0; i < this.homePageDetails$.length; i++) {
          const element = this.homePageDetails$[i];

          this.getHomePageSectionBySectionKey(element.sectionKey);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    });
  }
  banners: any = {};
  manufacturers$: any = [];
  getHomePageSectionBySectionKey(key: any) {
    let data = {
      sectionKey: key,
    };
    this.getPageSectionBySectionKey(data).subscribe((res: any) => {
      if (!res) {
        return;
      } else {
        this.homePageSectionDetails$.push(res);
        for (let i = 0; i < this.homePageSectionDetails$.length; i++) {
          const element = this.homePageSectionDetails$[i];
          if (element.Manufacturers !== undefined) {
            this.manufacturers$ = element.Manufacturers;
          }
          if (element.sectionType === 'CategoryCorousel') {
            this.homeSectionCategories = element;
          }
          if (element.sectionType === 'HeroBannerCorousel') {
            this.heroBanner$ = element;
            for (
              let i = 0;
              i < this.heroBanner$['HeroBannerCorousel:1'].length;
              i++
            ) {
              const element = this.heroBanner$['HeroBannerCorousel:1'][i];
              this.banners = element;
            }
          }
          if (element.sectionType === 'ProductManufacturers') {
            this.newListing$ = element;
            if (this.newListing$.products.length === 0) {
              this.noListingProducts$ = true;
            } else {
              this.noListingProducts$ = false;
            }
          }
        }
        if (this.heroBanner$) {
          this.heroBanner$['HeroBannerCorousel:1']
          .sort((a, b) => {
            return a.displayOrder - b.displayOrder;
          });
          // this.homePageSectionDetails$;
          // .sort((a, b) => {
          //   return b.verticalDisplayOrder - a.verticalDisplayOrder;
          // });
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    });
  }

  placeOrder(data) {
    let requestData = {
      amountPaid: data.payable * 100,
      cartId: localStorage.getItem('cartId'),
      customerFullName:
        this.currentUserProfile$.userFirstName +
        ' ' +
        this.currentUserProfile$.userLastName,
      paymentMode: 'Cash',
      provider: 'COD',
      retCode: '',
      signatures: '',

      bidRequest: false,
      txnId: '',
      storeId: environment.storeId,
      xnStatus: 'SUCCESS',
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),
    };
    const url = environment.rootCloudUrl + 'store/placeOrder';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  placeStripeOrder(data) {
    let requestData = {
      amountPaid: 0,
      amountPending: data.payable * 100,
      cartId: localStorage.getItem('cartId'),
      customerFullName: JSON.parse(
        localStorage.getItem('currentUserProfile')
      ).userFirstName + " " + JSON.parse(
        localStorage.getItem('currentUserProfile')
      ).userLastName,

      paymentMode: 'OnlineCreditCard',
      provider: 'Stripe',
      retCode: data.token.token.id,
      signatures: '',
      txnId: data.txnId,
      storeId: environment.storeId,
      bidRequest: false,
      xnStatus: 'INITIATED',
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),
    };
    const url = environment.rootCloudUrl + 'store/placeOrder';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  placeStripeBidOrder(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let requestData = {
      amountPaid: 0,
      amountPending: data.payable * 100,
      cartId: data.bidId,
      bidRequest: true,
      storeId: environment.storeId,
      customerFullName: t.userFirstName + ' ' + t.userLastName,
      paymentMode: 'OnlineCreditCard',
      provider: 'Stripe',
      retCode: data.token.token.id,
      signatures: '',
      txnId: data.txnId,
      xnStatus: 'INITIATED',
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),
    };
    const url = environment.rootCloudUrl + 'store/placeOrder';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  placeBid(data) {
    let requestData = {
      bidPrice: data.bidPrice,
      lattitude: 0,
      longitude: 0,
      productId: data.productId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };
    const url = environment.rootCloudUrl + 'bidding/placeBid';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  searchProduct(data) {
    let requestData = {
      categoryId: '',
      filter: data.filter ? data.filter : 'ProductsByCategory',

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchString: '',
      sortOption: data.sortOption ? data.sortOption : 'productId',
      sortOrder: data.sortOrder ? data.sortOrder : 'Desc',
      storeId: data.storeId,
      sendAllProducts: data.sendAllProducts ? data.sendAllProducts : false,
    };

    const url = environment.rootCloudUrl + 'store/web/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  searchStoreProduct(data) {
    let requestData = {
      categoryId: '',
      filter: data.filter,

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchString: '',
      sortOption: data.sortOption,
      sortOrder: data.sortOrder,
      storeId: data.storeId,
      sendAllProducts: data.sendAllProducts ? data.sendAllProducts : false,
    };
    const url = environment.rootCloudUrl + 'store/searchProduct';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };


    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getProductById(data) {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', environment.storeId);
    formData.append('productId', data.productId);

    let url = environment.rootCloudUrl + 'store/web/getProductById';
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' }),
    };
    if (localStorage.getItem('loginToken')) {
      url = environment.rootCloudUrl + 'store/getProductById';
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        }),
      };
    }

    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);

    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addMessageToCart(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('customerMessage', data.message);
    formData.append('cartId', localStorage.getItem('cartId'));
    const url = environment.rootCloudUrl + 'store/addMessageToCart';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  searchCategoryName$: any;
  categoryId: any;
  subCategoryId: any;
  getProduuctsByCategoryId(data) {
    this.searchProductByCategoryId(data).subscribe((res) => {
      if (res) {
        this.filteredCategories$ = res;
        if (this.filteredCategories$ !== {}) {
          this.filteredProducts$ = this.filteredCategories$.products;
        }
        this.categoryId = this.router.url.split('/')[2];
        this.subCategoryId = this.router.url.split('/')[3];

        if (this.categoryId === 'ALL') {
          this.searchCategoryName$ = 'ALL';
        } else {
          this.searchCategoryName$ =
            this.filteredCategories$.products[0].categoryName;
        }
        if (this.subCategoryId) {
          this.searchCategoryName$ =
            this.filteredCategories$.products[0].subCategoryname;
        }
        // if (this.searchTerm$) {
        //   this.setFilteredItems();
        // } else if (this.headerSearchTerm$) {
        //   this.headerFilteredItems();
        // }
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  relatedCategories$: any = [];
  relatedProducts$: any = [];
  getRelatedProductsByCategoryId(data) {
    this.searchProductByCategoryId(data).subscribe((res) => {
      if (res) {
        this.relatedCategories$ = res;
        if (this.relatedCategories$ !== {}) {
          this.relatedProducts$ = this.relatedCategories$.products;
        } else {
          this.relatedProducts$ = [];
        }
        // this.categoryId = this.router.url.split('/')[2];
        // this.subCategoryId = this.router.url.split('/')[3];

        // if (this.categoryId === 'ALL') {
        //   this.searchCategoryName$ = 'ALL';
        // } else {
        //   this.searchCategoryName$ =
        //     this.relatedCategories$.products[0].categoryName;
        // }
        // if (this.subCategoryId) {
        //   this.searchCategoryName$ =
        //     this.relatedCategories$.products[0].subCategoryname;
        // }

      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }
  searchProductByText(data) {
    let requestData = JSON.stringify({
      categoryId: data.categoryId ? data.categoryId : '',
      filter: data.filter ? data.filter : 'ProductsByCategory',
      subCategoryId: data.subCategoryId,

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchString: data.searchString,
      sortOption: data.sortOption ? data.sortOption : 'productId',
      sortOrder: data.sortOrder ? data.sortOrder : 'Desc',
      storeId: environment.storeId,
      sendAllProducts: data.sendAllProducts,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });

    const url = environment.rootCloudUrl + 'store/web/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  searchTerm$: any;
  filteredProducts$: any = [];
  setFilteredItems() {
    // this.router.navigate(['/browse-listing/ALL']).then((res) => {
    //   if (!this.searchTerm$) {
    //     this.filteredProducts$ = this.filteredProducts$;
    //   }
    //   this.filteredProducts$ = this.filterItems(this.searchTerm$);
    // });
    // if (!this.searchTerm$) {
    //   this.filteredProducts$ = this.filteredProducts$;
    // }
    let data: any = {
      categoryId: '',
      filter: 'TextSearch',
      subCategoryId: '',
      pageNo: 0,
      pageSize: 200000,
      searchString: this.searchTerm$,
      sortOption: 'productId',
      sortOrder: 'Desc',
      sendAllProducts: true,
    };
    this.categoryId = this.router.url.split('/')[2];
    this.subCategoryId = this.router.url.split('/')[3];

    if (this.categoryId === 'manufacturer') {
      data.brandIds = [parseInt(this.subCategoryId)];
      data.brandName = [''];
    }

    // if (this.categoryId === 'ALL') {
    //   this.searchCategoryName$ = 'ALL';
    //   data.categoryId = '';
    // } else {
    //   this.searchCategoryName$ =
    //     this.filteredCategories$.products[0].categoryName;
    //   data.categoryId = this.categoryId;
    // }
    // if (this.subCategoryId) {
    //   this.searchCategoryName$ =
    //     this.filteredCategories$.products[0].subCategoryname;
    //   data.subCategoryId = this.subCategoryId;

    // }

    this.searchProductByCategoryId(data).subscribe((response: any) => {
      if (response) {
        this.filteredCategories$ = response;
        this.filteredProducts$ = response.products;
      } else {
        console.error(response);
        this.spinner.hide();
      }
    });
  }

  filterItems(searchTerm: any) {
    return this.filteredCategories$.products.filter((item: any) => {
      return (
        item.productName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        item.categoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    });
  }
  headerFilteredItems() {
    // if (!this.headerSearchTerm$) {
    //   this.filteredProducts$ = this.filteredProducts$;
    // }
    // this.filteredProducts$ = this.filterItems(this.headerSearchTerm$);
    let data: any = {
      categoryId: '',
      filter: 'TextSearch',
      subCategoryId: '',
      pageNo: 0,
      pageSize: 9,
      searchString: this.headerSearchTerm$,
      sortOption: 'productId',
      sortOrder: 'Desc',
      sendAllProducts: true,
    };
    this.categoryId = this.router.url.split('/')[2];
    this.subCategoryId = this.router.url.split('/')[3];

    this.searchProductByCategoryId(data).subscribe((response: any) => {
      if (response) {
        this.router
          .navigate(['/browse-listing/ALL'], { queryParams: { searchText: this.headerSearchTerm$ } })
          .then((res) => {
            this.filteredCategories$ = response;
            this.filteredProducts$ = response.products;
          })
          .then((res) => {
            this.headerSearchTerm$ = '';
          });
      } else {
        console.error(response);
        this.spinner.hide();
      }
    });
  }

  headerfilterItems(searchTerm: any) {
    return this.filteredCategories$.products.filter((item: any) => {
      return (
        item.productName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        item.categoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    });
  }
  searchProductByCategoryId(data) {
    let requestData = JSON.stringify({
      categoryId: data.categoryId ? data.categoryId : '',
      filterEx: {
        brandIds: data.brandIds,
        priceFrom: data.priceFrom || this.min,
        priceTo: data.priceTo || this.max,
      },
      filter: data.filter ? data.filter : 'ProductsByCategory',
      subCategoryId: data.subCategoryId,
      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchString: data.searchString,
      sortOption: data.sortOption ? data.sortOption : 'productId',
      sortOrder: data.sortOrder ? data.sortOrder : 'Desc',
      storeId: environment.storeId,
      sendAllProducts: data.sendAllProducts,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });

    const url = environment.rootCloudUrl + 'store/web/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  searchStoreProductByCategoryId(data) {
    let requestData = JSON.stringify({
      categoryId: data.categoryId,
      filterEx: {
        brandIds: data.brandIds,
        priceFrom: data.priceFrom || this.min,
        priceTo: data.priceTo || this.max,
      },
      filter: data.filter,
      subCategoryId: data.subCategoryId,

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchString: data.searchString,
      sortOption: data.sortOption,
      sortOrder: data.sortOrder,
      storeId: environment.storeId,
      sendAllProducts: data.sendAllProducts,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });

    const url = environment.rootCloudUrl + 'store/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  clearCart(data) {
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      dealId: data.dealId,
      discountCode: data.discountCode,
      offer: data.offer,
      productId: data.productId,
      quantity: data.quantity,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          createdAt: 0,
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
          user_id: 0,
        },
        loginToken: loginToken ? loginToken : '',
      },
    });

    const url = environment.rootCloudUrl + 'store/web/clearCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addToCart(data) {
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      dealId: data.dealId,
      discountCode: data.discountCode,
      offer: data.offer,
      productId: data.productId,
      quantity: data.quantity,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          createdAt: 0,
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
          user_id: 0,
        },
        loginToken: loginToken ? loginToken : '',
      },
    });
    const url = environment.rootCloudUrl + 'store/web/addToCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getMessagesCount() {
    if (!localStorage.getItem('loginToken')) {
      return;
    }

    let data = {
      cartId: localStorage.getItem('cartId'),
      paymentMode: 'Cash',
    };

    try {
      this.viewCartItems(data).subscribe((res: any) => {
        this.cart$ = res.Cart;
        if (res.Cart.cartItems) {
          this.cartProducts$ = res.Cart.cartItems.filter((item) => {
            return item.quantity !== 0;
          });
          this.totalCart$ = this.cartProducts$
            .map((product) => product.quantity)
            .reduce((product, quantity) => quantity + product);

        } else {
          this.totalCart$ = 0;
          this.cart$ = [];
          this.cartProducts$ = [];
        }
      });
    } catch (err) {
      this.totalCart$ = 0;
      this.cart$ = [];
      this.cartProducts$ = [];
    }
  }

  totalCart$: any;
  viewCart() {
    if (!localStorage.getItem('cartId')) {
      this.viewCartWithoutCartId();
      return;
    }

    let data = {
      cartId: localStorage.getItem('cartId'),
      paymentMode: 'Cash',
    };

    try {
      this.viewCartItems(data).subscribe((res: any) => {
        this.cart$ = res.Cart;
        if (this.cart$.cartId) {
          localStorage.setItem('cartId', this.cart$.cartId);
        }

        if (res.Cart.cartItems.length) {
          this.cartProducts$ = res.Cart.cartItems.filter((item) => {
            return item.quantity !== 0;
          });
          this.totalCart$ = this.cartProducts$
            .map((product) => product.quantity)
            .reduce((product, quantity) => quantity + product);
        } else {
          this.totalCart$ = 0;
          this.cart$ = [];
          this.cartProducts$ = [];
        }

      });
    } catch (err) {
      this.totalCart$ = 0;
      this.cart$ = [];
      this.cartProducts$ = [];
    }
  }
  resetCartShipping() {
    if (!localStorage.getItem('cartId')) {
      return;
    }

    let data = {
      cartId: localStorage.getItem('cartId')
    };

    try {
      this.resetCartShippingData(data).subscribe((res: any) => {
        //do nothing
        // this.cart$ = res.Cart;
        // if (this.cart$.cartId) {
        //   localStorage.setItem('cartId', this.cart$.cartId);
        // }

        // if (res.Cart.cartItems.length) {
        //   this.cartProducts$ = res.Cart.cartItems.filter((item) => {
        //     return item.quantity !== 0;
        //   });
        //   this.totalCart$ = this.cartProducts$
        //     .map((product) => product.quantity)
        //     .reduce((product, quantity) => quantity + product);
        // } else {
        //   this.totalCart$ = 0;
        //   this.cart$ = [];
        //   this.cartProducts$ = [];
        // }
      });
    } catch (err) {
      //do nothing
      // this.totalCart$ = 0;
      // this.cart$ = [];
      // this.cartProducts$ = [];
    }
  }

  resetCartShippingData(cartId) {
    const url = environment.rootCloudUrl + 'freight/shipengine/resetCartShipping/' + cartId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  resetBidShipping(bidKey) {
    if (!bidKey) {
      return;
    }

    let data = {
      bidKey: bidKey
    };

    try {
      this.resetBidShippingData(data).subscribe((res: any) => {
        //do nothing
        return;
      });
    } catch (err) {
      //do nothing
      return;
    }
  }

  resetBidShippingData(bidKey) {
    const url = environment.rootCloudUrl + 'freight/shipengine/resetBidShipping/' + bidKey;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  viewCartWithoutCartId() {
    // if (!localStorage.getItem('cartId')) {
    //   // this.totalCart$ = 0;
    //   // this.cart$ = [];
    //   // this.cartProducts$ = [];
    // }

    let data = {
      cartId: '',
      paymentMode: 'Cash',
    };

    try {
      this.viewCartItems(data).subscribe((res: any) => {
        this.cart$ = res.Cart;
        if (this.cart$.cartId) {
          localStorage.setItem('cartId', this.cart$.cartId);
        }
        if (res.Cart.cartItems) {

          if (res.Cart.cartItems.length) {
            this.cartProducts$ = res.Cart.cartItems.filter((item) => {
              return item.quantity !== 0;
            });


            this.totalCart$ = this.cartProducts$
              .map((product) => product.quantity)
              .reduce((product, quantity) => quantity + product);
          } else {
            this.totalCart$ = 0;
            this.cart$ = [];
            this.cartProducts$ = [];
          }
        } else {

          this.totalCart$ = 0;
          this.cart$ = [];
          this.cartProducts$ = [];
          
        }

      });
    } catch (err) {
      this.totalCart$ = 0;
      this.cart$ = [];
      this.cartProducts$ = [];
    }
  }

  viewCartItems(data) {
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      paymentMode: data.paymentMode,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: loginToken ? loginToken : '',
      },
    });
    const url = environment.rootCloudUrl + 'store/web/viewCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  removeFormCart(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      discountCode: data.discountCode,
      quantity: data.quantity,
      isOffer: data.isOffer,
      dealId: '',
      productId: data.productId,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: '',
        },
        loginToken: loginToken,
      },
    });
    const url = environment.rootCloudUrl + 'store/removeFromCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addCustomerAddress(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');

    let requestData = JSON.stringify({
      addLine1: data.addLine1,
      addLine2: data.addLine2,
      addState: data.addState,
      addressType: data.addressType,
      cartId: localStorage.getItem('cartId'),
      city: data.city,
      country: data.country,
      customerFullName: data.customerFullName,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: loginToken,
      },
      zipCode: data.zipCode,
    });
    const url = environment.rootCloudUrl + 'store/addCustomerAddress';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  viewProfile() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/viewProfile';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getUserAddressList() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'store/getUserAddressList';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getShippingOptions() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'freight/shipengine/cart/' + localStorage.getItem('cartId');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }


  getBidRequest(bidKey) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'bidding/getBidRequest/' + bidKey;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getShippingOptionsForBids(bidKey) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'freight/shipengine/bids/' + bidKey;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  deleteUserAddress(data) {
    let loginToken = localStorage.getItem('loginToken');
    // const file1 = event.target.files;
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });

    const url =
      environment.rootCloudUrl +
      'store/removeUserAddress?addressId=' +
      data.addressId;
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    // headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  addNewStoreMerchant() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: loginToken,
      masterStore: false,
      merchantPhoneNo: localStorage.getItem('userMobileNumber'),
      phoneNumbers: [],
      storeAccessPassword: 'salvage_scout',
      storeEmailPrefix: 'salvage-',
      storeEmployees: [],
      storeEnabled: true,
      storeName: 'salvage_scout',
      storeOwnerName: t.userFirstName + ' ' + t.userLastName,
      storeRunningCost: 0,
      storeType: 'Auto',
    });
    const url = environment.rootCloudUrl + 'store/addCustomerAddress';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  postSalvageRequest(data: any) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');

    let requestData = {
      "budgetFrom": parseInt(data.budgetFrom),
      "budgetTo": parseInt(data.budgetTo),
      "categoryId": parseInt(data.categoryId),
      "email": data.email,
      "endDate": parseInt(data.endDate),
      "fingerprint": {
        "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
        "jsonOtherInfo": ""
      },
      "loginToken": loginToken ? loginToken : '',
      "newRequired": data.newRequired,
      "partId": data.partId,
      "reManufactured": data.reManufactured,
      "receiveBidFromAnyone": true,
      "refMedia": [

      ],
      "requestDescription": data.requestDescription,
      "requestName": data.requestName,
      "storeId": environment.storeId,
      "strCategory": data.strCategory
    };

    const url = environment.rootCloudUrl + 'store/web/postSalvageRequest';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  browseSalvageRequest(data: any) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = {
      fingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: loginToken,

      pageNo: data.pageNo > 0 ? data.pageNo : 0,
      pageSize: data.pageSize,
      searchParams: {
        'vin': data.vin ? +data.vin.toString() : "",
        'partId': data.partId ? data.partId.toString() : "",
        'manufacturingYear': "",
        'categoryId': parseInt(data.categoryId),
        'tags': data.tags ? data.tags.toString() : "",
        "endDate": data.date ? data.date : null
      },
      sortColumnName: 'createdAt',
      sortOrder: data.sortBy,
      storeId: environment.storeId,
    };
    const url = environment.rootCloudUrl + 'merchant/browseSalvageRequest';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateUserProfilePic(event: any): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeId', environment.storeId);
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'store/updateUserProfilePic';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateProductPic(event: any, data): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeId', data.storeId);
    formData.append('file', file);
    formData.append('displayOrder', data.displayOrder);
    formData.append('isPrimary', data.isPrimary);
    formData.append('productId', data.productId);

    const url = environment.rootCloudUrl + 'store/addProductImage';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  updateIdPic(event: any, data): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('artifactType', data.artifactType);
    formData.append('masterStore', environment.storeId);
    formData.append('artifactImage', file);

    const url = environment.rootCloudUrl + 'merchant/uploadUserArtifact';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addProductImage(event: any, data): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('displayOrder', data.displayOrder);
    formData.append('productId', data.productId);
    formData.append('isPrimary', data.isPrimary);
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeId', environment.storeId);
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'store/addProductImage';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateUserProfile(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      desiredRole: data.desiredRole,
      companyFDIN: data.companyFDIN ? data.companyFDIN : '',
      companyName: data.companyName ? data.companyName : '',
      dob: data.dob,
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      fullname: data.firstname + ' ' + data.lastname,
      //password: data.password,
      // media: {
      //   displayOrder: 0,
      //   mediaTags: '',
      //   mediaType: 'IMAGE',
      //   mediaURL: '',
      //   primary: false,
      // },
      storeId: environment.storeId,
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      merchant: false,
      loginToken: localStorage.getItem('loginToken'),
    });
    const url = environment.rootCloudUrl + 'auth/updateUserProfile';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getBidRequestStatusEnum() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const url = environment.rootCloudUrl + 'store/web/getBidRequestStatusEnum';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ',
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  uploadCsvFile(data: any): Observable<any> {
    const file = data.file;
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeKey', data.storeKey);
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  showPrimaryImage(img) {
    var images = img.sort((value) => {
      return value.primary ? -1 : 1;
    });
    return images[0].mediaURL
      ? images[0].mediaURL
      : '../../../assets/logo/logo.svg';
  }
  showPrimaryImageWithBasePath(img) {
    var images = img.sort((value) => {
      return value.primary ? -1 : 1;
    });
    return images[0].basePath + images[0].filename;
  }

  showInventoryPrimaryImageWithBasePath(img) {
    var images = img.sort((value) => {
      return value.primary ? -1 : 1;
    });
    return images[0].mediaURL;
  }

  downloadPDF(serviceURL, dataObj) {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(serviceURL, { headers: headers, responseType: 'blob' }).pipe(map((data: any) => {

      // let blob = new Blob([data], {
      //   type: 'application/pdf' // must match the Accept type
      //   // type: 'application/octet-stream' // for excel 
      // });
      // var link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = 'T&C.pdf';
      // link.click();
      // window.URL.revokeObjectURL(link.href);

    })).subscribe((result: any) => {
    });
  }

}
