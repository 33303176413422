import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  orderId = "";
  constructor(
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService) {

      

    if (this.router.url.split('/')[3]) {
      this.orderId = this.router.url.split('/')[3];
      this.getOrderDetails(this.orderId);
    }
     }

  ngOnInit() {}
  orderDetails = [];
  getOrderDetails(id) {
    let data = {
      id: id
    }
    this.store.getOrderDetails(data).subscribe((res: any) => {
      if (res) {
        
        this.orderDetails = res;
        return;
      } else {
      }
    });
  }
}
