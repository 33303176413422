// import { IonicStorageModule } from '@ionic/storage';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './httpConfig.interceptor';

import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { Stripe } from '@ionic-native/stripe/ngx';
// import { AgmCoreModule } from '@agm/core';


import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDNSp9P-cZko8qcXQgT8YWQm4WmfPNqAs0',
    // }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyD3oI0uHScHDd3DZnqIBQ80TeasS_s-8iM',
      apiKey: 'AIzaSyCehiheHtGIKnlCtYzHZwADvnSvhlcxeAc',
    }),
    NgxSpinnerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      maxOpened: 1,
      timeOut: 1000,
    }),
    // IonicStorageModule.forRoot(),
  ],
  providers: [
    InAppBrowser,
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Stripe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    AuthGuardService
  ],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
