import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  constructor(
    public store: StoreService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  categoryId: any;
  subCategoryId: any;
  ngOnInit() {
    this.categoryId = '';
    this.subCategoryId = '';
    this.activatedRoute.params.subscribe((res) => {
      this.categoryId = res.categoryId;
      this.subCategoryId = res.subCategoryId;
    });
  }

  clear() {
    this.router.navigate(['/browse-listing/ALL']).then((res) => {
      // this.filterTitle = 'None';
      this.store.searchTerm$ = '';
      // this.searchText = "";
      this.store.headerSearchTerm$ = '';
        this.store.manName = "";
      // return this.getAllProducts();
    }).then(() => {
   
      window.location.reload();
    });
    // this.store.searchTerm$ = '';
    // let data: any = {
    //   categoryId: this.categoryId,
    //   filter: 'ProductsByCategory',
    //   subCategoryId: '',
    //   brandIds: [0],
    //   pageNo: '0',
    //   pageSize: 9,
    //   searchString: '',
    //   sortOption: 'productId',
    //   sortOrder: 'Desc',
    //   sendAllProducts: false,
    // };
    // data.subCategoryId = this.subCategoryId !== '' ? this.subCategoryId : '';

    // if (this.categoryId === 'manufacturer') {

    //   data.brandIds = [parseInt(data.subCategoryId)];
    //   data.brandName = [''];

    //   data.sendAllProducts = true;
    //   data.filter = 'Manufacturer';
    //   data.categoryId = '';
    //   data.subCategoryId = '';
    // }

    // if (this.categoryId === 'ALL') {
    //   data.categoryId = "";
    //   data.sendAllProducts = true;
    //   data.filter = 'TextSearch';
    // }
    // this.store.getProduuctsByCategoryId(data);
  }

  search() {
    this.router
      .navigate(['/browse-listing/ALL'], { queryParams: { searchText: this.store.searchTerm$} }).then(() => {
        window.location.reload();
      });
  }

  getProduuctsByCategoryId(data) {
    this.store.searchProductByCategoryId(data).subscribe((res) => {
      if (res) {
        this.store.filteredCategories$ = res;
        this.store.filteredProducts$ = this.store.filteredCategories$.products;
        this.categoryId = this.router.url.split('/')[2];
        this.subCategoryId = this.router.url.split('/')[3];
        if (this.categoryId === 'ALL') {
          this.store.searchCategoryName$ = 'ALL';
        } else {
          this.store.searchCategoryName$ =
            this.store.filteredCategories$.products[0].categoryName;
        }
        if (this.subCategoryId) {
          this.store.searchCategoryName$ =
            this.store.filteredCategories$.products[0].subCategoryname;
        }
      } else {
        console.error(res);
      }
    });
  }
}
