import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BidAndBuyModelComponent } from '../bid-and-buy-model/bid-and-buy-model.component';
import { ReportListingModelComponent } from '../report-listing-model/report-listing-model.component';
import { SellItemModelComponent } from '../sell-item-model/sell-item-model.component';
import { Lightbox } from 'ngx-lightbox';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { Product } from 'src/app/models/product.model';
// import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';

import swal from 'sweetalert2';
import { IonSlides } from '@ionic/angular';
@Component({
  selector: 'app-product-detail-section',
  templateUrl: './product-detail-section.component.html',
  styleUrls: ['./product-detail-section.component.scss'],
})
export class ProductDetailSectionComponent implements OnInit {
  rate = 5;
  productDetails: any;
  slideOpts = {
    initialSlide: 1,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
  };
  slideOptsThreee = {
    loop: true,
    navigation: true,
    autoplay: true,
  };
  slideOptsThree = {
    initialSlide: 0,
    speed: 400,
    pagination: true,
    loop: false,
    navigation: true,
    // autoplay:true,
    slidesPerView: 1,
    spaceBetween: 0,

    // initialSlide: 0,

    // freeMode: false,
    breakpoints: {
      '640': {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      '768': {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      '1024': {
        slidesPerView: 6,
        spaceBetween: 8,
      },
    },
  };
  @ViewChild('slideWithNav2') slideWithNav2: IonSlides;
  @ViewChild('slides') slides: IonSlides;

  next(object, slideView) {
    this.slideWithNav2.slideNext();
  }

  prev(object, slideView) {
    this.slideWithNav2.slidePrev();
  }

  constructor(
    public dialog: MatDialog,
    public _lightbox: Lightbox,
    public activatedRoute: ActivatedRoute,
    public store: StoreService,
    // private storageService: StorageService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private router: Router,
    private toaster: ToastrService
  ) {
    this.store.viewCart();
  }
  productId: any;
  TAB_VALUE = 'Product_information';
  p: any;
  segmentChanged(event) {
    
    this.TAB_VALUE = event.detail.value;
  }
  @ViewChild('target') MyProp: ElementRef;
  gotoProduct(id): void {
    this.router
      .navigate(['/list-detail'], { queryParams: { productId: id } })
      .then((res) => {
        this.MyProp.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
  }
  navigateToImg(index) { 

    this.slides.slideTo(index);

    setTimeout(() => {
      this.slides.startAutoplay();
    }, 5000);
  }
  getRelatedProducts() {
    let data = {
      categoryId: this.productDetails.categoryId.toString(),
      filter: 'ProductsByCategory',
      // subCategoryId: this.productDetails.subCategoryId.toString(),
      pageNo: '0',
      pageSize: 300,
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'Desc',
    };
    this.store.getRelatedProductsByCategoryId(data);
  }

  pageType: any;
  ionViewWillEnter() { }
  trackBidRequest(key) {
    this.toaster.warning('COMING SOON!');
  }
  bidId: any;
  bidStatus: any;
  owner: any;
  cancelBidRequest() {
    swal
      .fire({
        title: 'CANCEL BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: this.bidId,
          };
          this.store.cancelBidRequest(data).subscribe((res: any) => {
            if (res) {
              //this.getBuyBidRequests();
              //this.getSellBidRequests();
              $('#cancelOrderBtn').hide();
              this.router.navigate(['/bid-details'], {
                queryParams: {
                  productId: this.productId,
                  bidId: this.bidId,
                  status: res.bidCommunicationStatus,
                  owner: this.owner,
                },
              });
              this.toastr.success('CANCEL BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }

  rejctBidRequest(key) {
    swal
      .fire({
        title: 'REJECT BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: this.bidId,
          };
          this.store.rejectBidRequest(data).subscribe((res: any) => {
            if (res) {
              // this.getBuyBidRequests();
              // this.getSellBidRequests();
              $('#acceptOrderBtn').hide();
              $('#rejectOrderBtn').hide();
              this.router.navigate(['/bid-details'], {
                queryParams: {
                  productId: this.productId,
                  bidId: this.bidId,
                  status: res.bidCommunicationStatus,
                  owner: this.owner,
                },
              });
              this.toastr.success('REJECT BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }

  acceptBidRequest(key) {
    swal
      .fire({
        title: 'ACCEPT BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: this.bidId,
          };
          this.store.acceptBidRequest(data).subscribe((res: any) => {
            if (res) {
              // this.getBuyBidRequests();
              // this.getSellBidRequests();
              $('#acceptOrderBtn').hide();
              $('#rejectOrderBtn').hide();
              this.router.navigate(['/bid-details'], {
                queryParams: {
                  productId: this.productId,
                  bidId: this.bidId,
                  status: res.bidCommunicationStatus,
                  owner: this.owner,
                },
              });
              this.toastr.success('ACCEPT BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }
  merchantStoreData: any = [];
  getStoreKey() {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }

    this.store.getStoreKey().subscribe((res: any) => {
      if (res) {
        
        this.merchantStoreData = res;
        //this.successToast('STORE KEY  FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }
  filtersModel: any = {
    ALL: 'ALL',
    RequestOpen: 'Request Open',
    RequestClosed: 'Request Closed',
    RequestExpired: 'Request Expired',
    PendingResponse: 'Pending Response',
    SellerResponsded: 'Seller Responsded',
    RequestAccepted: 'Request Accepted',
    PaymentPending: 'Payment Pending',
    OrderPlaced: 'Order Placed',
    BidCancelledByBidder: 'Bid Cancelled By Bidder',
    BidRejected: 'Bid Rejected',
    Paid: 'Paid',
    OrderRecieved: 'Order Recieved',
  };
  ngOnInit() {
    this.spinner.show();
    if (this.router.url.split('/')[1]) {
      this.pageType = this.router.url.split('/')[1].split('?')[0];
    }

    this.activatedRoute.queryParams.subscribe((res) => {
      this.productId = res.productId;

      if (res.bidId && res.status) {
        this.bidId = res.bidId;
        this.bidStatus = res.status;
        this.owner = res.owner;
        this.getStoreKey();
      }

      if (
        this.productId === 'create-new' ||
        this.productId === undefined ||
        !this.productId
      ) {
        return;
      }
      let data = {
        productId: this.productId,
      };
      this.store.getProductById(data).subscribe((res) => {
        this.spinner.hide();
        this.productDetails = res;
        this.getRelatedProducts();

        if (this.productDetails.listMedia.length) {
          this.productDetails.listMedia = this.productDetails.listMedia.sort(
            (value) => {
              return value.primary ? -1 : 1;
            }
          );
        }
      });
    });
    // const myArray = this.activatedRoute.snapshot.queryParamMap.get('myArray');
    // if (myArray === null) {
    //   this.productDetails = new Array<string>();
    // } else {
    //   return (this.productDetails = JSON.parse(myArray));
    // }
  }

  sendMessage(recipientUsername) {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: {
        title: 'Send Message',
        description: 'Send Message Description',
        productId: this.productId,
        recipientUsername,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }

  report() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: {
        title: 'Report listing',
        description: 'Please provide the reasons to report this listing',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }

  bidAndBuy() {
    if (this.auth.currentUser$) {
      const dialogRef = this.dialog.open(BidAndBuyModelComponent, {
        width: '860px',
        data: this.productDetails,
      });

      dialogRef.afterClosed().subscribe((result) => {
        
      });
    } else {
      this.router.navigate(['/auth/login']);
      localStorage.setItem('isLoggedIn', 'yes');
      localStorage.setItem('bidProductId', this.productDetails.productId);
    }
  }
  sellItem() {
    const dialogRef = this.dialog.open(SellItemModelComponent, {
      width: '860px',
      data: {
        title: 'Sell this item',
        description: 'Please provide the following to sell this listing',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }

  _albums: any = [];
  openPopup() {
    for (let i = 0; i < this.productDetails.listMedia.length; i++) {
      const element = this.productDetails.listMedia[i];
      let objs = {
        src: element.mediaURL,
        caption: 'Caption',
        thumb: element.mediaURL,
      };

      this._albums.push(objs);
    }
    this._lightbox.open(
      this._albums.slice(4, this.productDetails.listMedia.length),
      0
    );
  }
  addToCart() {
    if (this.checkInCart(this.productDetails) >= this.productDetails.productAvailableQuantity) {
      this.toastr.warning('Available qty exceeded!');
      return;
    }

    let cartId = localStorage.getItem('cartId');

    let minqtycheck = 1;
    if (minqtycheck < (+this.productDetails.minBuyingQuantity)) {
      minqtycheck = +this.productDetails.minBuyingQuantity;
    } else {
      minqtycheck = 1;
    }

    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: minqtycheck
    };

    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.store.viewCart();
        this.toastr.success('Added to cart!');
      }
    });
  }
  prodCount: any = 0;
  checkInCart(product: any) {
    let prodCount: any = 0;
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          prodCount = element.quantity;
        }
      }
    } else {
      prodCount = 0;
      this.store.totalCart$ = 0;
      return;
    }
    return prodCount;
  }


  addQuantity(product) {

    if (this.checkInCart(this.productDetails) >= this.productDetails.productAvailableQuantity) {
      this.toastr.warning('Available qty exceeded!');
      return;
    }

    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }

    let minqtycheck = prodQuantity.quantity + 1;
    if (minqtycheck < (+this.productDetails.minBuyingQuantity)) {
      minqtycheck = (+(this.productDetails.minBuyingQuantity ? this.productDetails.minBuyingQuantity : 1)) + prodQuantity.quantity;
    } else {
      minqtycheck = prodQuantity.quantity + 1;
    }


    if (prodQuantity) {
      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: minqtycheck
      };
      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.store.viewCart();
          this.toastr.success('Added to cart!');
        }
      });
    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;


      if (i !== 0) {
        i--;
        i = i;
        // i = i - (this.productDetails.minBuyingQuantity ? this.productDetails.minBuyingQuantity : 1);
        if ((i < product.minBuyingQuantity)) {
          i = 0;
        }
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.toastr.error('Removed form cart!');
            this.store.viewCart();
          }
        });
      }
    }
  }
  public uploadImage(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];

      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;

            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            // $.event.trigger({
            //   type: 'imageResized',
            //   blob: resizedImage,
            //   url: dataUrl,
            // });
          };
          $('#profileImage').attr('src', readerEvent.target.result);
          //this.profileArr.uploadedImage= readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
      /*shrink*/
      let data = {
        displayOrder: '1',
        isPrimary: false,
        productId: this.productDetails.productId,
      };
      this.store.addProductImage(event, data).subscribe(
        (res: any) => {
          if (res) {
            this.toaster.success('Image uploaded successfully!');
          } else {
            this.toaster.success(res.message);
            return;
          }
        },
        (err: any) => {
          // this.spinner.hide();
          // if (!this.common.checkValidAuthResponseCode(err)) {
          //   return;
          // }
          // if (err.error.text) {
          //   this.toastr.success(err.error.text);
          // } else {
          //   this.toastr.success(err.error.message);
          // }
        }
      );
    }
  }
}
