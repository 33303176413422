import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { StoreService } from 'src/app/services/store.service';
import { single } from './data';
import * as _ from 'underscore';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatDatepicker } from '@angular/material/datepicker';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public daterange: any = {};

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    locale: { format: 'yyyy-MM-DD' },
    alwaysShowCalendars: false,
    maxDate: new Date(),

  };

  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;


    this.fromDate = moment(this.daterange.start, 'yyyy-MM-DD').valueOf();
    this.toDate = moment(this.daterange.end, 'yyyy-MM-DD').valueOf();
  }


  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  single: any[];
  multi: any[];

  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Year';
  showYAxisLabel = true;
  yAxisLabel = 'Sales';
  yAxisTickFormatting(value) {
    return '$' + value;
  }
  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
  
  }

  colorScheme = {
    domain: ['#072c60', '#072c60', '#072c60', '#072c60'],
  };

  displayedColumns: string[] = [
    // 'checkbox',

    'active',
    'product_name',
    'product_id',
    'manufacturer_name',
    'availability',
    'retailer_price',
    'sale_price',
  ];
  dataSource;
  start_date_end;
  changeFromDate(event) {
    this.fromDate = event.target.value;
    this.start_date_end = new Date(this.fromDate);
  }
  fromNgDate = undefined;
  toNgDate = undefined;
  resetDates() {
    this.fromDate = moment(this.threeMonthAgo, 'yyyy-MM-DD').valueOf();
    this.toDate = moment(new Date(), 'yyyy-MM-DD').valueOf();
    this.initFromDate = this.fromDate;
    this.initToDate = this.toDate;
    this.start_date_end = undefined;
    this.today = new Date();
    $('#daterangeInput').val("");
    this.fromNgDate = undefined;
    this.toNgDate = undefined;
    this.daterange = {};
    this.daterange.start = this.toDate;
    this.daterange.end = this.toDate;
    this.daterange.label = "Default";
    this.daterange.updateCalendars()

    $("#daterangeInput").data().daterangepicker.startDate = this.toDate;
    $("#daterangeInput").data().daterangepicker.endDate = this.toDate;

    $("#daterangeInput").data('daterangepicker').updateView()
    $("#daterangeInput").data().daterangepicker.updateCalendars();
  }

  changeToDate(event) {
    this.toDate = event.target.value;
    this.today = new Date(this.toDate);
  }
  constructor(
    public commonService: CommonService,
    public auth: AuthService,
    platform: Platform,
    public dialog: MatDialog,
    public modalController: ModalController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public store: StoreService,

    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {
    this.single = [];
    this.metricDetails['TotalSale'] = 0;
    Object.assign(this, { single });
   
    let width = 700;

    if (platform.width() < 750) {
      width = 300;
    }
    this.view = [width, 400];
    // this.onLoad();
  }
  ngOnInit() {
    // this.onLoad();
  }

  ionViewWillEnter() {
    this.onLoad();
  }
  today = null;
  initFromDate = null;
  initToDate = null;

  onLoad() {
    this.getStoreKey();
    this.getNewCustomerMetricsByStore();
    this.getMetricsForStore();


    this.fromDate = moment(this.threeMonthAgo, 'yyyy-MM-DD').valueOf();
    this.toDate = moment(new Date(), 'yyyy-MM-DD').valueOf();
    this.initFromDate = this.fromDate;
    this.initToDate = this.toDate;

    this.today = new Date();
    // this.fromDateString = moment(this.threeMonthAgo, 'yyyy-MM-DD');
    //this.toDateString = moment(new Date(), 'yyyy-MM-DD');
  }
  threeMonthAgo = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 3,
    new Date().getDate()
  );
  fromDate = moment(this.threeMonthAgo, 'yyyy-MM-DD').valueOf();
  toDate = moment(new Date(), 'yyyy-MM-DD').valueOf();
  // fromDateString = moment(this.threeMonthAgo, 'yyyy-MM-DD');
  //toDateString = moment(new Date(), 'yyyy-MM-DD');


  public submitForm(form: any) {
    // this.toaster.info("Coming Soon!");
    // return;




    //this.presentToast(form.value);
    if (!this.fromDate || !this.toDate) {
      this.toaster.warning('Invalid details!');
      return;
    }
    this.fromDate = moment(new Date(this.fromDate), 'yyyy-MM-DD').valueOf();
    this.toDate = moment(new Date(this.toDate), 'yyyy-MM-DD').valueOf();
    //   this.fromDateString = moment(form.value.fromDate, 'yyyy-MM-DD');
    // this.toDateString = moment(form.value.toDate, 'yyyy-MM-DD');
    this.getMetricsForStore();
  }

  quickDetails = [];

  chartData = [];
  getNewCustomerMetricsByStore() {

    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate
    }
    this.spinner.show();
    this.store.getNewCustomerMetricsByStore(data).subscribe((res: any) => {

      this.spinner.hide();
      if (res) {
        this.quickDetails = res;
        return;
      } else {
      }
    });
  }


  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  metricDetails = [];
  getMetricsForStore() {

    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate
    }
    this.spinner.show();
    this.store.getMetricsForStore(data).subscribe((res: any) => {

      this.spinner.hide();
      if (res) {
        this.metricDetails = res;



        if (this.metricDetails['OrdersByMonth'].length) {
          this.chartData = [];
          this.metricDetails['OrdersByMonth'].forEach((element) => {
            this.chartData.push({
              "name": this.monthNames[element.month - 1] + " " + element.year.toString(),
              "value": parseInt(element.sumOrdersStr)
            });
          }
          );
          this.single = this.chartData;
        } else {
          this.single = [];
        }




        return;
      } else {
      }
    });
  }

  action1() {
    $("#fromDateMat").trigger("click");
  }
  action2() {
    $("#toDateMat").trigger("click");
  }
  updateProductSellingStatus(id, $event) {
    let data = {
      id: id,
      sellingStatus: $event.detail.checked,
      storeId: this.merchantStoreData.storeId,
    };
    // this.spinner.show();
    this.store.updateProductSellingStatus(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.toaster.success('Update success!');
        return;
      } else {
        this.toaster.warning('Update failed!');
        return;
      }
    });

    return;
    swal
      .fire({
        title: 'Update Status',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          return;
        }
      });
  }
  // Charts
  onSelect(event) {
  }

  merchantStoreData: any;
  getStoreKey() {
    if (this.auth.currentUser$) {
      if (!this.auth.currentUser$.hasStore) {
        return;
      }
    }

    this.spinner.show();
    this.store.getStoreKey().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.merchantStoreData = res;

        this.getAllProducts();
        return;
      } else {
      }
    });
  }
  getAllProducts1() {
    let data = {
      categoryId: '',
      filter: 'TextSearch',
      pageNo: this.activePage,
      pageSize: 1500,
      searchString: '',
      sortOption: 'productName',
      sortOrder: 'Desc',
      sendAllProducts: true,
      storeId: this.merchantStoreData.storeId,
    };
    this.getProduuctsByCategoryId(data);
  }
  getProduuctsByCategoryId(data) {
    this.store.searchProductByCategoryId(data).subscribe((res) => {
      if (res) {
        this.store.filteredCategories$ = res;
        this.store.filteredProducts$ = this.store.filteredCategories$.products;
        this.dataSource = this.store.filteredProducts$;
        this.getPage(res);
      } else {
        console.error(res);
      }
    });
  }

  getAllProducts() {
    if (!this.merchantStoreData) {
      return;
    }

    let data = {
      categoryId: '',
      filter: 'TextSearch',
      pageNo: this.activePage,
      pageSize: 8,
      searchString: '',
      sortOption: 'productName',
      sendAllProducts: true,
      sortOrder: 'Desc',
      storeId: this.merchantStoreData.storeId,
    };
    this.store.searchStoreProduct(data).subscribe((res: any) => {
      if (res) {
        this.store.filteredCategories$ = res;
        this.store.filteredProducts$ = this.store.filteredCategories$.products;
        this.dataSource = this.store.filteredProducts$;

        this.getPage(res);
        return;
      } else {
      }
    });
  }

  activePage = 0;
  total_count: any;
  pager: any = {};
  pageSize: any;
  pageFix(i, total, current) {
    if (i === current) {
      return true;
    }

    if (i < 4) {
      if (i === current) {
        return false;
      }

      return true;
    }

    if (i >= total - 4 && i < total) {
      if (i === current) {
        return false;
      }
      return true;
    }
    return false;
  }
  getPage(res) {
    this.pagination(this.activePage);
    this.total_count = res.totalpages;
    this.pager = this.getPager(this.total_count, this.activePage);
  }
  setPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.pager.totalPages - 1) {
      this.toaster.info('Reached end of pages!');
      return;
    }
    this.pager = this.getPager(this.total_count, page);
    this.getAllProducts();
  }
  pagination(page: number) {
    return page.toString();
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 1) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= this.total_count) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
  @ViewChild('target') MyProp: ElementRef;

  gotoProduct(id): void {
    this.router
      .navigate(['/list-detail'], { queryParams: { productId: id } })
      .then((res) => {
        this.MyProp.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
  }
}
