import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { VideoPopupComponent } from '../components/video-popup/video-popup.component';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private _location: Location
  ) {}

  goBack() {
    this._location.back();
  }

  openCloseMenu() {
    if (this.document.body.classList.value == 'open-menu') {
      this.document.body.classList.remove('open-menu');
    } else {
      this.document.body.classList.add('open-menu');
    }
  }

  openVideoPopup() {
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      width: '877px',
      data: '',
    });
    return dialogRef;
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
