import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItemComponent implements OnInit {
  constructor(public commonService: CommonService) {}

  ngOnInit() {}

  openVideoPopup() {
    let dialogRef = this.commonService.openVideoPopup();
    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }
}
