import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-report-listing-model',
  templateUrl: './report-listing-model.component.html',
  styleUrls: ['./report-listing-model.component.scss'],
})
export class ReportListingModelComponent implements OnInit {
  title = 'Report listing';
  description: 'Please provide the reasons to report this listing';
  productId: any;
  message: any = '';
  showEmojiPicker: boolean = false;
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    const { message } = this;
    const text = `${message}${event.emoji.native}`;
    this.message = text;
  }
  constructor(
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    @Optional() public dialogRef: MatDialogRef<ReportListingModelComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) {}
  ClickedOut(event) {
    if (
      event.target.className === 'emoji-mart-emoji' &&
      event.target.className === ''
    ) {
      this.showEmojiPicker = true;
    } else if (
      event.target.className === 'emoji-popup' ||
      event.target.className === 'mat-dialog-content report-listing' ||
      event.target.className === 'native-textarea sc-ion-textarea-md' ||
      event.target.className === 'ng-pristine ng-valid ng-touched' ||
      event.target.className === 'd-flex align-items-center icon-section'
    ) {
      this.showEmojiPicker = false;
    }
  }
  ngOnInit() {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
    if (this.data && this.data.description) {
      this.description = this.data.description;
    }

    if (this.data && this.data.productId) {
      this.productId = this.data.productId;
    }
  }

  async closeDialog() {
    this.dialogRef.close();
  }

  public sendMessage(form: any) {
    //this.presentToast(form.value);
    if (form.value.message === undefined) {
      this.toastr.error('Invalid message!');
      return;
    }
    const data = {
      loginToken: localStorage.getItem('loginToken'),
      message: form.value.message,
      parentMessageKey: '',
      productId: this.productId,
      recipientUsername: '',
    };
    //  this.spinner.show();

    this.store.sendMessage(data).subscribe(
      (res) => {
        form.reset();
        this.spinner.hide();
        this.toastr.success(res.Status);
        this.closeDialog();
      },
      (err: any) => {
        this.spinner.hide();

        //this.presentToast(err);
        if (err.error.text) {
          this.toastr.error(err.error.text);
        } else {
          this.toastr.error(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }
}
