import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
// disable console log
console.log = function(){};
console.error=function(){};
console.table=function(){};
console.warn=function(){};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
