import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
// import { Stripe } from '@ionic-native/stripe/ngx';
declare var Stripe;
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  isSellerPage: boolean = false;
  productDetails: any;
  stripe = null;
  card: any;

  load = false;
  cardNumber: string;
  cardMonth: number;
  cardYear: number;
  cardCVV: string;
  constructor(
    public location: Location,
    public store: StoreService,
    private router: Router,

    private spinner: NgxSpinnerService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    // public stripe: Stripe,
    public dialog: MatDialog,

    private auth: AuthService
  ) {

    // this.store.viewCart();
  }

  ngOnInit() {
    this.location.path() == '/checkout-message-seller'
      ? (this.isSellerPage = true)
      : (this.isSellerPage = false);

    if (this.store.cart$.shippingAddress) {
      if (this.store.cart$.shippingAddress.hasOwnProperty('active')) {
        if (this.store.cart$.shippingAddress.active) {
          this.initiatePayment();
        }
      }
    }
  }
  placeOrder() {
    let data = {
      payable: this.store.cart$.cartValue,
    };

    try {
      this.store.placeOrder(data).subscribe((res: any) => {

        if (res.success) {
          this.store.viewCart();
          this.router.navigate(['/order-sucess', res.orderId]);
        } else {

          this.toastr.warning(res.message);
        }
      });
    } catch (err) {
      this.clearCart();
      this.store.viewCart();
      this.router.navigate(['/product-dashboard/order']);
    } finally {
      this.clearCart();
      this.store.viewCart();
      this.router.navigate(['/product-dashboard/order']);
    }
  }
  clearCartOld() {
    let cartId = localStorage.getItem('cartId');
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      const element = this.store.cartProducts$[i];
      let data = {
        cartId: cartId ? cartId : '',
        discountCode: element.productDto.discountCode
          ? element.productDto.discountCode
          : '',
        quantity: 0,
        isOffer: element.productDto.hasOffer,
        productId: element.productDto.productId,
      };
      this.store.addToCart(data).subscribe((res) => {
        this.store.totalCart$ = 0;
      });
    }
  }
  clearCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: '',
      discountCode: '',
      offer: '',
      productId: 0,
      quantity: 0,
    };
    this.store.clearCart(data).subscribe((res: any) => {
      localStorage.removeItem('cartId');
      this.store.viewCart();
    });
  }
  addToCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      localStorage.setItem('cartId', res.cartId);
      this.store.viewCart();
    });
  }
  prodCount: any;
  checkInCart(product: any) {
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          this.prodCount = element.quantity;
        }
      }
    } else {
      this.prodCount = 0;
    }
    return this.prodCount;
  }





  ionViewDidLoad() {
    this.location.path() == '/checkout-message-seller'
      ? (this.isSellerPage = true)
      : (this.isSellerPage = false);

    if (this.store.cart$.shippingAddress) {
      if (this.store.cart$.shippingAddress.hasOwnProperty('active')) {
        if (this.store.cart$.shippingAddress.active) {
          this.initiatePayment();
        }
      }
    }
  }

  paymentDetails: [];

  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.card = elements.create('card', { style: style });

    this.card.mount('#card-element');

    this.card.addEventListener('change', (event) => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      this.validateCard();
    });
  }

  public submitGiftMessageForm(form: any) {
    this.store.addMessageToCart(form.value).subscribe((res) => {
      this.store.cart$ = res['Cart'];
      this.toastr.success('Delivery instructions updated!');
      form.reset();
    });
  }
  initiatePayment() {

    if (!this.store.cartProducts$ || !localStorage.getItem('cartId')) {
      return;
    }


    let data = {};
    this.store.initiatePayment(data).subscribe((res: any) => {
      if (res) {
        this.paymentDetails = res;
        localStorage.setItem('txnId', this.paymentDetails['TransactionNumber']);
        this.stripe = Stripe(this.paymentDetails['StripePublicKey']);
        if (this.isSellerPage)
          this.setupStripe();
        return;
      } else {
      }
    });
  }

  validateCard() {

    if (this.load) {
      return;
    }
    this.load = true;
    const data = {
      name: "Test",
      address_line1: "Test",
      address_line2: "Test",
      address_city: "Test",
      address_state: "Test",
      address_zip: "12345",
      address_country: "US"
    }
    this.stripe.createToken(this.card, data)
      .then((token) => {
        let data = {
          payable: this.store.cart$.cartValue,
          token: token,
          txnId: localStorage.getItem('txnId'),
        };
        try {
          this.store.placeStripeOrder(data).subscribe((res: any) => {
            if (res.hasOwnProperty('success')) {
              if (!res.success) {
                this.toastr.warning(res.message);
                return;
              }
            }

            localStorage.removeItem('cartId');
            this.store.totalCart$ = 0;
            this.store.cart$ = [];
            this.store.cartProducts$ = [];
            this.store.viewCart();
            this.router.navigate(['/order-sucess', res.orderId]);
          });
        } catch (err) {
          this.spinner.hide();
          this.load = false;
          this.toastr.warning("SOMETHING WENT WRONG!")
        } finally {
          //this.spinner.hide();
          //this.load = false;
        }
      })
      .catch((error) => {


        this.load = false;
        console.error(error);
      }
      );
  }








}
