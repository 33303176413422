
import { Component, ElementRef, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'underscore';


@Component({
  selector: 'app-listing-section',
  templateUrl: './listing-section.component.html',
  styleUrls: ['./listing-section.component.scss'],
})
export class ListingSectionComponent implements OnInit {
  collection = [];
  p: any;
  @ViewChild(FilterPanelComponent) child: FilterPanelComponent;


  // @Output() clearModalFilter = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public modalController: ModalController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    private auth: AuthService,
    public toaster: ToastrService,
    public store: StoreService
  ) {



    this.env = localStorage.getItem("environment");
  }
  rate = 2;

  selectedView: string = 'Block';
  categoryId: any;
  subCategoryId: any;
  filterText: any;
  searchInput: any;
  searchText = "";

  parseURLData() {
    this.store.getHomePageListSectionDetails();
    this.categoryId = '';
    this.subCategoryId = '';

    this.store.manName = "";
    this.env = localStorage.getItem("environment");



    this.activatedRoute.queryParams.subscribe((res) => {

      if (res.searchText) {

        this.searchText = res.searchText;
        this.store.searchTerm$ = res.searchText;
        this.spinner.show();
        setTimeout(() => {
          this.activePage = 0;
          this.store.manName = "";

          this.requestJson.sortOption = 'productId';

          this.setFilteredItems(res.searchText);
          this.spinner.hide();
        }, 1000);
      } else {
        this.searchText = "";
        this.store.searchTerm$ = "";

        this.activatedRoute.params.subscribe((res) => {



          this.categoryId = res.categoryId;
          this.subCategoryId = res.subCategoryId;


          if (this.categoryId === 'NewArrival') {
            this.getNewArrivalProducts();
          } else if (this.categoryId === 'ALL') {
            this.getAllProducts();
          } else {
            this.getProducts();
          }



        });
        if (this.categoryId === 'manufacturer') {

          if (!this.router.url.split('/')[3].includes('M-')) {
            this.requestJson.categoryId = this.router.url.split('/')[3];
          }

          if (!this.store.brandIds$.length) {
            if (this.requestJson.categoryId) {
              this.router.navigate(['/browse-listing', this.requestJson.categoryId]);
            } else {
              this.clearFilter();
            }
            return;
          }
          this.getManName(this.subCategoryId);
        } else if (this.categoryId === 'ALL') {
          //do nothing for now
        } else {
          this.store.manName = "";
          this.store.brandIds$ = [];
        }
        setTimeout(() => {
          if (this.categoryId === 'manufacturer') {

            if (!this.router.url.split('/')[3].includes('M-')) {
              this.requestJson.categoryId = this.router.url.split('/')[3];
            }

            this.getManName(this.subCategoryId);
          } else if (this.categoryId === 'ALL') {
            //do nothing for now
          } else {
            this.store.manName = "";
            this.store.brandIds$ = [];
          }
        }, 1000);
      }

    });


  }
  ngOnInit() {
    this.parseURLData();
  }
  ionViewDidEnter() {


  }
  getSelectedManufacturers() {
    let manNames = [];
    this.store.manufacturers$.forEach(element => {
      if (this.store.brandIds$.includes(parseInt(element.brandId))) {
        manNames.push(element.brandName);
      }
    });
    // this.store.manName = manNames;

    if (manNames.length) {

      return ' " ' + manNames.toString() + ' "';
    } else {

      return " ";
    }
  }
  getManName(manId) {
    let manName = "";
    this.store.manufacturers$.forEach(element => {
      if (parseInt(element.brandId) === parseInt(manId)) {
        manName = element.brandName;
      }
    });
    this.store.manName = manName;
    return manName;
  }
  onLoad() {
    if (this.store.filterSort) {
      this.categoryFilter(this.store.filterSort)
    }
  }
  env = "web";
  getProducts() {
    this.requestJson.categoryId = this.categoryId;
    this.requestJson.filter = 'ProductsByCategory';
    this.requestJson.subCategoryId = '';
    this.requestJson.pageNo = this.activePage;
    this.requestJson.pageSize = 9;
    this.requestJson.searchString = '';
    this.requestJson.sortOrder = 'Desc';
    this.requestJson.sendAllProducts = false;

    // this.requestJson = {
    //   categoryId: this.categoryId,
    //   filter: 'ProductsByCategory',
    //   subCategoryId: '',
    //   brandIds: [0],
    //   pageNo: this.activePage,
    //   pageSize: 9,
    //   searchString: '',
    //   sortOption: 'productId',
    //   sortOrder: 'Desc',
    //   sendAllProducts: false,
    // };
    this.requestJson.subCategoryId = this.subCategoryId !== '' ? this.subCategoryId : '';
    if (this.categoryId === 'manufacturer') {

      this.requestJson.categoryId = '';
      this.requestJson.subCategoryId = '';
      if (!this.router.url.split('/')[3].includes('M-')) {
        this.requestJson.categoryId = this.router.url.split('/')[3];
      }


      this.requestJson.sendAllProducts = true;
      this.requestJson.filter = 'Manufacturer';
      // brandIds: [parseInt(this.subCategoryId)],
      this.requestJson.filterEx = {

        brandIds: this.store.brandIds$,
        brandName: [''],
        colors: [''],
        material: [''],
        priceFrom: this.store.min,
        priceTo: this.store.max,
        size: [''],
      };

      // this.requestJson.brandIds = [this.subCategoryId];

      this.requestJson.brandIds = this.store.brandIds$;
    }
    if (!this.requestJson.sortOption) {
      this.requestJson.sortOption = 'productId';
    }
    this.getProduuctsByCategoryId(this.requestJson);
  }


  setFilteredItems(searchText) {

    // alert(searchText);
    this.requestJson = {
      categoryId: '',
      filter: 'TextSearch',
      subCategoryId: '',
      pageNo: this.activePage,
      pageSize: 9,
      searchString: searchText,
      sortOption: 'productId',
      sortOrder: 'Desc',
      sendAllProducts: true,
    };
    this.categoryId = this.router.url.split('/')[2];
    this.subCategoryId = this.router.url.split('/')[3];

    if (this.categoryId === 'manufacturer') {
      if (!this.router.url.split('/')[3].includes('M-')) {
        this.requestJson.categoryId = this.router.url.split('/')[3];
      }
      if (!this.store.brandIds$.length) {
        if (this.requestJson.categoryId) {
          this.router.navigate(['/browse-listing', this.requestJson.categoryId]);
        } else {
          this.clearFilter();
        }
        return;
      }

      // this.requestJson.brandIds = [parseInt(this.subCategoryId)];
      this.requestJson.brandIds = this.store.brandIds$;
      this.requestJson.brandName = [''];
    } else {
      this.store.manName = "";

    }


    this.getProduuctsByCategoryId(this.requestJson);
  }
  requestJson: any = {};

  getNewArrivalProducts() {
    this.requestJson.categoryId = '';
    this.requestJson.filter = 'NewArrival';
    this.requestJson.subCategoryId = '';
    this.requestJson.pageNo = this.activePage;
    this.requestJson.pageSize = 9;
    this.requestJson.searchString = '';
    this.requestJson.sortOrder = 'Desc';
    this.requestJson.sendAllProducts = false;

    if (!this.requestJson.sortOption) {
      this.requestJson.sortOption = 'productId';
    }
    this.getProduuctsByCategoryId(this.requestJson);
  }

  getAllProducts() {

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();


      this.requestJson.categoryId = '';
      this.requestJson.filter = 'SearchByTagOnly';
      this.requestJson.subCategoryId = '';
      this.requestJson.pageNo = this.activePage;
      this.requestJson.pageSize = 9;
      this.requestJson.searchString = '';
      this.requestJson.sortOrder = 'Desc';
      this.requestJson.sendAllProducts = false;
      // this.getProduuctsByCategoryId(this.requestJson);

      if (this.categoryId === 'ALL') {
        this.requestJson.sendAllProducts = true;
        this.store.brandIds$ = [];
        this.store.manufacturers$.forEach(element => {
          this.store.brandIds$.push(element.brandId);
        });
        this.requestJson.filter = 'Manufacturer';
        this.requestJson.brandIds = this.store.brandIds$;
        this.requestJson.brandName = [''];
        this.requestJson.searchString = this.store.headerSearchTerm$
          ? this.store.headerSearchTerm$
          : '';
      }
      if (!this.requestJson.sortOption) {
        this.requestJson.sortOption = 'productId';
      }
      // this.parseURLData();

      this.getProduuctsByCategoryId(this.requestJson);
    }, 2000);
  }

  activePage = 0;
  total_count: any;
  pager: any = {};
  total_pages = 0;
  pageSize = 4;
  pageFix(i, total, current) {
    if (i === current) {
      return true;
    }

    if (i < 4) {
      if (i === current) {
        return false;
      }

      return true;
    }

    if (i >= total - 4 && i < total) {
      if (i === current) {
        return false;
      }
      return true;
    }
    return false;
  }


  getPage(res) {
    this.pagination(this.activePage);
    this.total_count = res.totalelements;
    this.total_pages = res.totalpages;
    this.pager = this.getPager(this.total_count, this.activePage, this.pageSize);

  }
  scroll() {
    this.MyProp.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  getProduuctsByCategoryId(data) {

    this.store.searchProductByCategoryId(data).subscribe((res) => {
      if (res) {
        try {
          this.store.filteredCategories$ = res;
          this.store.filteredProducts$ = this.store.filteredCategories$.products;
          this.categoryId = this.router.url.split('/')[2];
          this.subCategoryId = this.router.url.split('/')[3];
          if (this.categoryId === 'ALL') {
            this.store.searchCategoryName$ = 'ALL';
            this.requestJson.brandIds = this.store.brandIds$;
          } else {
            if (this.store.filteredCategories$) {

              if (this.store.filteredCategories$.products[0].categoryName) {
                this.store.searchCategoryName$ =
                  this.store.filteredCategories$.products[0].categoryName;

              }
            }

          }
          if (this.subCategoryId) {
            if (this.store.filteredCategories$) {
              if (this.store.filteredCategories$.products[0].subCategoryname) {
                this.store.searchCategoryName$ =
                  this.store.filteredCategories$.products[0].subCategoryname;
              }
            }
          }
        } catch (error) {
          //do nothing
        }
        // this.parseURLData();
        this.getPage(res);
      } else {
        console.error(res);
      }
    });
  }

  @ViewChild('target') MyProp: ElementRef;
  setPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.pager.totalPages - 1) {
      this.toaster.info('Reached end of pages!');
      return;
    }
    this.pager = this.getPager(this.total_count, page, this.pageSize);



    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.searchText) {
        this.searchText = res.searchText;
        this.spinner.show();
        setTimeout(() => {

          this.setFilteredItems(res.searchText);
          this.spinner.hide();
        }, 1000);

      } else {
        this.searchText = "";
        this.store.searchTerm$ = "";
        if (this.categoryId === 'NewArrival') {
          this.getNewArrivalProducts();
        } else if (this.categoryId === 'ALL') {
          this.getAllProducts();
        } else {
          this.getProducts();
        }
      }

    });



    setTimeout(() => {
      this.MyProp.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 500);


  }

  pagination(page: number) {
    return page.toString();
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {

        if ((totalPages - (currentPage - 2)) == 5) {
          startPage = currentPage - 1;
          endPage = currentPage + 3;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage - 1, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: FilterPanelComponent,
      cssClass: 'my-filter-class',
    });
    return await modal.present();
  }
  filterItems: any = [
    // { title: 'None', value: 'none' },
    { title: 'Most Relevant', value: 'mostRelevant' },
    { title: 'Featured', value: 'isFeatured' },
    { title: 'Hot Selling', value: 'isHotSelling' },
    { title: 'New Arrival', value: 'createdAt' },
  ];
  


  categoryFilter(item: any) {
    this.store.filterSort = item;
    this.store.filterTitle = item.title;

    if (item.value === 'none') {
      this.clearFilter();
      return;
    } else if (item.value === 'mostRelevant') {

      this.requestJson.sortOption = 'productId';
    } else {
      this.requestJson.sortOption = item.value;
    }

    // this.requestJson.pageNo = 0;
    this.requestJson.pageSize = 9;

    this.store.getProduuctsByCategoryId(this.requestJson);
  }
  clearFilter() {
    this.store.min=0;
    this.store.max=25000;
    // this.child.clearModalFilter();

    setTimeout(() => {
      this.router.navigate(['/browse-listing/ALL']).then((res) => {
        this.store.searchTerm$ = '';
        this.searchText = "";
        this.store.headerSearchTerm$ = '';
        this.store.manName = "";
        //this.store.filterSort = null;
        //this.store.filterTitle = 'None';
        //this.requestJson.sortOption = 'productId';
        this.store.brandIds$ = [];
        this.store.manufacturers$.forEach(element => {
          this.store.brandIds$.push(element.brandId);
        });
        this.child.clearModalFilter();
        return this.getAllProducts();
      });   
    }, 1500);

  }

  onValChange(ev) {
    this.selectedView = ev;
    console.log(ev);
  }
}
