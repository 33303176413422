import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {

  }
  state: boolean;

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
      const data = {
        loginToken: localStorage.getItem('loginToken')
      };
      this.authService.validateToken(data).subscribe(
        (result) => {
          if (result) {
            this.state = true;
            res(this.state);
          } else {
            this.authService.logout();
            this.router.navigate(["/auth/login"]);
            this.state = false;
            res(this.state);
          }
        },
        (err) => {
          this.authService.logout();
          this.router.navigate(["/auth/login"]);
          this.state = false;
          res(this.state);
        }
      );
    });
  }
}
