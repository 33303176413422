import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  rate = 2;
  sliceAmount = 398;
  reviewText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget
  odio
  nec ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit amet,
  consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget odio nec ultricies tristique
  nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  Egestas elementum eu velit egestas duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at amet diam sed
  diam
  urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas
  duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem
  ipsum
  dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget odio nec
  ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur
  adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at
  amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas elementum
  eu velit egestas duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget
  turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis.
  Eget odio nec ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit
  amet,
  consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget odio nec ultricies tristique
  nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  Egestas elementum eu velit egestas duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at amet diam sed
  diam
  urna massa eget turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas
  duis facilisis. Eget odio nec ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.Lorem
  ipsum
  dolor sit amet, consectetur adipiscing elit. Egestas elementum eu velit egestas duis facilisis. Eget odio nec
  ultricies tristique nisl. Etiam at amet diam sed diam urna massa eget turpis.`;
  constructor() { }

  ngOnInit() { }

}
