import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-secondery-menu',
  templateUrl: './secondery-menu.component.html',
  styleUrls: ['./secondery-menu.component.scss'],
})
export class SeconderyMenuComponent implements OnInit {

  

  constructor(public commonService: CommonService) { }

  ngOnInit() { 
    
  }

  closeMenu() { }
}
