import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery"
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  orderId = "";

  constructor(private spinner: NgxSpinnerService,
    public toaster: ToastrService, private router: Router,
    
    private theInAppBrowser: InAppBrowser,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,) {
    if (this.router.url.split('/')[3]) {
      this.orderId = this.router.url.split('/')[3];
      this.getOrderDetails(this.orderId);
    }
  }

  ngOnInit() { }
  options: InAppBrowserOptions = {
    location: 'yes',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes',//Android only ,shows browser zoom controls 
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
    hideurlbar: 'yes', // hide the url toolbar
  };
  
  public openWithSystemBrowser(url: string) {
    let target = "_system";
    let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }

  load = false;
  public getPDF(id, name) {

    this.load = true;
    this.spinner.show();
    var HTML_Width = $("#invoice").width();
    var HTML_Height = $("#invoice").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    setTimeout(() => {

      this.load = false;
      this.spinner.hide();
      // this.toastr.info("Invoice Downloaded!");
    }, 6000);
    html2canvas($("#invoice")[0]).then(function (canvas) {
      canvas.getContext('2d');
      var imgData = canvas.toDataURL("image/png");
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width.toString(), "p");
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(name + ".pdf");


      //this.spinner.hide();
      // if (localStorage.getItem('environment') !== 'web') {
      //   this.openWithSystemBrowser(name + ".pdf");
      // } else {
      //   window.open(name + ".pdf", 'Download');
      // }

     
    });
  };

  orderDetails = [];
  getOrderDetails(id) {
    let data = {
      id: id
    }
    this.spinner.show();
    this.store.getOrderDetails(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        
        this.orderDetails = res;
        return;
      } else {
      }
    });
  }
}
