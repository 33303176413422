import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent implements OnInit {

  title = "Add/Edit Address";
  description: ''

  constructor(
    @Optional() public dialogRef: MatDialogRef<AddressDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
    if (this.data && this.data.description) {
      this.description = this.data.description;
    }
  }
  close(){
    this.dialogRef.close();
  }
}
