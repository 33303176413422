import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-id-check',
  templateUrl: './id-check.component.html',
  styleUrls: ['./id-check.component.scss'],
})
export class IdCheckComponent implements OnInit {

  uploadedFileName = "filename";

  constructor(
    public store: StoreService,
    public auth: AuthService,
    private toastr: ToastrService
  ) {
    this.getArtifacts();
  }

  ngOnInit() {
    this.getArtifacts();
  }
  doRefresh(event) {
    this.getArtifacts();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  ValidateSize(file, limit) {
    var FileSize = file.files[0].size / 1024 / 1024; // in MiB
    if (FileSize > limit) {
      return false;
    } else {
      return true;
    }
  }

  public uploadImage(event: any, artifactType, spinner): void {


    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];


      if (!this.ValidateSize(event.target, 5)) {
        this.toastr.warning("Please upload image that is less than 5mb!");
        return;
      }


      if (spinner === 'front') {
        this.frontSpinner = true;
      } else {
        this.backSpinner = true;
      }
      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;

            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            // $.event.trigger({
            //   type: 'imageResized',
            //   blob: resizedImage,
            //   url: dataUrl,
            // });
          };
          if (artifactType === 'IDProofFront') {
            $('#frontImage').attr('src', readerEvent.target.result);
          } else {
            $('#backImage ').attr('src', readerEvent.target.result);
          }

          //this.profileArr.uploadedImage= readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
      /*shrink*/
      let data = {
        artifactType: artifactType,
      };
      this.store.updateIdPic(event, data).subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success('ID picture uploaded successfully!');
            this.uploadedFileName = file.name;
            this.frontSpinner = false;
            this.backSpinner = false;
            this.getArtifacts();
          } else {
            this.frontSpinner = false;
            this.backSpinner = false;
            this.toastr.success(res.message);
            return;
          }
        },
        (err: any) => {
         
        }
      );
    }
  }
  frontArtifact: any;
  backArtifact: any;
  frontSpinner: any = false;
  backSpinner: any = false;

  getArtifacts() {
    let data = {
      artifactType: 'IDProofFront',
    };
    this.store.getArtifacts(data).subscribe((res: any) => {
      if (res) {
        this.frontSpinner = false;
        this.frontArtifact = res;
        return;
      } else {
        this.frontSpinner = false;
      }
    });

    let data1 = {
      artifactType: 'IDProofReverse',
    };
    this.store.getArtifacts(data1).subscribe((res: any) => {
      if (res) {
    this.backSpinner = false;
        this.backArtifact = res;
        return;
      } else {
        this.backSpinner = false;
      }
    });
  }
}
