import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.scss'],
})
export class AddListingComponent implements OnInit {
  constructor(public router: Router, public store: StoreService) {}
  categoryId: any;
  searchText: any = '';
  filteredProducts: any = [];
  ngOnInit() {
    // this.getAllProducts();
  }

  gotoDetailPage() {
    this.router.navigateByUrl(
      `/list-detail?myArray=%7B"productId":28,"productName":"82361059%20Hood%20Mirror,%20RH%20-%20AFTERMARKET","productDescription":"Includes:%201%20Mounting%20Plate%20%26%204%20Lock%20Nuts.%20CROSS%20REFERENCES:%20VTC-49882,%20GS-V-012BR,%206743CR","productShortDescription":"Includes:%201%20Mounting%20Plate%20%26%204%20Lock%20Nuts.%20CROSS%20REFERENCES:%20VTC-49882,%20GS-V-012BR,%206743CR","productSellingPrice":163.16,"categoryName":"Drivetrain","categoryId":4,"subCategoryname":"PTO","productCreditDuration":0,"minBuyingQuantity":1,"productBarCode":"5340662450","hasOffer":false,"productOfferPrice":143.16,"listMedia":%5B%7B"mediaURL":"https:%2F%2Fappmediaencriss.s3.ap-south-1.amazonaws.com%2Ftheretailgrid%2F2%2F1%2Fproduct_images%2F1.HOODMIRROR.png","mediaType":"IMAGE","mediaTags":"","displayOrder":1,"primary":true%7D,%7B"mediaURL":"https:%2F%2Fappmediaencriss.s3.ap-south-1.amazonaws.com%2Ftheretailgrid%2F2%2F1%2Fproduct_images%2F1.HOODMIRROR.png","mediaType":"IMAGE","mediaTags":"","displayOrder":1,"primary":true%7D%5D,"availableQuantity":100,"shortName":"PART26","productHowToUse":null,"brandName":"Mercedes%20Benz","storeId":"1ddb383e-0042-4afa-b6ff-e4963a78e0fd","message":null,"productMeasure":null,"active":true,"product":false,"hotSelling":true%7D`
    );
  }
  getAllProducts(text: any) {
    if (!this.searchText.length) {
      this.filteredProducts = [];
      return;
    } else {
      let data = {
        categoryId: '',
        filter: 'TextSearch',
        subCategoryId: '',
        pageNo: '0',
        pageSize: 8,
        searchString: this.searchText,
        sortOption: 'productId',
        sortOrder: 'Desc',
        sendAllProducts: true,
      };

      this.store.searchProductByText(data).subscribe((res: any) => {
        if (res) {
          if (!this.searchText.length) {
            this.filteredProducts = [];
            return;
          } else {
            this.filteredProducts = res.products;
          }
        }
      });
    }
  }
  openProductInNewWindow(id) {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/product-dashboard/sell-item/${id}`])
    );

    window.open(url, '_blank');
  }
}
