import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowseByCategoryComponent } from './components/home-page-component/browse-by-category/browse-by-category.component';
import { BrowseByManufacturerComponent } from './components/home-page-component/browse-by-manufacturer/browse-by-manufacturer.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleMapComponent } from './components/home-page-component/google-map/google-map.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeBannerBuyComponent } from './components/home-page-component/home-banner-buy/home-banner-buy.component';
import { HomeBannerSellComponent } from './components/home-page-component/home-banner-sell/home-banner-sell.component';
import { HowItWorkComponent } from './components/home-page-component/how-it-work/how-it-work.component';
import { ListingSectionComponent } from './components/browse-listing-components/listing-section/listing-section.component';
import { NewestListingsComponent } from './components/home-page-component/newest-listings/newest-listings.component';
import { NewsItemComponent } from './components/home-page-component/newest-listings/news-item/news-item.component';
import { SearchComponent } from './components/browse-listing-components/search/search.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ListItemComponent } from './components/browse-listing-components/listing-section/list-item/list-item.component';

import { BarRatingModule } from 'ngx-bar-rating';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { ProductDetailSectionComponent } from './components/product-detail-section/product-detail-section.component';
import { SeconderyMenuComponent } from './components/product-dashboard/secondery-menu/secondery-menu.component';

import { TabsComponent } from './pages/tabs/tabs.component';

import { NoListingComponent } from './components/product-dashboard/no-listing/no-listing.component';
import { AddListingComponent } from './components/product-dashboard/add-listing/add-listing.component';
import { SellItemComponent } from './components/product-dashboard/sell-item/sell-item.component';
import { SelectItemComponent } from './components/product-dashboard/sell-item/select-item/select-item.component';
import { VideoPopupComponent } from './components/video-popup/video-popup.component';
import { SellItemShippingComponent } from './components/product-dashboard/sell-item/sell-item-shipping/sell-item-shipping.component';
import { ReportListingModelComponent } from './components/report-listing-model/report-listing-model.component';
import { BidAndBuyModelComponent } from './components/bid-and-buy-model/bid-and-buy-model.component';
import { ProductListItemComponent } from './components/product-list-item/product-list-item.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { OrdersComponent } from './components/product-dashboard/orders/orders.component';
import { OrderDetailsComponent } from './components/product-dashboard/orders/order-details/order-details.component';
import { InvoicesComponent } from './components/product-dashboard/orders/invoices/invoices.component';
import { LightboxModule } from 'ngx-lightbox';
import { SellDetailsComponent } from './components/product-dashboard/sell-item/sell-details/sell-details.component';
import { SettingsComponent } from './components/product-dashboard/settings/settings.component';

import { MatTabsModule } from '@angular/material/tabs';
import { ListViewItemComponent } from './components/browse-listing-components/list-view-item/list-view-item.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MyCartComponent } from './components/checkout/my-cart/my-cart.component';
import { SummaryComponent } from './components/checkout/summary/summary.component';
import { SendMessageSellerComponent } from './components/checkout/send-message-seller/send-message-seller.component';
import { ReportListComponent } from './components/product-dashboard/report-list/report-list.component';

import { MatTableModule } from '@angular/material/table';
import { DashboardComponent } from './components/product-dashboard/dashboard/dashboard.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { InboxComponent } from './components/product-dashboard/inbox/inbox.component';
import { MailBoxComponent } from './components/product-dashboard/mail-box/mail-box.component';
import { RoundPipe } from './services/round.pipe';
import { IdCheckComponent } from './components/settings/id-check/id-check.component';
import { ProfileComponent } from './components/settings/profile/profile.component';
import { PasswordResetComponent } from './components/settings/password-reset/password-reset.component';

import { DeleteAccountComponent } from './components/settings/delete-account/delete-account.component';
import { PaymentComponent } from './components/settings/payment/payment.component';
import { RequestsComponent } from './components/settings/requests/requests.component';
import { RequestsBrowseComponent } from './components/settings/requests-browse/requests-browse.component';
import { PartRequestsComponent } from './components/settings/part-requests/part-requests.component';
import { ReviewsComponent } from './components/browse-listing-components/reviews/reviews.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RequestResultsComponent } from './components/product-dashboard/request-results/request-results.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AddressesComponent } from './components/product-dashboard/addresses/addresses.component';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';

import { JwPaginationModule } from 'jw-angular-pagination';

import { Daterangepicker } from 'ng2-daterangepicker';
import { TrackTableComponent } from './components/track-table/track-table.component';
 
@NgModule({
  imports: [
    JwPaginationModule,
    Daterangepicker,
    PickerModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    AgmCoreModule,
    BarRatingModule,
    HttpClientModule,
    MatStepperModule,
    MatTooltipModule,
    MatDialogModule,
    LightboxModule,
    MatTabsModule,
    NgxSpinnerModule,
    MatTableModule,
    MatProgressSpinnerModule,
    CdkAccordionModule,
    NgxPaginationModule,
    NgxChartsModule,
    NgSelectModule,
    ClickOutsideModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    ReviewsComponent,
    RequestsComponent,
    PaymentComponent,
    ProfileComponent,
    PasswordResetComponent,
    DeleteAccountComponent,
    IdCheckComponent,
    HeaderComponent,
    HomeBannerBuyComponent,
    HomeBannerSellComponent,
    BrowseByCategoryComponent,
    CategoryItemComponent,
    BrowseByManufacturerComponent,
    NewestListingsComponent,
    NewsItemComponent,
    HowItWorkComponent,
    GoogleMapComponent,
    FooterComponent,
    SearchComponent,
    ListingSectionComponent,
    ListItemComponent,
    FilterPanelComponent,
    TrackTableComponent,
    ProductDetailSectionComponent,
    SeconderyMenuComponent,
    TabsComponent,
    NoListingComponent,
    RequestResultsComponent,
    AddListingComponent,
    SellItemComponent,
    SelectItemComponent,
    SellDetailsComponent,
    VideoPopupComponent,
    SellItemShippingComponent,
    ReportListingModelComponent,
    BidAndBuyModelComponent,
    ProductListItemComponent,
    OrderItemComponent,
    OrdersComponent,
    OrderDetailsComponent,
    InvoicesComponent,
    AddressesComponent,
    SettingsComponent,
    MyCartComponent,
    ListViewItemComponent,
    SummaryComponent,
    SendMessageSellerComponent,
    ReportListComponent,
    DashboardComponent,
    InboxComponent,
    MailBoxComponent,
    RoundPipe,
    RequestsBrowseComponent,
    PartRequestsComponent,
  ],
  exports: [
    InboxComponent,
    SettingsComponent,
    LightboxModule,
    MatStepperModule,
    HttpClientModule,
    BarRatingModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    HomeBannerBuyComponent,
    HomeBannerSellComponent,
    BrowseByCategoryComponent,
    CategoryItemComponent,
    BrowseByManufacturerComponent,
    NewestListingsComponent,
    NewsItemComponent,
    HowItWorkComponent,
    GoogleMapComponent,
    FooterComponent,
    SearchComponent,
    ListingSectionComponent,
    ListItemComponent,
    AgmCoreModule,
    FilterPanelComponent,
    ProductDetailSectionComponent,
    SeconderyMenuComponent,
    NoListingComponent,
    RequestResultsComponent,
    AddListingComponent,
    SellItemComponent,
    SelectItemComponent,
    SellDetailsComponent,
    MatTooltipModule,
    VideoPopupComponent,
    MatDialogModule,
    SellItemShippingComponent,
    BidAndBuyModelComponent,
    ProductListItemComponent,
    OrderItemComponent,
    OrdersComponent,
    OrderDetailsComponent,
    InvoicesComponent,
    AddressesComponent,
    MatTabsModule,
    ListViewItemComponent,
    MyCartComponent,
    SummaryComponent,
    SendMessageSellerComponent,
    ReportListComponent,
    MatTableModule,
    DashboardComponent,
    MatProgressSpinnerModule,
    CdkAccordionModule,
    MailBoxComponent,
    IdCheckComponent,
    ProfileComponent,
    PasswordResetComponent,
    
    DeleteAccountComponent,
    PaymentComponent,
    RequestsComponent,
    RequestsBrowseComponent,
    PartRequestsComponent,
    ReviewsComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
})
export class SharedModule {}
