import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { AddressDialogComponent } from '../../address-dialog/address-dialog.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
// import { Stripe } from '@ionic-native/stripe/ngx';
// declare var Stripe;

@Component({
  selector: 'app-send-message-seller',
  templateUrl: './send-message-seller.component.html',
  styleUrls: ['./send-message-seller.component.scss'],
})
export class SendMessageSellerComponent implements OnInit {
  stripe = null;
  card: any;

  cardNumber: string;
  cardMonth: number;
  cardYear: number;
  cardCVV: string;

  constructor(
    public store: StoreService,
    private toastr: ToastrService,
    public auth:AuthService,
    private router: Router,
    // public stripe: Stripe,
    public dialog: MatDialog
  ) {}
  ionViewDidLoad() {}

  paymentDetails: [];
  ngOnInit() {
    //this.initiatePayment();
  }
  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.card = elements.create('card', { style: style });

    this.card.mount('#card-element');

    this.card.addEventListener('change', (event) => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      this.validateCard();
    });
  }

  public submitGiftMessageForm(form: any) {
    this.store.addMessageToCart(form.value).subscribe((res) => {
      this.store.cart$ = res['Cart'];
      this.toastr.success('Delivery instructions updated!');
      form.reset();
    });
  }

  validateCard() {
    const userAddress = JSON.parse(localStorage.getItem('userAddress'));
    if (!JSON.parse(localStorage.getItem('userAddress'))) {
      this.toastr.error('Please select valid shipping address!');
      return;
    }
    const data = {
      name: userAddress.customerFullName,
      address_line1: userAddress.addLine1,
      address_line2: userAddress.addLine2,
      address_city: userAddress.city,
      address_state: userAddress.addState,
      address_zip:'12345',
      address_country: "US"
    }
    this.stripe.createToken(this.card, data)
      .then((token) => {
        let data = {
          payable: this.store.cart$.cartValue,
          token: token,
          txnId: localStorage.getItem('txnId'),
        };

        try {
          this.store.placeStripeOrder(data).subscribe((res:any) => {
            this.store.viewCart();
            this.router.navigate(['/order-sucess', res.orderId]);
          });
        } catch (err) {
          this.store.viewCart();
          this.router.navigate(['/product-dashboard/order']);
        } finally {
          this.store.viewCart();
          this.router.navigate(['/product-dashboard/order']);
        }
      })
      .catch((error) => console.error(error));
  }
}
