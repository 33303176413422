import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-browse-by-manufacturer',
  templateUrl: './browse-by-manufacturer.component.html',
  styleUrls: ['./browse-by-manufacturer.component.scss'],
})
export class BrowseByManufacturerComponent implements OnInit {
  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;
  @Input() requestJson;
  slideOptsTwo = {
    loop: true,
    navigation: true,
    autoplay: true,
  };
  next(object, slideView) {
    slideView.slideNext();
  }

  prev(object, slideView) {
    slideView.slidePrev();
  }

  constructor(public store: StoreService,
    
    
    
    public router: Router
    ) {}

  ngOnInit() {}

    action(item, isAll = false) {

    if (isAll) {
      this.store.brandIds$ = [];
    }
    this.store.searchTerm$ = "";

    if (!this.store.brandIds$.includes(item.brandId)) {
      this.store.brandIds$.push(item.brandId);
    } else {
      this.store.brandIds$.splice(this.store.brandIds$.indexOf(item.brandId), 1);
    }

    this.router.navigate(['/browse-listing/manufacturer/' + "M-" + Math.floor(Math.random() * 1000000000)]);
  }
}
