import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { IonSlides } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-newest-listings',
  templateUrl: './newest-listings.component.html',
  styleUrls: ['./newest-listings.component.scss'],
})
export class NewestListingsComponent implements OnInit {
  @ViewChild('slideWithNav2') slideWithNav2: IonSlides;
  // @ViewChild('slideWithNav3', { static: false }) slideWithNav3: IonSlides;

  // slideOpts = {
  //   slidesPerView: 'auto',
  //   loopedSlides: '3',
  //   loopPreventsSlide: true,
  //   loop: true,
  //   navigation: true,
  //   autoplay: true,
  // };

  slideOptsThreee = {
    loop: true,
    navigation: true,
    autoplay: true,
  };
  slideOptsThree = {
    initialSlide: 0,
    speed: 400,
    pagination: true,
    loop: false,
    navigation: true,
    // autoplay:true,
    slidesPerView: 1,
    spaceBetween: 0,

    // initialSlide: 0,

    // freeMode: false,
    breakpoints: {
      '640': {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      '768': {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      '1024': {
        slidesPerView: 6,
        spaceBetween: 8,
      },
    },
  };

  newListings: any = [
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Honda',
    //   productName: 'Product Name',
    //   description: 'Lorem ipsum long description',
    //   offerPrice: 200,
    //   marketPrice: 250,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Kawasaki',
    //   productName: 'Product Name2',
    //   description: 'Lorem ipsum long description',
    //   offerPrice: 210,
    //   marketPrice: 260,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Subaru',
    //   productName: 'Product Name3',
    //   description: 'Lorem ipsum long description3',
    //   offerPrice: 220,
    //   marketPrice: 270,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Jaguar',
    //   productName: 'Product Name4',
    //   description: 'Lorem ipsum long description4',
    //   offerPrice: 290,
    //   marketPrice: 330,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Proton',
    //   productName: 'Product Name5',
    //   description: 'Lorem ipsum long description5',
    //   offerPrice: 190,
    //   marketPrice: 200,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Subaru6',
    //   productName: 'Product Name6',
    //   description: 'Lorem ipsum long description6',
    //   offerPrice: 220,
    //   marketPrice: 270,
    // },
    // {
    //   imgPath: './../../../../assets/logo/logo.svg',
    //   brandName: 'Jaguar7',
    //   productName: 'Product Name7',
    //   description: 'Lorem ipsum long description7',
    //   offerPrice: 290,
    //   marketPrice: 330,
    // },
    // {
    //   imgPath :"./../../../../assets/logo/logo.svg",
    //   brandName: "Proton8",
    //   productName: "Product Name8",
    //   description: "Lorem ipsum long description8",
    //   offerPrice: 190,
    //   marketPrice: 200
    // }
  ];

  constructor(public store: StoreService, private router: Router,
    
    
    private auth:AuthService
    ) {}
  hotSellingProducts: any = [];
  ngOnInit() {
    this.getProducts();
  }
  next(object, slideView) {
    this.slideWithNav2.slideNext();
  }

  prev(object, slideView) {
    this.slideWithNav2.slidePrev();
  }

  getProducts() {
    let data = {
      categoryId: '',
      filter: 'NewArrival',
      pageNo: '0',
      pageSize: 6,
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'Desc',
    };
    this.store.searchProductByCategoryId(data).subscribe((res: any) => {
      this.newListings = res.products;
    });
  }
  gotoViewProduct(item): void {
    const queryParams: any = {};
    const arrayOfValues = item;

    queryParams.myArray = JSON.stringify(arrayOfValues);
    const navigationExtras: NavigationExtras = {
      queryParams,
    };
    this.router.navigate(['/list-detail'], navigationExtras);
  }
}
