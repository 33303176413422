import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;

  isActiveToggleTextPassword: Boolean = true;
  isActiveToggleTextPassword2: Boolean = true;
  // newPassword: any;
  // confirmNewPassword: any;

  error_messages = {
    newPassword: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length is minimum 8 characters' },
      { type: 'maxlength', message: 'Password length maxed out' },
      { type: 'pattern', message: 'Enter strong password (at least one letter and one number)' },
    ],
    confirmNewPassword: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length is minimum 8 characters' },
      { type: 'maxlength', message: 'Password length maxed out' },
      { type: 'pattern', message: 'Enter strong password (at least one letter and one number)' },
    ],
  };
  constructor(
    public formBuilder: FormBuilder,
    private toaster: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.loginForm = this.formBuilder.group(
      {
        newPassword: new FormControl(
          '',
          Validators.compose([
            Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&/,>.=+-{} ~|\\^():¦;÷`¿¡/\\|{}])[A-Za-z\\d@$!%*#?&/,>.=+-{} ~|\\^():¦;÷`¿¡/\\|{}]{8,}$'),
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
          ])
        ),
        confirmNewPassword: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
          ])
        ),
      },
      {
        validators: this.password.bind(this),
      }
    );
    this.otpForm = this.formBuilder.group({
      otp: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
        ])
      ),
    });
  }

  ngOnInit() { }

  onLoad() {

  }

  public toggleTextPassword(): void {
    this.isActiveToggleTextPassword =
      this.isActiveToggleTextPassword == true ? false : true;
  }

  public getType() {
    return this.isActiveToggleTextPassword ? 'password' : 'text';
  }

  public getName() {
    return this.isActiveToggleTextPassword ? 'eye-off-outline' : 'eye-outline';
  }

  public toggleTextPassword2(): void {
    this.isActiveToggleTextPassword2 =
      this.isActiveToggleTextPassword2 == true ? false : true;
  }
  public getType2() {
    return this.isActiveToggleTextPassword2 ? 'password' : 'text';
  }
  public getName2() {
    return this.isActiveToggleTextPassword2 ? 'eye-off-outline' : 'eye-outline';
  }

  password(formGroup: FormGroup) {
    const { value: newPassword } = formGroup.get('newPassword');
    const { value: confirmNewPassword } = formGroup.get('confirmNewPassword');
    return newPassword === confirmNewPassword ? null : { passwordMatch: false };
  }
  showPasswordResetForm: boolean = false;
  
  otp: any;
  saveNewPassword() {
    if (!this.loginForm.value) {
      this.toaster.warning('Invalid details!');
      return;
    }
    const reqData = {
      mobile: this.auth.currentUser$.emailAddr,
    };

    this.auth.sendOtp(reqData).subscribe(
      (res: any) => {
        if (!res) {
          this.toaster.warning('Something went wrong, Please try again!');
      
        } else {
          this.showPasswordResetForm = true;
          this.toaster.success('OTP sent to registered email address.');
          return;
        }
      },
      (err: any) => {
        this.spinner.hide();
        if (err.loginStatus) {
          this.showPasswordResetForm = true;
          this.toaster.success(err.error.message);
        } else {
          this.showPasswordResetForm = false;
          this.toaster.warning(err.error.message);
        }
      }
    );
  }
  submitOtp() {
    let data = {
      mobile: this.auth.currentUser$.emailAddr,
      newPassword: this.loginForm.value.confirmNewPassword,
      otp: this.otpForm.value.otp,
    };
    this.spinner.show();
    this.auth.verifyOtp(data).subscribe(
      (res: any) => {
        if (res.loginStatus) {
          localStorage.setItem('loginToken', res.loginToken);

          this.spinner.hide();

          let reqData = {
            phoneNumber: data.mobile,
            password: data.newPassword,
            token: res.loginToken,
          };
          this.auth.passwordReset(reqData).subscribe((res) => {
            if (res.loginStatus) {
              localStorage.setItem('currentUserProfile', JSON.stringify(res));
              localStorage.setItem('usernameOrEmail', res.username);
              localStorage.setItem('accessToken', res.accessToken);
              this.auth.getCurrentUser();
              // window.location.reload();
              this.spinner.hide();
              this.toaster.success('Password changed successfully');
              this.showPasswordResetForm = false;
              this.loginForm.reset();
            } else {
              this.toaster.warning('Something went wrong, Try again later.');
            }
          });
        } else {
          this.toaster.warning(res);
        }
      },
      (err: any) => {
        this.spinner.hide();
        if (err.error.text) {
          this.toaster.warning(err.error.text);
        } else {
          this.toaster.warning('Something went wrong, Try again later.');
        }
        // this.router.navigate([
        //   '/auth/new-password',
        //   this.mobileNum,
        //   err.error.text,
        // ]);
      }
    );
  }
}
