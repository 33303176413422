import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-home-banner-buy',
  templateUrl: './home-banner-buy.component.html',
  styleUrls: ['./home-banner-buy.component.scss'],
})
export class HomeBannerBuyComponent implements OnInit {
  constructor(public store: StoreService) {}
  @Input('bannerInfo') banner: any;
  @Input('tabValue') tabValue: any;
  ngOnInit() {}
}
