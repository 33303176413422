import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { NgZone } from '@angular/core';
import swal from 'sweetalert2';
import * as _ from 'underscore';

declare var Stripe;
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  displayedColumns: string[] = ['email', 'name', 'date', 'message', 'remove'];
  dataSource;
  selectedValue = 'Inbox';
  isOpenMail: boolean = false;
  doRefresh(event) {
    this.onLoad();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  isMainTab = false;
  constructor(
    public commonService: CommonService,

    public activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private zone: NgZone
  ) {
    // this.onLoad();
    this.selectedValue = 'Inbox';

  }

  onLoad() {
    this.getInboxMessages();
    this.getSentMessages();
    this.getReadMessages();
  }
  ionViewWillEnter() {

    this.onLoad();
  }
  ngOnInit() {
    // this.onLoad();
  }
  // jbafds
  showMore(index) {
    if (this.dataSource[index].slice == -100) {
      this.dataSource[index].slice = 39;
    } else {
      this.dataSource[index].slice = -100;
    }
  }
  showMoreInbox(index) {
    if (this.inboxMessages[index].slice == 100) {
      this.inboxMessages[index].slice = 12;
    } else {
      this.inboxMessages[index].slice = 100;
    }
  }
  showMoreRead(index) {
    if (this.readMessages[index].slice == 100) {
      this.readMessages[index].slice = 12;
    } else {
      this.readMessages[index].slice = 100;
    }
  }
  showMoreSent(index) {
    if (this.sentMessages[index].slice == 100) {
      this.sentMessages[index].slice = 12;
    } else {
      this.sentMessages[index].slice = 100;
    }
  }

  removeMessage(messageKey) {
    swal
      .fire({
        title: 'REMOVE MESSAGE',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          this.spinner.show();
          this.store.removeMessage(messageKey).subscribe((res: any) => {
            this.spinner.hide();

            if (res) {
              this.onLoad();
              this.toaster.success('Message removed!');

              return;
            } else {
              this.toaster.error('Message remove failed!');
            }
          });
          return;
        }
      });
  }

  inboxMessages: any = [];
  getAllMessages() {
    this.inboxMessages = [];
    this.readMessages = [];
    this.sentMessages = [];

    let data = {
      listLabels: ['Inbox', 'Read', 'Sent'],
      pageNo: 0,
      pageSize: 8,
      sortColumnName: 'lastUpdated',
      sortOrder: 'DESCENDING',
    };
    this.spinner.show();

    this.store.getMessages(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.Status === 'Success') {
        let inboxMessages = res.Inbox;
        let readMessages = res.Read;
        let sentMessages = res.Sent;

        if (inboxMessages.messages.content.length) {
          this.store.totalMessages$ = inboxMessages.messages.content.length;
          this.inboxMessages = [];
          inboxMessages.messages.content.forEach((element) => {
            
            let dataSource = {
              email: element.sender.email,
              type: 'inbox',
              name:
                element.sender.userProfile.userFirstname +
                ' ' +
                element.sender.userProfile.userLastname,
              image:
                element.sender.userProfile.profilePic +
                ' ' +
                element.sender.userProfile.profilePic,

              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };

            this.inboxMessages.push(dataSource);
          });
        } else {
          this.store.totalMessages$ = 0;
        }

        if (readMessages.messages.content.length) {
          this.readMessages = [];
          readMessages.messages.content.forEach((element) => {
            
            let dataSource = {
              email: element.sender.email,
              type: 'read',
              name:
                element.sender.userProfile.userFirstname +
                ' ' +
                element.sender.userProfile.userLastname,
              image:
                element.sender.userProfile.profilePic +
                ' ' +
                element.sender.userProfile.profilePic,

              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };

            this.readMessages.push(dataSource);
          });
        }

        if (sentMessages.messages.content.length) {
          this.sentMessages = [];
          sentMessages.messages.content.forEach((element) => {
            
            let dataSource = {
              email: element.recipient.email,
              type: 'sent',
              name:
                element.recipient.userProfile.userFirstname +
                ' ' +
                element.recipient.userProfile.userLastname,
              image:
                element.recipient.userProfile.profilePic +
                ' ' +
                element.recipient.userProfile.profilePic,
              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };

            this.sentMessages.push(dataSource);
          });
        }

        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
        this.store.totalMessages$ = 0;
      }
    });
  }

  getInboxMessages() {
    this.inboxMessages = [];
    let data = {
      listLabels: ['Inbox'],
      pageNo: this.inboxActivePage,
      pageSize: 8,
      sortColumnName: 'lastUpdated',
      sortOrder: 'DESCENDING',
    };
    this.spinner.show();

    this.store.getMessages(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        let inboxMessages = res.Inbox;
        if (inboxMessages.messages.content.length) {
          this.store.totalMessages$ = inboxMessages.messages.content.length;

          this.inboxMessages = [];
          inboxMessages.messages.content.forEach((element) => {
            
            let dataSource = {
              email: element.sender.email,
              type: 'inbox',
              name:
                element.sender.userProfile.userFirstname +
                ' ' +
                element.sender.userProfile.userLastname,
              image:
                element.sender.userProfile.profilePic +
                ' ' +
                element.sender.userProfile.profilePic,
              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };

            this.inboxMessages.push(dataSource);
            this.inboxGetPage(res);
          });
        } else {



        }

        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }
  readMessages: any = [];
  getReadMessages() {
    let data = {
      listLabels: ['Read'],
      pageNo: this.readActivePage,
      pageSize: 8,
      sortColumnName: 'lastUpdated',
      sortOrder: 'DESCENDING',
    };
    this.spinner.show();

    this.store.getMessages(data).subscribe((res: any) => {
      this.spinner.hide();

      if (res) {
        let readMessages = res.Read;

        if (readMessages.messages.content.length) {
          this.readMessages = [];

          readMessages.messages.content.forEach((element) => {
            
            this.dataSource = {
              email: element.sender.email,
              type: 'read',
              name:
                element.sender.userProfile.userFirstname +
                ' ' +
                element.sender.userProfile.userLastname,
              image:
                element.sender.userProfile.profilePic +
                ' ' +
                element.sender.userProfile.profilePic,

              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };

            this.readMessages.push(this.dataSource);
            this.readGetPage(res);
          });
        }

        return;
      } else {
      }
    });
  }

  sentMessages: any = [];
  getSentMessages() {
    this.sentMessages = [];
    let data = {
      listLabels: ['Sent'],
      pageNo: this.sentMessageActivePage,
      pageSize: 8,
      sortColumnName: 'lastUpdated',
      sortOrder: 'DESCENDING',
    };
    this.spinner.show();

    this.store.getMessages(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        let sentMessages = res.Sent;
        if (sentMessages.messages.content.length) {
          this.sentMessages = [];
          sentMessages.messages.content.forEach((element) => {
            
            let dataSource = {
              email: element.recipient.email,
              type: 'sent',
              name:
                element.recipient.userProfile.userFirstname +
                ' ' +
                element.recipient.userProfile.userLastname,
              image:
                element.recipient.userProfile.profilePic +
                ' ' +
                element.recipient.userProfile.profilePic,
              date: element.createdAt,
              message: element.message,
              slice: 12,
              moreOption: '',

              messageKey: element.messageKey,
            };
            this.sentMessages.push(dataSource);
            this.sentMessageGetPage(res);
          });
        }
        return;
      } else {
      }
    });
  }
  selectReportType(event) {
    this.isOpenMail = false;
    this.selectedValue = event.detail.value;
    this.onLoad();
  }

  userbasedGroupedMessages: any = [];
  lastOpenedMessages: any = [];
  lastOpenedMessageKey: any;
  lastOpenedMessageRecipientUsername: any;
  lastOpenedMessageContent: any;
  openMailBox(
    messageKey,
    lastOpenedMessageRecipientUsername,
    lastOpenedMessageContent,
    type,
    messages
  ) {
    this.isOpenMail == true
      ? (this.isOpenMail = false)
      : (this.isOpenMail = true);

    if (this.isOpenMail && type === 'Inbox') {
      this.spinner.show();
      this.store.messageRead(messageKey).subscribe((res: any) => {
        this.spinner.hide();

        if (res) {
          this.toaster.success('Message read!');
          this.onLoad();

          //nandan
          // this.selectedValue = 'Read';
          return;
        } else {
          this.toaster.error('Message read failed!');
        }
      }),
        (err: any) => {
          this.spinner.hide();
          if (err.error.text) {
            this.toaster.error(err.error.text);
          } else {
            this.toaster.error('Something went wrong, Try again later.');
          }

          // this.router.navigate(['signin']);
        };
    }


    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.lastOpenedMessageKey = messageKey;
      this.lastOpenedMessageRecipientUsername =
        lastOpenedMessageRecipientUsername;
      this.lastOpenedMessageContent = lastOpenedMessageContent;

      this.userbasedGroupedMessages = [];

      if (this.inboxMessages.length) {
        this.inboxMessages.forEach((element) => {
          
          if (element.email === this.lastOpenedMessageContent.email) {
            this.userbasedGroupedMessages.push(element);
          }
        });
      }

      if (this.readMessages.length) {
        this.readMessages.forEach((element) => {
          
          if (element.email === this.lastOpenedMessageContent.email) {
            this.userbasedGroupedMessages.push(element);
          }
        });
      }

      if (this.sentMessages.length) {
        this.sentMessages.forEach((element) => {
          
          if (element.email === this.lastOpenedMessageContent.email) {
            this.userbasedGroupedMessages.push(element);
          }
        });
      }

      this.userbasedGroupedMessages = this.userbasedGroupedMessages.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      this.lastOpenedMessages = this.userbasedGroupedMessages;
    }, 2000);

  }

  closeMailBox() {
    this.isOpenMail = false;
    this.onLoad();
  }
  // Sent pagination
  sentMessageActivePage = 0;
  sentMessageTotal_count: any;
  sentMessagePager: any = {};
  sentMessageGetPage(res) {
    this.sentMessagePagination(this.sentMessageActivePage);
    this.sentMessageTotal_count = res.Sent.messages.totalPages;
    this.sentMessagePager = this.sentMessageGetPager(
      this.sentMessageTotal_count,
      this.sentMessageActivePage
    );
  }
  sentMessageSetPage(page: number) {
    this.sentMessageActivePage = page;
    if (page < 0 || page > this.sentMessagePager.totalPages) {
      return;
    }
    this.sentMessagePager = this.sentMessageGetPager(
      this.sentMessageTotal_count,
      page
    );
    this.getSentMessages();
  }
  sentMessagePagination(page: number) {
    return page.toString();
  }
  sentMessageGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= this.sentMessageTotal_count) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  // Inbox pagination
  inboxActivePage = 0;
  inboxTotal_count: any;
  inboxPager: any = {};
  inboxGetPage(res) {
    this.inboxPagination(this.inboxActivePage);
    this.inboxTotal_count = res.Inbox.messages.totalPages;
    this.inboxPager = this.inboxGetPager(
      this.inboxTotal_count,
      this.inboxActivePage
    );
  }
  inboxSetPage(page: number) {
    this.inboxActivePage = page;
    if (page < 0 || page > this.inboxPager.totalPages) {
      return;
    }
    this.inboxPager = this.inboxGetPager(this.inboxTotal_count, page);
    this.getInboxMessages();
  }
  inboxPagination(page: number) {
    return page.toString();
  }
  inboxGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= this.inboxTotal_count) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  // Read pagination
  readActivePage = 0;
  readTotal_count: any;
  readPager: any = {};
  readGetPage(res) {
    this.readPagination(this.readActivePage);
    this.readTotal_count = res.Read.messages.totalPages;
    this.readPager = this.readGetPager(
      this.readTotal_count,
      this.readActivePage
    );
  }
  readSetPage(page: number) {
    this.readActivePage = page;
    if (page < 0 || page > this.readPager.totalPages) {
      return;
    }
    this.readPager = this.readGetPager(this.readTotal_count, page);
    this.getReadMessages();
  }
  readPagination(page: number) {
    return page.toString();
  }
  readGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= this.readTotal_count) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
