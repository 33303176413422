import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import * as _ from 'underscore';
@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit {
  selectedIndex = 0;
  constructor(
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private _formBuilder: FormBuilder,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.onLoad();
    // this.items = Array(10).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}`}));

  }
  ionViewWillEnter() {
    this.onLoad();
  }

  onTabChanged(event) {
    this.selectedIndex = event.index;
  }

  items = [];
  pageOfItems: any;

  onChangePage(pageOfItems: any) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit() {
    // this.onLoad();
    // an example array of 150 items to be paged

    // console.table(this.items)
  }

  onLoad() {
    this.model = {};
    this.model.sortBy = 'DESCENDING';
  }

  model: any = {};
  requests: [];
  public submitForm(form: any) {
    // this.toaster.info("Coming Soon!");
    // return;

    //this.presentToast(form.value);
    if (!form.value) {
      this.toaster.warning('Invalid details!');
      return;
    }

    // if (!this.model.categoryId) {
    //   this.toaster.warning('Choose category!');
    //   return;
    // }
    const data = {
      categoryId: this.model.categoryId,
      date: moment(form.value.date, 'yyyy-MM-DD').unix(),
      partId: form.value.partId,
      vin: form.value.vin,
      sortBy: form.value.sortBy,
      tags: form.value.tags,
      pageSize: this.pageSize,
      pageNo: this.activePage,
      year: moment(form.value.date, 'yyyy-MM-DD').year()
    };

    this.spinner.show();

    this.store.browseSalvageRequest(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        //this.presentToast(res);
        if (res) {
          this.requests = res;

          // if(res['content'].content.length){
          //   for (let i = 0; i < res['content'].content.length; i++) {
          //     this.items.push({ id: (i + 1), content: `Item ${i + 1}` });
          //   }
          // }


          this.getPage(res);
          // this.toaster.success('Results found...');
        } else {
          this.toaster.warning('Something went wrong!');
        }
      },
      (err: any) => {
        this.spinner.hide();
        //this.presentToast(err);
        if (err.error.text) {
          this.toaster.warning(err.error.text);
        } else {
          this.toaster.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }


  activePage = 0;
  total_count: any;
  pager: any = {};
  total_pages = 0;
  pageSize = 4;
  pageFix(i, total, current) {
    if (i === current) {
      return true;
    }

    if (i < 4) {
      if (i === current) {
        return false;
      }

      return true;
    }

    if (i >= total - 4 && i < total) {
      if (i === current) {
        return false;
      }
      return true;
    }
    return false;
  }

  getPage(res) {
    this.pagination(this.activePage);
    this.total_count = res.content.totalItems;
    this.total_pages = res.content.totalPages;
    this.pager = this.getPager(this.total_count, this.activePage, this.pageSize);
  }
  setPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.pager.totalPages - 1) {
      this.toaster.info('Reached end of pages!');
      return;
    }
    this.pager = this.getPager(this.total_count, page, this.pageSize);
    const data = {
      categoryId: this.model.categoryId,
      date: moment(this.model.date, 'yyyy-MM-DD').unix(),
      partId: this.model.partId,
      vin: this.model.vin,
      sortBy: this.model.sortBy,
      tags: this.model.tags,
      pageSize: this.pageSize,
      pageNo: this.activePage,
      year: moment(this.model.date, 'yyyy-MM-DD').year()
    };

    this.spinner.show();

    this.store.browseSalvageRequest(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        //this.presentToast(res);
        if (res) {
          this.requests = res;
          // this.toaster.success('Results found...');
        } else {
          this.toaster.warning('Something went wrong!');
        }
      },
      (err: any) => {
        this.spinner.hide();
        //this.presentToast(err);
        if (err.error.text) {
          this.toaster.warning(err.error.text);
        } else {
          this.toaster.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }
  pagination(page: number) {
    return page.toString();
  }


  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {

        if ((totalPages - (currentPage - 2)) == 5) {
          startPage = currentPage - 1;
          endPage = currentPage + 3;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage - 1, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  clearSearchForm(form: any) {
    form.reset();

    setTimeout(() => {
      this.onLoad();
      this.toaster.info('Form cleared!');
    }, 500);
  }


  resetEverything() {
    this.requests = null;
    this.activePage = 0;
    this.total_count = 0;
    this.pager = {};
    this.total_pages = 0;
  }

}