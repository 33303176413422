import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  val: any = { lower: 0, upper: 1000 };
  env = "web";


  @ViewChild('target') MyProp: ElementRef;
  @Output() getPage = new EventEmitter<any>();

  @Output() scroll = new EventEmitter<any>();


  @ViewChild(IonContent) content: IonContent;
  @Input() requestJson;
  @Input() total_count;
  @Input() total_pages;
  @Input() activePage;

  constructor(
    public modalController: ModalController,
    public store: StoreService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    route: ActivatedRoute,

    private auth: AuthService
  ) {
    // this.clearModalFilter();
    this.env = localStorage.getItem("environment");
    this.modal.min = this.store.min;
    this.modal.max = this.store.max;
    this.val = {
      lower: this.modal.min,
      upper: this.modal.max,
    };
    route.params.subscribe(val => {

      // this.clearModalFilter();
    });
  }
  categoryId: any;
  subCategoryId: any;
  min: any = 0;
  max: any = 25000;
  ngOnInit() {
    this.modal.min = this.store.min;
    this.modal.max = this.store.max;
    this.val = {
      lower: this.modal.min,
      upper: this.modal.max,
    };
    this.activatedRoute.params.subscribe((res) => {
      this.categoryId = res.categoryId;
      this.subCategoryId = res.subCategoryId;
    });
    // if (this.categoryId === 'ALL') {
    //   this.getAllProducts();
    // } else {
    //   this.getProducts(this.categoryId);
    // }
  }
  rangeChange(event) {
    this.modal.min = parseInt(event.target.value.lower);
    this.modal.max = parseInt(event.target.value.upper);
    this.val = {
      lower: this.modal.min,
      upper: this.modal.max,
    };

  }
  modal: any = {
    min: this.store.min,
    max: this.store.max,
  };
  changeRangeMinVal(event) {
    this.val = {
      lower: parseInt(event.target.value) ? parseInt(event.target.value) : 0,
      upper: this.modal.max,
    };
  }
  changeRangeMaxVal(event) {
    this.val = {
      lower: this.modal.min,
      upper: parseInt(event.target.value) ? parseInt(event.target.value) : 0,
    };
  }
  onSearchRange() {
    if (!this.modal.max && this.modal.max === 0) {
      this.toaster.error('Enter maximum price');
      return;
    } else {
      this.total_count = 0;
      this.total_pages = 0;
      this.activePage = 0;

      try {
        this.requestJson.categoryId = this.requestJson.categoryId ? this.requestJson.categoryId : '';
        this.requestJson.filter = this.requestJson.filter ? this.requestJson.filter : 'TextSearch';
        this.requestJson.priceFrom = parseInt(this.modal.min);
        this.requestJson.priceTo = parseInt(this.modal.max);
        this.requestJson.subCategoryId = '';
        this.store.min=this.modal.min;
        this.store.max=this.modal.max;
      } catch (error) {
        //do nothing
      }



      // let data = {
      //   categoryId: '',
      //   filter: 'TextSearch',
      //   priceFrom: parseInt(this.modal.min),
      //   priceTo: parseInt(this.modal.max),
      //   subCategoryId: '',
      //   pageNo: '0',
      //   pageSize: 9,
      //   searchString: '',
      //   sortOption: 'productId',
      //   sortOrder: 'Desc',
      // };

      // this.store.getProduuctsByCategoryId(this.requestJson);




      this.store.searchProductByCategoryId(this.requestJson).subscribe((res) => {
        if (res) {
          try {
            this.store.filteredCategories$ = res;
            this.store.filteredProducts$ = this.store.filteredCategories$.products;
            this.categoryId = this.router.url.split('/')[2];
            this.subCategoryId = this.router.url.split('/')[3];
            if (this.categoryId === 'ALL') {
              this.store.searchCategoryName$ = 'ALL';
              this.requestJson.brandIds = this.store.brandIds$;
            } else {
              if (this.store.filteredCategories$) {

                if (this.store.filteredCategories$.products[0].categoryName) {
                  this.store.searchCategoryName$ =
                    this.store.filteredCategories$.products[0].categoryName;

                }
              }

            }
            if (this.subCategoryId) {
              if (this.store.filteredCategories$) {
                if (this.store.filteredCategories$.products[0].subCategoryname) {
                  this.store.searchCategoryName$ =
                    this.store.filteredCategories$.products[0].subCategoryname;
                }
              }
            }
          } catch (error) {
            //do nothing
          }


          // this.parseURLData();
          this.getPage.emit(res);
          this.scroll.emit();

        } else {
          console.error(res);
        }
      });
      this.close();
    }
  }


  inBrandArray(brandId) {
    if (this.store.brandIds$.includes(brandId)) {
      return true;
    } else {
      return false;
    }
  }
  clearModalFilter() {

    this.store.min=0;
    this.store.max=25000;
    this.min = this.store.min;
    this.max = this.store.max;
    this.modal.min = parseInt(this.min);
    this.modal.max = parseInt(this.max);
    this.val = {
      lower: this.modal.min,
      upper: this.modal.max,
    };
    if (this.requestJson) {
      if (this.requestJson.hasOwnProperty('priceFrom') || this.requestJson.hasOwnProperty('priceTo')) {
        this.requestJson.priceFrom = parseInt(this.modal.min);
        this.requestJson.priceTo = parseInt(this.modal.max);

      }
    }


    // this.store.brandIds$ = [];
  }
  action(item, isAll = false) {

    if (isAll) {
      this.store.brandIds$ = [];
    }
    this.store.searchTerm$ = "";

    if (!this.store.brandIds$.includes(item.brandId)) {
      this.store.brandIds$.push(item.brandId);
    } else {
      this.store.brandIds$.splice(this.store.brandIds$.indexOf(item.brandId), 1);
    }

    // alert(this.requestJson.categoryId)
    if (this.requestJson.categoryId && this.requestJson.categoryId !== 'All') {
      this.router.navigate(['/browse-listing/manufacturer/' + this.requestJson.categoryId + "/" + "M-" + Math.floor(Math.random() * 1000000000)]);
    } else {
      this.router.navigate(['/browse-listing/manufacturer/' + "M-" + Math.floor(Math.random() * 1000000000)]);
    }
    this.close();
  }


  getProducts(categoryId) {
    // let data = {
    //   categoryId: categoryId,
    //   filter: 'HotSelling',
    //   subCategoryId: this.subCategoryId,
    //   pageNo: '0',
    //   pageSize: 9,
    //   searchString: '',
    //   sortOption: 'productId',
    //   sortOrder: 'Desc',
    //   sendAllProducts: false,
    // };
    this.store.searchTerm$ = "";
    let data: any = {
      categoryId: categoryId,
      filter: 'ProductsByCategory',
      subCategoryId: '',
      brandIds: [0],
      pageNo: '0',
      pageSize: 9,
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'Desc',
      sendAllProducts: false,
    };
    data.subCategoryId = this.subCategoryId !== '' ? this.subCategoryId : '';
    if (this.categoryId === 'manufacturer') {
      data.sendAllProducts = true;
      data.filter = 'Manufacturer';
      data.categoryId = '';
      data.subCategoryId = '';
      data.searchString = this.subCategoryId;
    }
    this.store.getProduuctsByCategoryId(data);
    this.close();
  }
  getAllProducts() {
    return;
    this.store.searchTerm$ = "";
    let data = {
      categoryId: '',
      filter: 'Manufacturer',
      subCategoryId: '',
      pageNo: '0',
      pageSize: 9,
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'Desc',
    };
    this.store.brandIds$ = [];
    this.store.manufacturers$.forEach(element => {
      this.store.brandIds$.push(element.brandId);
    });
    this.requestJson.brandIds = this.store.brandIds$;
    this.requestJson.brandName = [''];
    this.store.getProduuctsByCategoryId(data);
    this.close();
  }
  close() {
    try {
      this.modalController.dismiss({
        dismissed: true,
      });

    } catch (error) {

    }





  }



}
