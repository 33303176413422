import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-work',
  templateUrl: './how-it-work.component.html',
  styleUrls: ['./how-it-work.component.scss'],
})
export class HowItWorkComponent implements OnInit {

  ACTIVE_TAB: string = "buyers";

  constructor() { }

  ngOnInit() { }

  activeTab(ev) {
    this.ACTIVE_TAB = ev;

  }

}
