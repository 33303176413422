import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import swal from 'sweetalert2';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    private toaster: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    public store: StoreService,
    public router: Router,
    private spinner1: NgxSpinnerService,
    private toastr: ToastrService,
    public toastController: ToastController,
  ) {
   
  }

  ngOnInit() {
    this.onLoad();

  }
  ionViewWillEnter() {
    this.onLoad();
  }

  onLoad() {

    this.delete= {};
  }


  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  async warningToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-info-toast',
    });
    toast.present();
  }
  
  delete: any = {};
  public submitDeleteForm(form: any) {
    //this.presentToast(form.value);
    if (!form.value) {
      this.warningToast('Invalid details!');
      return;
    }


    swal
      .fire({
        title: 'DELETE ACCOUNT',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {

          const currentUserProfile = JSON.parse(
            localStorage.getItem('currentUserProfile')
          );
      
          const data = {
            fullname: currentUserProfile.userFirstName + " " + currentUserProfile.userLastName,
            userEmail: currentUserProfile.emailAddr,
            message: form.value.message
          };

          this.spinner.show();
          this.auth.saveContactUs(data).subscribe(
            (res: any) => {
              this.spinner.hide();
              if (res) {
                form.reset();
                this.toastr.success("Your account deletion request is submitted successfully! Please check your registered email address inbox for more information.");
              } else {
                this.toastr.warning('Invalid authentication!');
              }
            },
            (err: any) => {
              console.log(err)
              this.spinner.hide();
              form.reset();
              if (err?.error?.text) {
                this.warningToast(err.error.text);
                this.toastr.success("Your account deletion request is submitted successfully! Please check your registered email address inbox for more information.");
              } else {
                this.warningToast(err.error.message);
              }
            }
          );
          return;
        }
      });


  }
}
