import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

import { ProfileComponent } from 'src/app/components/settings/profile/profile.component';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})

export class SettingsComponent implements OnInit {
  selectedIndex: number = 0;

  @ViewChild(ProfileComponent) child: ProfileComponent;



  constructor(
    public commonService: CommonService,
    public router: Router
  ) {
    this.selectedIndex = 0;
  }


  page: any
  ionViewWillEnter() {
    this.selectedIndex = 0;
    if (this.router.url.split('/')[3]) {
      this.page = this.router.url.split('/')[3];
      if (this.page === 'password-reset') {
        this.selectedIndex = 2;
      } else {
        this.child.onLoad();
      }
    } else {
      this.child.onLoad();
    }
  }

  ngOnInit() {
    this.load();
  }
  load() {
    this.selectedIndex = 0;
    if (this.router.url.split('/')[3]) {
      this.page = this.router.url.split('/')[3];
      if (this.page === 'password-reset') {
        this.selectedIndex = 2;
      } 
    }
  }
  onTabChanged(event) {
    this.selectedIndex = event.index;
  }

}
