//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    public router: Router,
    public toastController: ToastController
  ) {}

  async warningToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-warning-toast',
    });
    toast.present();
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
     
        // return throwError(error);
        
        //error -null/undefined
        //ErrorEvent.status=0,400,404,500

        //redirect to error page

        //alert(JSON.stringify(error));
        if (error.error instanceof ErrorEvent) {
          // client-side error or network error
        } else {
          // TODO: Clean up following by introducing method
          if (error.status === 401) {
            // this.warningToast(
            //   error?.error
            //     ? JSON.stringify(error?.error)
            //     : 'SOMETHING WENT WRONG'
            // );

            this.warningToast('Session timeout, Login Again to continue!');
            this.auth.logout();
            return;
          }

          if (error.status === 400) {
            //alert("We found an issue in backend/session, relogin and surf!");
            // this.warningToast(
            //   error?.error
            //     ? JSON.stringify(error?.error)
            //     : 'SOMETHING WENT WRONG'
            // );
            // this.auth.logout();
            this.router.navigate(['not-found']);
            return;
          }

          if (error.status === 500) {
            //alert("We found an issue in backend/session, relogin and surf!");
            // this.warningToast(
            //   error?.error
            //     ? JSON.stringify(error?.error)
            //     : 'SOMETHING WENT WRONG'
            // );
            // this.auth.logout();
            this.router.navigate(['not-found']);
            return;
          }

          if (error.statusText === 'Unknown Error') {
            // alert("We found an issue in backend/session, relogin and surf!");
            // this.warningToast(
            //   error?.error
            //     ? JSON.stringify(error?.error)
            //     : 'SOMETHING WENT WRONG'
            // );
            // this.auth.logout();
            this.router.navigate(['not-found']);
            return;
          }
        }
        return throwError(error);
      })
    );
  }
}
