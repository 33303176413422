import {
  Component,
  OnInit,
  HostListener,
  isDevMode,
  ViewChild,
} from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { StoreService } from './services/store.service';
import { Location } from '@angular/common';
import { single } from './data';
import { IonContent } from '@ionic/angular';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonContent, undefined) content: IonContent;
  public appPages = [
    { title: 'Post a Request', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Browse Requests', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Browse Listings', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Login', url: '/auth', icon: 'person' },
    { title: 'Sign up', url: '/auth/sign-up', icon: 'person' },
  ];
  public labels = [
    'Hood & Front End',
    'Cab & Sleeper',
    'Engine',
    'Drivetrain',
    'Axle & Wheel End',
    'Chassis',
    'Steering & Suspension',
    'More',
  ];
  currentUser: any;

  // Charts
  single: any[];
  multi: any[];

  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };
  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  public isDesktop() {
    let platforms = this.platform.platforms();
    let isDesktop = true;
    if (this.platform.is('android')) {
      isDesktop = false;
    } else if (this.platform.is('ios')) {
      isDesktop = false;
    } else {
      isDesktop = true;
    }

    return isDesktop;
  }

  constructor(

    public router: Router,
    public menuCtrl: MenuController,
    public auth: AuthService,
    public store: StoreService,
    private platform: Platform,
    private _location: Location,

    private activatedRoute: ActivatedRoute,
    private statusBar: StatusBar,
    public alertController: AlertController
  ) {
    this.initializeApp();
    /* this.menuCtrl.swipeGesture(true, 'contentId') */
    // this.platform.backButton.subscribeWithPriority(0, () => {
    //   navigator['app'].exitApp();
    // });
    Object.assign(this, { single });
    if (!localStorage.getItem('environment')) {
      localStorage.setItem('environment', 'web');
    }






  }

  goTo(url) {
    this.router.navigate([url]);
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleBlackTranslucent();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByName("black");




      if (this.platform.is('cordova')) {
        // this.auth.user$.environment = "device";
        if (!this.isDesktop()) {
          localStorage.setItem('environment', 'device');
        } else {
          localStorage.setItem('environment', 'web');
        }
      } else {
        // this.auth.user$.environment = "web";
        localStorage.setItem('environment', 'web');
      }




    });

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this._location.isCurrentPathEqualTo('/home')) {
        // Show Exit Alert!
        let i = 1;
        if (i != 2) {
          i++;
          let count = i;
          if (count == 2) {
            navigator['app'].exitApp();
          }
        }
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        this._location.back();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      this.alertController
        .getTop()
        .then((r) => {
          if (r) {
            navigator['app'].exitApp();
          }
        })
        .catch((e) => {
        });
    });
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: 'App termination',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    let currentUserdata = JSON.parse(
      localStorage.getItem('currentUserProfile')
    );
    if (currentUserdata === null) {
      return;
    } else {
      this.auth.getCurrentUser();
    }
    this.menuCtrl.swipeGesture(true);
    const randomNumber: any = Math.floor(Math.random() * 1000000000);
    localStorage.setItem('deviceFingerPrint', randomNumber);
    // this.currentUser = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.store.viewLoginUser().subscribe((res) => {
    //   this.currentUser = res;
    // });
  }
  title = 'My first AGM project';
  lat = 51.678418;
  lng = 7.809007;

  menuClose() {
    this.menuCtrl.close();
  }

  openCloseMenu(category) {
    if (!category.isCollapsed) {
      category.isCollapsed = true;
    } else {
      category.isCollapsed = false;
    }
  }

  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    this.content.scrollToTop(400);
  }
}
