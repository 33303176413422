import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

import * as $ from 'jquery';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-track-table',
  templateUrl: './track-table.component.html',
  styleUrls: ['./track-table.component.scss'],
})
export class TrackTableComponent implements OnInit {
  val: any = { lower: 0, upper: 1000 };
  env = "web";


  @Input() res;
  @ViewChild('target') MyProp: ElementRef;
  @Output() getPage = new EventEmitter<any>();

  @Output() scroll = new EventEmitter<any>();


  @ViewChild(IonContent) content: IonContent;
  @Input() requestJson;
  @Input() total_count;
  @Input() total_pages;
  @Input() activePage;
  options: InAppBrowserOptions = {
    location: 'yes',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes',//Android only ,shows browser zoom controls 
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
    hideurlbar: 'yes', // hide the url toolbar
  };
  constructor(
    public modalController: ModalController,
    public store: StoreService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    route: ActivatedRoute,

    private theInAppBrowser: InAppBrowser,
    private auth: AuthService
  ) {
    this.env = localStorage.getItem("environment");
    route.params.subscribe(val => {
    });
  }
  ngOnInit() {
  }
  orderStatus=null;
  buyOrdersActivePage=0;
  public openWithInAppBrowser(url: string) {
    let target = "_blank"; let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }
  public openWithSystemBrowser(url: string) {
    let target = "_system";
    let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }
  trackBidRequest(key) {

    $(".track-" + key).hide();
    $(".track-spinner-" + key).show();

    let data = {
      type: 'Buy',
      status: this.orderStatus !== 'ALL' ? this.orderStatus : 'RequestOpen',
      pageNo: this.buyOrdersActivePage,
      primaryStatus: 'RequestOpen',
    };
    this.store.getBidRequests(data).subscribe((res: any) => {
      $(".track-" + key).show();
      $(".track-spinner-" + key).hide();


       if (res) {
          if (localStorage.getItem('environment') !== 'web') {
            this.openWithSystemBrowser('https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx');
          } else {
            window.open('https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx', '_blank');
          }
          // this.toaster.warning('COMING SOON!');
          return;
        } else {
        }
   



    });



  }
  close() {
    try {
      this.modalController.dismiss({
        dismissed: true,
      });

    } catch (error) {

    }

  }



}
