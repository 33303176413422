import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-request-results',
  templateUrl: './request-results.component.html',
  styleUrls: ['./request-results.component.scss'],
})
export class RequestResultsComponent implements OnInit {

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() {}

}
