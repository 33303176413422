import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CountriesAndStatesService } from 'src/app/services/countries-and-states.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  constructor(
    private store: StoreService,
    public auth: AuthService,
    private toaster: ToastrService,
    private countriesServices: CountriesAndStatesService,
    private spinner: NgxSpinnerService,
  ) {

    // this.onLoad(); 


  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ionViewWillEnter() {
    this.onLoad();
  }

  addressList = {
    firstName: '',
  };

  statesList = this.auth.statesList;
  cities = this.auth.cities;
  countriesList = this.auth.countriesList;

  selectCountryValue: any;
  selectStateValue: any;
  selectCityValue: any;
  userAddressesList: any = [];
  address: any = {};
  ngOnInit() {
    // this.onLoad();
  }


  onLoad() {
    this.address.addressType = 'SHIPPING';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (localStorage.getItem('accessToken') === null) {
      return;
    } else {
      this.getUserAddressesList();
    }

  }
  contactInformationForm(form: any) {
    let data: any = {
      addLine1: form.value.address1,
      addLine2: form.value.address2,
      addState: this.selectStateValue,
      addressType: this.address.addressType,
      city: form.value.city,
      country: this.selectCountryValue,
      customerFullName: form.value.firstName + ' ' + form.value.lastName,
      zipCode: form.value.zipCode,
    };

    this.store.addCustomerAddress(data).subscribe((res) => {
      if (res) {
        this.toaster.success('Address added!');
        this.getUserAddressesList();
        form.reset();
      } else {
        this.toaster.warning('Something went wrong');
      }
      return this.userAddressesList;
    });
  }
  getUserAddressesList() {

    this.spinner.show();
    this.store.getUserAddressList().subscribe((res) => {
      this.spinner.hide();
      this.userAddressesList = res;
    });
  }
  deleteAddress(addressId) {
    let data = {
      addressId: addressId,
    };
    this.store.deleteUserAddress(data).subscribe((res: any) => {
      if (res === true) {
        this.toaster.success('Address deleted!');
        this.getUserAddressesList();
      } else {
        this.toaster.warning('Something went wrong');
        this.getUserAddressesList();
      }
    });
  }
  countryName: any;
  cityName: any;
  doRefresh(event) {
    this.getUserAddressesList();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }
}
