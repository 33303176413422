import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { NgZone } from '@angular/core';
import swal from 'sweetalert2';
import * as _ from 'underscore';
declare var Stripe;
import * as $ from 'jquery';

import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { TrackTableComponent } from '../../track-table/track-table.component';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  stripe = null;
  card: any;

  cardNumber: string;
  cardMonth: number;
  cardYear: number;
  cardCVV: string;
  p: any;

  ORDER_ITEM = 'SOLD';
  closeItemIsOpen: boolean = true;
  openItemIsOpen: boolean = true;
  pendingItemIsOpen: boolean = true;
  completedItemIsOpen: boolean = true;
  cartSellCompletedItemIsOpen: boolean = true;

  showOrderDetails = false;

  mergedCompletedOrders = [];
  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,

    public activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private zone: NgZone,
    private theInAppBrowser: InAppBrowser,

    public modalController: ModalController
  ) {
    // this.onLoad();
  }
  options: InAppBrowserOptions = {
    location: 'yes',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes',//Android only ,shows browser zoom controls 
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
    hideurlbar: 'yes', // hide the url toolbar
  };
  onLoad() {
    this.mergedCompletedOrders = [];
    this.getStoreKey();
    this.getBuyBidRequests();
    this.getSellBidRequests();
    this.getCartBuyOrders();

    setTimeout(() => {
      this.getCartSellOrders();

    }, 3000);


  }

  doRefresh(event) {
    this.onLoad();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }
  tab: AudioNode;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      this.tab = res.tab;
      if (this.tab.toString() === 'buy') {
        this.ORDER_ITEM = 'PURCHASED';
      } else {
        this.ORDER_ITEM = 'SOLD';
      }
    });

    // this.onLoad();
    this.getBidRequestStatusEnum();
  }
  ionViewWillEnter() {
    this.onLoad();
  }
  merchantStoreData: any = [];
  getStoreKey() {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }

    this.store.getStoreKey().subscribe((res: any) => {
      if (res) {

        this.merchantStoreData = res;
        //this.successToast('STORE KEY  FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }
  segmentChanged(ev) {
    this.ORDER_ITEM = ev.detail.value;
    this.onLoad();
  }
  public openWithInAppBrowser(url: string) {
    let target = "_blank"; let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }
  public openWithSystemBrowser(url: string) {
    let target = "_system";
    let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }

  trackBidRequest(key) {
console.log(key);
    $(".track-" + key).hide();
    $(".track-spinner-" + key).show();


    this.store.trackOrder(key).subscribe((res: any) => {
      console.log(res);
      $(".track-" + key).show();
      $(".track-spinner-" + key).hide();


      if (!res || !res.length) {
        this.toaster.warning('Tracking option not available for this request.');
      } else {
        if (res.length === 1) {

          if (res[0].trackingUrl) {
            if (localStorage.getItem('environment') !== 'web') {
              this.openWithSystemBrowser(res[0].trackingUrl);
            } else {
              window.open(res[0].trackingUrl, '_blank');
            }

          } else {
            this.toaster.warning('Tracking URL not available for this request.');
          }


        } else {
          this.presentModal(res);
        }
      }
/*
      if (Math.floor(Math.random() * 10) % 2 === 0) {
        if (res) {
          if (localStorage.getItem('environment') !== 'web') {
            this.openWithSystemBrowser('https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx');
          } else {
            window.open('https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx', '_blank');
          }
          // this.toaster.warning('COMING SOON!');
          return;
        } else {
        }
      } else {
        this.presentModal();
      }

*/

    });



  }

  async presentModal(res) {
    const modal = await this.modalController.create({
      component: TrackTableComponent,
      cssClass: 'my-filter-class',
      componentProps: { res:res }
    });
    return await modal.present();
  }
  openCloseOpenItem() { }
  report() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: { title: 'Cancel purchase' },
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }
  cancelBidRequest(key) {
    swal
      .fire({
        title: 'CANCEL BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: key,
          };
          this.store.cancelBidRequest(data).subscribe((res: any) => {
            if (res) {
              //this.getBuyBidRequests();
              //this.getSellBidRequests();
              this.onLoad();
              this.successToast('CANCEL BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }

  rejctBidRequest(key) {
    swal
      .fire({
        title: 'REJECT BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: key,
          };
          this.store.rejectBidRequest(data).subscribe((res: any) => {
            if (res) {
              // this.getBuyBidRequests();
              // this.getSellBidRequests();
              this.onLoad();
              this.successToast('REJECT BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }

  acceptBidRequest(key) {
    swal
      .fire({
        title: 'ACCEPT BID',
        position: 'top',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })

      .then((result) => {
        if (result.value) {
          let data = {
            bidKey: key,
          };
          this.store.acceptBidRequest(data).subscribe((res: any) => {
            if (res) {
              // this.getBuyBidRequests();
              // this.getSellBidRequests();
              this.onLoad();
              this.successToast('ACCEPT BID SUCCESS!');
              return;
            } else {
            }
          });
          return;
        }
      });
  }

  changeFilter(status) {
    this.orderStatus = status;
    this.onLoad();
  }

  buyOrders = [];
  buyClosedOrders = [];
  sellOrders = [];
  sellClosedOrders = [];
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  getBuyBidRequests() {
    let data = {
      type: 'Buy',
      status: this.orderStatus !== 'ALL' ? this.orderStatus : 'RequestOpen',
      pageNo: this.buyOrdersActivePage,
      primaryStatus: 'RequestOpen',
    };
    this.store.getBidRequests(data).subscribe((res: any) => {
      if (res) {

        this.buyOrders = res.Bids.content;
        // res.Bids.content.sort(
        //   (a, b) =>
        //     new Date(a.lastUpdated).getTime() -
        //     new Date(b.lastUpdated).getTime()
        // );
        this.buyOrdersGetPage(res);
        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });

    data = {
      type: 'Buy',
      status: this.orderStatus !== 'ALL' ? this.orderStatus : 'RequestClosed',
      pageNo: this.buyOrdersCompletedActivePage,
      primaryStatus: 'RequestClosed',
    };
    this.store.getBidRequests(data).subscribe((res: any) => {
      if (res) {
        // 
        this.buyClosedOrders = res.Bids.content;

        // res.Bids.content.sort(
        //   (a, b) =>
        //     new Date(a.lastUpdated).getTime() -
        //     new Date(b.lastUpdated).getTime()
        // );
        this.buyOrdersbuyOrdersCompletedGetPage(res);
        // if (this.buyClosedOrders.length) {
        //   this.buyClosedOrders.forEach((element) => {
        //     let order = {
        //       date: element.lastUpdated,
        //       content: element,
        //       type: 'bid',
        //     };

        //     this.mergedCompletedOrders.push(order);
        //   });
        // }

        return;
      } else {
      }
    });
  }

  filtersModel: any = {
    ALL: 'ALL',
    RequestOpen: 'Request Open',
    RequestClosed: 'Request Closed',
    RequestExpired: 'Request Expired',
    PendingResponse: 'Pending Response',
    SellerResponsded: 'Seller Responsded',
    RequestAccepted: 'Request Accepted',
    PaymentPending: 'Payment Pending',
    OrderPlaced: 'Order Placed',
    BidCancelledByBidder: 'Bid Cancelled By Bidder',
    BidRejected: 'Bid Rejected',
    Paid: 'Paid',
    OrderRecieved: 'Order Recieved',
  };

  bidsFilter: any = [];
  getBidRequestStatusEnum() {
    this.store.getBidRequestStatusEnum().subscribe((res: any) => {
      if (res) {
        this.bidsFilter = res;
        // this.successToast('SELL BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }
  getSellBidRequests() {
    let data = {
      type: 'Sell',
      status: this.orderStatus !== 'ALL' ? this.orderStatus : 'RequestOpen',
      pageNo: this.sellOrderActivePage,

      primaryStatus: 'RequestOpen',
    };
    this.store.getBidRequests(data).subscribe((res: any) => {
      if (res) {
        this.sellOrders = res.Bids.content;
        this.sellOrderGetPage(res);
        return;
      } else {
      }
    });

    data = {
      type: 'Sell',
      status: this.orderStatus !== 'ALL' ? this.orderStatus : 'RequestClosed',
      pageNo: this.sellOrderCloseActivePage,

      primaryStatus: 'RequestClosed',
    };
    this.store.getBidRequests(data).subscribe((res: any) => {
      if (res) {
        this.sellClosedOrders = res.Bids.content;
        this.sellOrderCloseGetPage(res);
        return;
      } else {
      }
    });
  }
  cartBuyOrders: any;

  getCartBuyOrders() {
    let data = {
      customerId: '',
      pageNo: this.activePage,
      pageSize: 8,
      sortOption: 'orderCreationDate',
      sortOrder: 'Desc',
    };
    this.store.getOrders(data).subscribe((res: any) => {
      if (res) {
        this.cartBuyOrders = res.content;
        this.getPage(res);
        // if (this.cartBuyOrders.length) {
        //   this.cartBuyOrders.forEach((element) => {
        //     let order = {
        //       date: element.lastUpdated,
        //       content: element,
        //       type: 'cart',
        //     };

        //     this.mergedCompletedOrders.push(order);
        //   });
        // }

        // if (this.mergedCompletedOrders.length) {
        //   this.mergedCompletedOrders.sort(function (a, b) {
        //     return new b.date() - a.date;
        //   });
        // }

        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }


  cartSellOrders: any;
  getCartSellOrders() {


    if (!this.auth.currentUser$.hasStore) {
      return;
    }


    let data = {
      customerId: '',
      pageNo: this.activePage,
      pageSize: 8,
      sortOption: 'orderCreationDate',
      sortOrder: 'Desc',
      storeId: this.merchantStoreData['storeId']
    };
    this.store.getCartSellOrders(data).subscribe((res: any) => {
      if (res) {
        this.cartSellOrders = res.content;
        this.cartSellGetPage(res);
        return;
      } else {
      }
    });
  }

  // Buy order pending pagination
  buyOrdersActivePage = 0;
  buyOrdersTotal_count: any;
  buyOrdersPager: any = {};

  buyOrdersGetPage(res: any) {
    this.buyOrdersPagination(this.buyOrdersActivePage);
    this.buyOrdersTotal_count = res.Bids.totalPages;
    this.buyOrdersPager = this.buyOrdersGetPager(
      this.buyOrdersTotal_count,
      this.buyOrdersActivePage
    );
  }
  buyOrdersPagerSetPage(page: number) {
    this.buyOrdersActivePage = page;
    if (page < 0 || page > this.buyOrdersPager.totalPages) {
      return;
    }
    this.buyOrdersPager = this.buyOrdersGetPager(
      this.buyOrdersTotal_count,
      page
    );
    this.getBuyBidRequests();
  }
  buyOrdersPagination(page: number) {
    return page.toString();
  }
  buyOrdersGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  // Buy order completed pagination
  buyOrdersCompletedActivePage = 0;
  buyOrdersCompletedTotal_count: any;
  buyOrdersCompletedPager: any = {};

  buyOrdersbuyOrdersCompletedGetPage(res: any) {
    this.buyOrdersCompletedPagination(this.buyOrdersCompletedActivePage);
    this.buyOrdersCompletedTotal_count = res.Bids.totalPages;
    this.buyOrdersCompletedPager = this.buyOrdersCompletedGetPager(
      this.buyOrdersCompletedTotal_count,
      this.buyOrdersCompletedActivePage
    );
  }
  buyOrdersCompletedPagerSetPage(page: number) {
    this.buyOrdersCompletedActivePage = page;
    if (page < 0 || page > this.buyOrdersCompletedPager.totalPages) {
      return;
    }
    this.buyOrdersCompletedPager = this.buyOrdersCompletedGetPager(
      this.buyOrdersCompletedTotal_count,
      page
    );
    this.getBuyBidRequests();
  }
  buyOrdersCompletedPagination(page: number) {
    return page.toString();
  }
  buyOrdersCompletedGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  // Sell order open pagination
  sellOrderActivePage = 0;
  sellOrderTotal_count: any;
  sellOrdersPager: any = {};

  sellOrderGetPage(res: any) {
    this.sellOrderPagination(this.sellOrderActivePage);
    this.sellOrderTotal_count = res.Bids.totalPages;
    this.sellOrdersPager = this.sellOrderGetPager(
      this.sellOrderTotal_count,
      this.sellOrderActivePage
    );
  }
  sellOrdersPagerSetPage(page: number) {
    this.sellOrderActivePage = page;
    if (page < 0 || page > this.sellOrdersPager.totalPages) {
      return;
    }
    this.sellOrdersPager = this.sellOrderGetPager(
      this.sellOrderTotal_count,
      page
    );
    this.getSellBidRequests();
  }
  sellOrderPagination(page: number) {
    return page.toString();
  }
  sellOrderGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  // Sell order closed pagination
  sellOrderCloseActivePage = 0;
  orderStatus: any = 'ALL';
  sellOrderCloseTotal_count: any;
  sellOrderClosePager: any = {};

  sellOrderCloseGetPage(res: any) {
    this.sellOrderClosePagination(this.sellOrderCloseActivePage);
    this.sellOrderCloseTotal_count = res.Bids.totalPages;
    this.sellOrderClosePager = this.sellOrderCloseGetPager(
      this.sellOrderCloseTotal_count,
      this.sellOrderCloseActivePage
    );
  }
  sellOrderClosesPagerSetPage(page: number) {
    this.sellOrderCloseActivePage = page;
    if (page < 0 || page > this.sellOrderClosePager.totalPages) {
      return;
    }
    this.sellOrderClosePager = this.sellOrderCloseGetPager(
      this.sellOrderCloseTotal_count,
      page
    );
    this.getSellBidRequests();
  }
  sellOrderClosePagination(page: number) {
    return page.toString();
  }
  sellOrderCloseGetPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 1
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }





  // Cart buy orders pagination
  activePage = 0;
  total_count: any;
  pager: any = null;
  getPage(res: any) {
    this.pagination(this.activePage);
    this.total_count = res.totalPages;

    this.pager = this.getPager(this.total_count, this.activePage);
  }
  setPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.total_count, page);
    this.getCartBuyOrders();
  }
  pagination(page: number) {
    return page.toString();
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 1) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
















  // Cart sell orders pagination
  cartSellActivePage = 0;
  cartSellTotal_count: any;
  cartSellPager: any = null;
  cartSellGetPage(res: any) {
    this.pagination(this.activePage);
    this.total_count = res.totalPages;

    this.cartSellPager = this.cartSellGetPager(this.total_count, this.activePage);
  }
  cartSellSetPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.cartSellPager.totalPages) {
      return;
    }
    this.cartSellPager = this.cartSellGetPager(this.total_count, page);
    this.getCartBuyOrders();
  }
  cartSellPagination(page: number) {
    return page.toString();
  }
  cartSellGetPager(totalItems: number, currentPage: number = 1, pageSize: number = 1) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }



  orderDetails: any = [];
  paymentDetails: any = [];
  getOrderDetails(id) {
    let data = {
      id: id,
    };
    this.store.getOrderDetails(data).subscribe((res: any) => {
      if (res) {
        this.orderDetails = res;
        return;
      } else {
      }
    });
  }

  getPaymentDetails(id) {
    let data = {
      id: id,
    };
    this.paymentDetails = [];
    this.store.getPaymentDetails(data).subscribe((res: any) => {
      if (res) {
        this.paymentDetails = res;
        return;
      } else {
      }
    });
  }
  @ViewChild('target') MyProp: ElementRef;

  gotoProduct(id): void {
    this.router
      .navigate(['/list-detail'], { queryParams: { productId: id } })
      .then((res) => {
        this.MyProp.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
  }
  toggleOrderDetails(index) {
    if (this.cartBuyOrders[index].hasOwnProperty('checked')) {
      this.cartBuyOrders[index].checked = !this.cartBuyOrders[index].checked;
    } else {
      this.cartBuyOrders[index] = {
        ...this.cartBuyOrders[index],
        checked: true,
      };
    }
    this.showOrderDetails = !this.showOrderDetails;
    this.getOrderDetails(this.cartBuyOrders[index].orderKey);
    this.getPaymentDetails(this.cartBuyOrders[index].orderKey);
  }
}
