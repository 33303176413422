import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-no-listing',
  templateUrl: './no-listing.component.html',
  styleUrls: ['./no-listing.component.scss'],
})
export class NoListingComponent implements OnInit {
  rate = 4;
  constructor(
    public commonService: CommonService,

    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {
    this.onLoad();
  }

  onLoad() {
    this.getMasterStoreSettings();
    this.getStoreKey();
    this.getStoreCategories();
  }
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  ionViewWillEnter() {
    this.onLoad();
  }
  cat = '';

  listedProducts = [];


  doRefresh(event) {
    this.onLoad();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }
  ngOnInit() {
    // this.onLoad();
  }

  viewUserProfile() {
    let data = {};
    this.store.viewProfile().subscribe((res: any) => {
      if (res) {
        localStorage.setItem('currentUserProfile', JSON.stringify(res));
        this.auth.getCurrentUser();
        return;
      } else {
      }
    });
  }

  getNewStore() {
    if (!this.masterStoreData) {
      return;
    }

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let data1 = {
      email: t.email,
      loginToken: localStorage.getItem('loginToken'),
      password: t.emailAddr,
      username: t.emailAddr,
      userFirstName: t.userFirstName,
      userLastName: t.userLastName,
      storeEmailPrefix: this.masterStoreData['EmailPrefix'],
    };

    try {
      this.auth.addNewStore(data1).subscribe((res2: any) => {
        this.successToast('STORE SETUP SUCCESS!');
        // this.getStoreKey();
        this.merchantStoreData = res2;
        // this.auth.logout();

        this.viewUserProfile();

        this.onLoad();
      });
    } catch (err) {
    } finally {
      // this.successToast('STORE SETUP FAILED!')
      // this.getStoreKey();
      //this.merchantStoreData = err;
    }
  }

  getStoreCategories() {
    this.store.getStoreCategories().subscribe((res: any) => {
      if (res) {
        
        this.cat = res.Categories[0];
        // this.successToast('CATEGORY ID FETCH SUCCESS!')
        this.getProducts();
        return;
      } else {
      }
    });
  }
  getStoreKey() {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }

    this.spinner.show();
    this.store.getStoreKey().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.merchantStoreData = res;
        return;
      } else {
      }
    });
  }
  masterStoreData = null;
  getMasterStoreSettings() {
    this.spinner.show();
    this.store.getMasterStoreSettings().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.masterStoreData = res;
        return;
      } else {
      }
    });
  }

  fileToUpload: File | null = null;
  handleFileInput(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      this.fileToUpload = file;
      let data = {
        file: this.fileToUpload,
        storeKey: this.merchantStoreData.storeId,
      };
      this.store.uploadCsvFile(data).subscribe((res) => {
        if (res) {
          this.toaster.success('CSV uploaded successfully!');
          this.onLoad();
        } else {
          this.toaster.warning(res.message);
          return;
        }
      });
    }
  }
  merchantStoreData = null;

  getProducts() {
    if (!this.merchantStoreData) {
      return;
    }

    let data = {
      categoryId: '',
      filter: 'TextSearch',
      pageNo: this.activePage,
      pageSize: 8,
      searchString: 'MY NEW',
      sortOption: 'productName',
      sortOrder: 'DESCENDING',
      storeId: this.merchantStoreData['storeId'],
      sendAllProducts: true,
    };
    this.store.searchStoreProduct(data).subscribe((res: any) => {
      if (res) {
        
        this.listedProducts = res.products;
        this.getPage(res);
        // this.successToast('PRODUCTS FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }

  activePage = 0;
  total_count: any;
  pager: any = {};
  pageSize: any;
  pageFix(i, total, current) {
    if (i === current) {
      return true;
    }

    if (i < 4) {
      if (i === current) {
        return false;
      }

      return true;
    }

    if (i >= total - 4 && i < total) {
      if (i === current) {
        return false;
      }
      return true;
    }
    return false;
  }
  getPage(res) {
    this.pagination(this.activePage);
    this.total_count = res.totalpages;
    this.pager = this.getPager(this.total_count, this.activePage);
  }
  setPage(page: number) {
    this.activePage = page;
    if (page < 0 || page > this.pager.totalPages - 1) {
      this.toaster.info('Reached end of pages!');
      return;
    }
    this.pager = this.getPager(this.total_count, page);
    this.getProducts();
  }
  pagination(page: number) {
    return page.toString();
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 1) {
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= this.total_count) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
  downloadMyFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://salvagescout.s3.amazonaws.com/theretailgrid/2/1/tagFolder/Download-SalvageScout_DataTemplate.csv');
    link.setAttribute('download', `Salvage Scout Data`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
