import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sell-item-model',
  templateUrl: './sell-item-model.component.html',
  styleUrls: ['./sell-item-model.component.scss'],
})
export class SellItemModelComponent implements OnInit {
  title = "Sell this item";
  description: 'Please provide the reasons to report this listing'

  constructor(
    @Optional() public dialogRef: MatDialogRef<SellItemModelComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
    if (this.data && this.data.description) {
      this.description = this.data.description;
    }
  }


}
