import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import swal from 'sweetalert2';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  TAB_VALUE = 'CORPORATE';
  profilePicVal: any;
  profileArr: any = [];
  firstname: any;
  lastname: any;
  email: any;
  dob: any;
  model: any = {};
  spinnerImg: any = false;
  constructor(
    public store: StoreService,
    public auth: AuthService,
    public router: Router,
    private spinner1: NgxSpinnerService,
    private toastr: ToastrService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {
    // this.onLoad();
  }

  page: any;
  ngOnInit() {
    this.onLoad();

  }
  ionViewWillEnter() {
    this.onLoad();
  }
  setInputDate(_id) {
    var _dat = document.querySelector(_id);

    _dat.value = this.model.dobString;
    this.model.dob = this.model.dobString;
  }
  public onLoad() {
    const currentUserProfile = JSON.parse(
      localStorage.getItem('currentUserProfile')
    );

    this.profileArr = currentUserProfile;

    let default_dob = "1990-01-01";
    this.model = {
      companyFDIN: currentUserProfile.fdin ? currentUserProfile.fdin : '',
      companyName: currentUserProfile.companyName ? currentUserProfile.companyName.split(":")[0] : '',
      taxId: currentUserProfile.companyName ? currentUserProfile.companyName.split(":")[1] : '',
      website: currentUserProfile.companyName ? currentUserProfile.companyName.split(":")[2] : '',
      firstname: currentUserProfile.userFirstName,
      lastname: currentUserProfile.userLastName,
      email: currentUserProfile.emailAddr,
      dob: currentUserProfile.dob ? currentUserProfile.dob : new Date(default_dob).valueOf(),
      dobString: currentUserProfile.dobString ? currentUserProfile.dobString : default_dob
    };

    if (this.profileArr.userRole[0].name === 'ROLE_USER') {
      this.activeTab('INDIVIDUAL');
    } else {
      this.activeTab('CORPORATE');
    }



    setTimeout(() => {
      this.setInputDate("#dob");

      this.spinner1.hide();
    }, 3000);
  }

  activeTab(TAB_VALUE) {
    this.TAB_VALUE = TAB_VALUE;
  }
  uploadProfilePic() {
    let data = {
      file: this.profilePicVal,
    };
  }
  doRefresh(event) {
    const currentUserProfile = JSON.parse(
      localStorage.getItem('currentUserProfile')
    );

    this.onLoad();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  ValidateSize(file, limit) {
    var FileSize = file.files[0].size / 1024 / 1024; // in MiB
    if (FileSize > limit) {
      return false;
    } else {
      return true;
    }
  }


  public uploadImage(event: any, imageForm): void {

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      if (!this.ValidateSize(event.target, 5)) {
        this.toastr.warning("Please upload image that is less than 5mb!");
        return;
      }

      this.spinnerImg = true;

      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;

            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            // $.event.trigger({
            //   type: 'imageResized',
            //   blob: resizedImage,
            //   url: dataUrl,
            // });
          };
          $('#profileImage').attr('src', readerEvent.target.result);
          //this.profileArr.uploadedImage= readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
      /*shrink*/

      this.store.updateUserProfilePic(event).subscribe(
        (res: any) => {
          if (res) {
            this.spinnerImg = false;
            this.toastr.success(
              'Profile picture uploaded successfully, Login again!'
            );
            // this.auth.logout();
            localStorage.setItem('currentUserProfile', JSON.stringify(res));
            this.auth.getCurrentUser();
            imageForm.reset();
            setTimeout(() => {

              this.onLoad();
            }, 2000);
          } else {
            this.spinnerImg = false;
            this.toastr.success(res.message);
            return;
          }
        },
        (err: any) => {

          // this.spinner1.hide();
          // if (!this.common.checkValidAuthResponseCode(err)) {
          //   return;
          // }
          // if (err.error.text) {
          //   this.toastr.success(err.error.text);
          // } else {
          //   this.toastr.success(err.error.message);
          // }
        }
      );
    }
  }


  submitIndividualForm(updateForm) {
    let data = {
      desiredRole: 'ROLE_USER',
      companyFDIN: this.model.companyFDIN,
      companyName: this.model.companyName + ":" + this.model.taxId + ":" + this.model.website,
      firstname: this.model.firstname,
      lastname: this.model.lastname,
      email: this.model.email,
      dob: new Date(this.model.dobString).valueOf(),
    };

    this.spinner1.show();
    this.store.updateUserProfile(data).subscribe(
      (res: any) => {
        this.spinner1.hide();
        if (res) {
          this.toastr.success('Profile updated  successfully!');
          localStorage.setItem('currentUserProfile', JSON.stringify(res));
          this.auth.getCurrentUser();
          setTimeout(() => {
            this.onLoad();
          }, 2000);
        } else {
          this.spinner1.hide();
          this.toastr.success(res.message);
          return;
        }
      },
      (err: any) => {
        this.spinner1.hide();
        // this.spinner1.hide();
        // if (!this.common.checkValidAuthResponseCode(err)) {
        //   return;
        // }
        // if (err.error.text) {
        //   this.toastr.success(err.error.text);
        // } else {
        //   this.toastr.success(err.error.message);
        // }
      }
    );
  }
  orderDetails = [];

  viewUserProfile() {
    let data = {

    }
    this.store.viewProfile().subscribe((res: any) => {
      if (res) {
        this.onLoad();

        return;
      } else {
      }
    });
  }
  submitForm(updateForm) {

    let data = {
      desiredRole: 'ROLE_CORPORATE_USER',
      companyFDIN: this.model.companyFDIN,
      companyName: this.model.companyName + ":" + this.model.taxId + ":" + this.model.website,
      firstname: this.model.firstname,
      lastname: this.model.lastname,
      email: this.model.email,
      dob: new Date(this.model.dobString).valueOf(),
    };
    this.spinner1.show();
    this.store.updateUserProfile(data).subscribe(
      (res: any) => {
        this.spinner1.hide();
        if (res) {
          this.toastr.success('Profile updated successfully!');
          localStorage.setItem('currentUserProfile', JSON.stringify(res));
          this.auth.getCurrentUser();
          setTimeout(() => {

            this.onLoad();
          }, 2000);
        } else {
          this.spinner1.hide();
          this.toastr.success(res.message);
          return;
        }
      },
      (err: any) => {
        this.spinner1.hide();
        // this.spinner1.hide();
        // if (!this.common.checkValidAuthResponseCode(err)) {
        //   return;
        // }
        // if (err.error.text) {
        //   this.toastr.success(err.error.text);
        // } else {
        //   this.toastr.success(err.error.message);
        // }
      }
    );
  }



  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  async warningToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-info-toast',
    });
    toast.present();
  }


}
