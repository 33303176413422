import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
@Component({
  selector: 'app-requests-browse',
  templateUrl: './requests-browse.component.html',
  styleUrls: ['./requests-browse.component.scss'],
})
export class RequestsBrowseComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
 }

 
}
