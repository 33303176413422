import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-view-item',
  templateUrl: './list-view-item.component.html',
  styleUrls: ['./list-view-item.component.scss'],
})
export class ListViewItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
