import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CountriesAndStatesService {
  devURL: string = 'https://countriesnow.space';
  baseEndpoint: string = '/api/v0.1/countries';
  constructor(private http: HttpClient) {}
  getAllCountries() {
    return this.http.get(this.devURL + this.baseEndpoint);
  }
  getStatesByCountry(data) {
    return this.http.post(this.devURL + this.baseEndpoint + '/states', data);
  }
  getCitiesByState(data) {
    return this.http.post(
      this.devURL + this.baseEndpoint + '/state/cities',
      data
    );
  }
}
