import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';

@Component({
  selector: 'app-sell-item',
  templateUrl: './sell-item.component.html',
  styleUrls: ['./sell-item.component.scss'],
})
export class SellItemComponent implements OnInit {
  @ViewChild('sellItemForm') public sellItemForm: NgForm;
  @ViewChild('sellItemForm1') public sellItemForm1: NgForm;



  selectedCountries: number;
  productDetails: any;
  newProductId: any;
  selectedCar: number;

  categories = [{ id: 1, name: 'Hood and frontend' }];
  manufactures = [
    { id: 1, name: 'Brandon' },
    { id: 2, name: 'Honda' },
    { id: 3, name: 'Paccar' },
  ];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  new = true;
  refurbished = false;
  pickUpAddressToggle = false;
  TAB_SELECTED = 'fedEx';
  @ViewChild('stepper') stepper: MatStepper;
  model: any = {};
  checkboxes = [
    // {
    //   val: 'isHotselling',
    //   name: 'Hot Selling',
    //   isChecked: true,
    //   disabled: false,
    // },
    // { val: 'isBranded', name: 'Branded', isChecked: false, disabled: false },
    // { val: 'isNew', name: 'New Arrival', isChecked: false, disabled: false },
    // {
    //   val: 'hasOffer',
    //   name: 'Has an offer',
    //   isChecked: false,
    //   disabled: false,
    // },
    // {
    //   val: 'trackInventory',
    //   name: 'Track Inventory',
    //   isChecked: true,
    //   disabled: false,
    // },
    // {
    //   val: 'taxExempted',
    //   name: 'Tax Exempted',
    //   isChecked: false,
    //   disabled: false,
    // },
    {
      val: 'taxInclusive',
      name: 'Is this product tax inclusive?',
      isChecked: true,
      disabled: false,
    },
  ];
  toCamelCase(str) {
    return str
      .split(' ')
      .map(function (word, index) {
        // If it is the first word make sure to lowercase all the chars.
        if (index == 0) {
          return word.toLowerCase();
        }
        // If it is not the first word only upper case the first char and lowercase the rest.
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  }
  CamelCase(str) {
    return str
      .split(' ')
      .map(function (word, index) {
        // If it is the first word make sure to lowercase all the chars.

        // If it is not the first word only upper case the first char and lowercase the rest.
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  capitalizeAllLetter(string) {
    return string.charAt(0).toUpperCase();
  }
  removeDots(_string) {
    let dummyString = String(_string);
    let finalString = '';
    for (let i = 0; i < dummyString.length; i++) {
      if (!(dummyString.charAt(i) === '.')) {
        finalString = finalString + dummyString.charAt(i);
      }
    }
    return finalString;
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  constructor(
    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private _formBuilder: FormBuilder,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,

  ) {

    // this.onLoad();

    // this.stepperGoto(1);
  }

  onLoad() {

    this.getStoreKey();
    this.getStoreCategories();
    this.productTag.value = "";

    this.productTag.value = "";
    this.new = true;
    this.refurbished = false;
    if (this.router.url.split('/')[2]) {
      this.actionType = this.router.url.split('/')[2];

    }


    this.model.totalTaxPercentage = 0;
    this.model.taxInclusive = true;
    this.firstFormGroup = this._formBuilder.group({
      // firstCtrl: ['', Validators.required],
      // prodName: ['', Validators.pattern('([0-9a-zA-Z]{1,100})')],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.getStoreKey();
    this.getStoreCategories();
    this.store.getHomePageListSectionDetails();

    this.activatedRoute.params.subscribe((res) => {


      this.productId = res.productId;
      if (this.productId === 'create-new' || this.productId === undefined || this.productId == null) {

        return;

      }
      let data = {
        productId: this.productId,
      };
      this.store.getProductById(data).subscribe((res) => {
        this.productDetails = res;
        if (this.productDetails) {
          this.setProductModel();

          if (this.actionType === "edit-item") {
            Object.keys(this.sellItemForm.form.controls).forEach(key => {
              this.sellItemForm.form.controls[key].markAllAsTouched();
              setTimeout(() => {
                $("[name='" + key + "']").val(this.sellItemForm.form.controls[key].value).trigger('change');
              }, 1500);
            })

            Object.keys(this.sellItemForm1.form.controls).forEach(key => {
              this.sellItemForm1.form.controls[key].markAllAsTouched();
              setTimeout(() => {
                $("[name='" + key + "']").val(this.sellItemForm.form.controls[key].value).trigger('change');
              }, 1500);
            })

          }

          if (this.actionType === "sell-item") {
            this.model.shortName = "";
            this.model.productBarCode = "";
            this.model.alernateId = "";

            Object.keys(this.sellItemForm.form.controls).forEach(key => {
              this.sellItemForm.form.controls[key].markAllAsTouched();
              setTimeout(() => {
                $("[name='" + key + "']").val(this.sellItemForm.form.controls[key].value).trigger('change');
              }, 1500);

            })
            Object.keys(this.sellItemForm1.form.controls).forEach(key => {
              this.sellItemForm1.form.controls[key].markAllAsTouched();

              $("[name='" + key + "']").val(this.sellItemForm.form.controls[key].value).trigger('change');
              setTimeout(() => {
                $("[name='" + key + "']").val(this.sellItemForm.form.controls[key].value).trigger('change');
              }, 1500);
            })
          }
        }
      });


    });

    setTimeout(() => {
      if (this.productId === 'create-new') {

        this.stepperGoto(0);
      }
    }, 1000);
  }


  productId: any;
  // productDetails: any;

  checkboxClick(e, entry) {
    if (entry.isChecked) {
      this.model.totalTaxPercentage = 0;
      this.model.taxInclusive = true;
    } else {
      this.model.totalTaxPercentage = 5;
      this.model.taxInclusive = false;
    }
  }
  productTag: any = {};
  actionType: any;
  ngOnInit() {

    // this.onLoad();
  }


  ionViewWillEnter() {
    this.onLoad();
    this.stepperGoto(0);
  }
  setProductModel() {
    this.model = this.productDetails;
    this.model.alernateId = this.productDetails.alernateId;
    this.selectedCategoryName = this.productDetails.categoryName;
    this.selectedCategoryId = parseInt(this.productDetails.categoryId);
    this.selectedSubCategoryName = this.productDetails.subCategoryname;
    this.selectedCategoryId = parseInt(this.productDetails.subCategoryId);
    this.new = this.model.new;
    this.refurbished = this.model.refurbished;
    if (this.productDetails.productMeasure) {
      try {

        this.model.length = parseInt(this.productDetails.productMeasure.split(':')[0].split("-")[1]);
        this.model.width = parseInt(this.productDetails.productMeasure.split(':')[1].split("-")[1]);
        this.model.height = parseInt(this.productDetails.productMeasure.split(':')[2].split("-")[1]);
        this.model.weight = parseInt(this.productDetails.productMeasure.split(':')[3].split("-")[1]);

      } catch (error) {

      }
    }

    if (this.productDetails.productHsnCode) {
      this.model.productHsnCode = this.productDetails.productHsnCode;
    }


    this.model.productTags = [];
    this.model.totalTaxPercentage = this.productDetails.totalTaxPercentage
      ? this.productDetails.totalTaxPercentage
      : 0;
    this.model.minBuyingQuantity = this.productDetails.minBuyingQuantity;

    if (this.productDetails.taxInclusive) {
      this.model.taxInclusive = true;
    } else {
      this.model.taxInclusive = false;
    }


  }
  spinnerImg: any = false;
  public uploadImage(event: any, imageNum, imageForm1): void {
    this.spinnerImg = true;

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];

      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;

            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            // $.event.trigger({
            //   type: 'imageResized',
            //   blob: resizedImage,
            //   url: dataUrl,
            // });
          };
          $('#image0').attr('src', readerEvent.target.result);
          //this.profileArr.uploadedImage= readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
      /*shrink*/
      let data = {
        productId: this.productDetails.productId,
        displayOrder: imageNum,
        isPrimary: false,
        storeId: this.merchantStoreData.storeId,
      };
      this.store.updateProductPic(event, data).subscribe(
        (res: any) => {
          if (res) {
            imageForm1.reset();

            this.productDetails = res;
            if (this.productDetails) {
              this.setProductModel();
            }

            this.spinnerImg = false;
            this.toaster.success('Product image uploaded successfully!');
          } else {
            this.spinnerImg = false;
            this.toaster.success(res.message);
            return;
          }
        },
        (err: any) => {
          // this.spinner.hide();
          // if (!this.common.checkValidAuthResponseCode(err)) {
          //   return;
          // }
          // if (err.error.text) {
          //   this.toastr.success(err.error.text);
          // } else {
          //   this.toastr.success(err.error.message);
          // }
        }
      );
    }
  }

  merchantStoreData = null;

  getStoreKey() {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }

    this.store.getStoreKey().subscribe((res: any) => {
      if (res) {
        this.merchantStoreData = res;
        //this.successToast('STORE KEY  FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }
  updateProductPrimaryImage(productId, imageId) {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }
    let data = {
      productId: productId,
      imageId: imageId,
      storeId: this.merchantStoreData.storeId
    }

    this.store.updateProductPrimaryImage(data).subscribe((res: any) => {
      if (res) {
        //nandan
        this.productDetails = res;
        if (this.productDetails) {
          this.setProductModel();
        }
        this.successToast('Primary image changed!')
        return;
      } else {
      }
    });
  }
  deleteProductImage(productId, imageId, imageUrl) {
    if (!this.auth.currentUser$.hasStore) {
      return;
    }
    let data = {
      productId: productId,
      imageId: imageId,
      storeId: this.merchantStoreData.storeId,
      imageUrl: imageUrl
    }

    this.store.deleteProductImage(data).subscribe((res: any) => {
      if (res) {
        //nandan
        this.productDetails = res;
        if (this.productDetails) {
          this.setProductModel();
        }
        this.successToast('Image deleted!')
        return;
      } else {
      }
    });
  }
  addTag(tag) {
    if (!this.model.productTags) {
      this.model.productTags = [];

    }
    if (!this.model.productTags.length) {
      this.model.productTags = [];
    }
    this.model.productTags.push(tag);
    this.productTag.value = "";

    this.toaster.success("Tag added.");
  }
  removeTag(tag) {
    if (this.model.productTags.length) {
      this.model.productTags.pop(tag);

      this.toaster.success("Tag removed.");
    }

  }

  cat = null;
  getStoreCategories() {
    this.store.getStoreCategories().subscribe((res: any) => {
      if (res) {
        this.cat = res.Categories[0];
        //   this.store.getCategories();
        // this.successToast('CATEGORY ID FETCH SUCCESS!')
        //  this.getProducts();
        return;
      } else {
      }
    });
  }
  openVideoPopup() {
    let dialogRef = this.commonService.openVideoPopup();
    dialogRef.afterClosed().subscribe((result) => {

    });
  }
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  getManName(manId) {
    let manName = false;
    this.store.manufacturers$.forEach(element => {
      if (element.brandId === manId) {
        manName = element.brandName;
      }
    });
    return manName;
  }
  requestData: any = {};
  addDummyNewProduct(sellItemForm, type) {
    let formData = sellItemForm.form.value;

    if (
      parseFloat(formData.productOfferPrice) >
      parseFloat(formData.productSellingPrice)
    ) {
      this.toaster.error(
        'Oops, Product offer price should be smaller than product selling price!'
      );

      this.sellItemForm.form.controls['productOfferPrice'].markAllAsTouched();
      return;
    }

    if (
      parseFloat(formData.productMinSellingPrice) >
      parseFloat(formData.productSellingPrice)
    ) {
      this.toaster.error(
        'Oops, Product minimum selling price should be smaller than product selling price!'
      );

      this.sellItemForm.form.controls['productMinSellingPrice'].markAllAsTouched();
      return;
    }


    if (
      parseFloat(formData.productMinSellingPrice) >
      parseFloat(formData.productOfferPrice)
    ) {
      this.toaster.error(
        'Oops, Product minimum selling price should be smaller than product offer price!'
      );
      this.sellItemForm.form.controls['productMinSellingPrice'].markAllAsTouched();

      this.sellItemForm.form.controls['minBuyingQuantity'].markAllAsTouched();

      return;
    }

    if (
      parseFloat(formData.minBuyingQuantity) >
      parseFloat(formData.productAvailableQuantity)
    ) {

      this.toaster.error(
        'Oops, Product available quantity should be greater than minimum buying quantity!'
      );
      this.sellItemForm.form.controls['productAvailableQuantity'].markAllAsTouched();

      return;
    }

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let barcode = Math.floor(100000000 + Math.random() * 900000000);
    this.requestData = {
      active: this.model.active,
      alernateId: formData.alernateId ? formData.alernateId.toString() : '',
      // availableQuantity: parseInt(formData.availableQuantity),
      brandName: formData.brandName.toString(),
      categoryId: parseInt(this.selectedCategoryId),
      categoryName: this.selectedCategoryName,
      subCategoryname: this.selectedSubCategoryName,
      subCategoryId: parseInt(this.selectedSubCategoryId),
      featuredProduct: false,
      hasOffer: false,
      hotSelling: false,
      productHsnCode: this.model.productHsnCode ? this.model.productHsnCode.toString() : '',
      interestPercentage: 0,
      message: '',
      minBuyingQuantity: formData.minBuyingQuantity
        ? parseInt(formData.minBuyingQuantity)
        : 0,
      new: this.new,
      refurbished: this.refurbished,
      newArrival: true,
      product: true,
      productAvailableQuantity: parseInt(formData.productAvailableQuantity),
      productBarCode: this.model.productBarCode ? this.model.productBarCode.toString() : '',
      productCreditDuration: 0,
      productDescription: formData.productDescription,
      manufacturerId: formData.manufacturerId,
      manufacturerName: this.getManName(formData.manufacturerId),
      // alternateId: formData.alternateId,
      productHowToUse: '',
      productId: this.productDetails
        ? this.productDetails.productId
          ? this.productDetails.productId
          : 0
        : 0,
      productMinSellingPrice: parseFloat(formData.productMinSellingPrice),
      productName: formData.productName,
      productOfferPrice: parseFloat(formData.productOfferPrice),
      productPurchasePrice: parseFloat(formData.productMinSellingPrice),
      productSellingPrice: parseFloat(formData.productSellingPrice),
      productShortDescription: formData.productShortDescription,
      productWSP: 0,
      productWSPMinQuantity: 0,
      shortName: this.model.shortName ? this.model.shortName : formData.shortName,
      // taxInclusive: this.model.taxInclusive ? this.model.taxInclusive : false,
      taxInclusive: false,
      taxOnPurchase: 0,
      token: {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: localStorage.getItem('deviceFingerPrint'),
        },
        loginToken: localStorage.getItem('loginToken'),
      },
      // totalTaxPercentage: parseInt(this.model.totalTaxPercentage),
      totalTaxPercentage: 0,
      trackInventory: true,
      sellerId: t.emailAddr, //
      sellerName: t.userFirstName + ' ' + t.userLastName, //
      sellerProfilePic: t.urlProfilePic, //
      shippingAmount: 0, //
      shippingCost: 0, //
      storeId: this.merchantStoreData.storeId, //
      // status: "",
      used: false
    };

    if (type === 'add') {
      //adding a new product
      this.requestData.productId = "";
      this.requestData.listMedia = [];
      this.requestData.productMeasure = '';
      this.requestData.productTags = '';
    } else if (type === 'edit') {
      //editing the exiting product
      this.requestData.productMeasure = this.productDetails.hasOwnProperty('productMeasure') ? (this.productDetails.productMeasure ? this.productDetails.productMeasure : '') : '';
      this.requestData.productTags = this.model.productTags.length ? this.model.productTags : '';
      this.requestData.listMedia = this.model.listMedia;
      //  this.requestData.status = this.selected;
      this.requestData.manufacturerName = this.getManName(this.requestData.manufacturerId) ? this.getManName(this.requestData.manufacturerId) : '';
      this.requestData.productHsnCode = this.model.productHsnCode ? this.model.productHsnCode.toString() : '';
      this.requestData.productHowToUse = "";
      this.requestData.refurbished = this.model.refurbished;
      this.requestData.new = this.model.new;
      this.requestData.sellerId = t.emailAddr; //
      this.requestData.sellerName = t.userFirstName + ' ' + t.userLastName; //
      this.requestData.sellerProfilePic = t.urlProfilePic; //
      this.requestData.used = this.model.used ? this.model.used : false; //
      this.requestData.taxInclusive = this.model.taxInclusive ? this.model.taxInclusive : false;//
      this.requestData.subCategoryId = this.model.subCategoryId;
      this.requestData.subCategoryName = this.model.subCategoryName;
    } else {
      //sell-this-item ( )
      this.requestData.productTags = this.model.productTags.length ? this.model.productTags : '';
      this.requestData.productId = "";
      this.requestData.listMedia = this.model.listMedia;
      //  this.requestData.status = this.selected;
      this.requestData.productMeasure = this.productDetails.hasOwnProperty('productMeasure') ? (this.productDetails.productMeasure ? this.productDetails.productMeasure : '') : '';
      this.requestData.productHsnCode = this.model.productHsnCode ? this.model.productHsnCode.toString() : '';
      this.requestData.manufacturerName = this.getManName(this.requestData.manufacturerId) ? this.getManName(this.requestData.manufacturerId) : '';
      this.requestData.productHowToUse = "";

      this.requestData.refurbished = this.model.refurbished;

      this.requestData.new = this.model.new;
      this.requestData.sellerId = t.emailAddr; //
      this.requestData.sellerName = t.userFirstName + ' ' + t.userLastName; //
      this.requestData.sellerProfilePic = t.urlProfilePic; //
      this.requestData.used = this.model.used ? this.model.used : false; //
      this.requestData.taxInclusive = this.model.taxInclusive ? this.model.taxInclusive : false;//

      this.requestData.subCategoryId = this.model.subCategoryId;
      this.requestData.subCategoryName = this.model.subCategoryName;
    }




    //updating the listMedia ( becuase uplaod images happens ad-hoc basis)
    if (this.productDetails) {
      if (this.productDetails.listMedia) {
        if (this.productDetails.listMedia.length) {
          this.requestData.listMedia = this.productDetails.listMedia;
        }
      }
    }

    this.store
      .addDummyNewProduct(this.merchantStoreData, this.requestData, type)
      .subscribe((res: any) => {
        if (res) {
          this.productDetails = res;
          if (this.productDetails) {
            this.setProductModel();
          }

          //  sellItemForm.reset();
          this.successToast(type.toUpperCase() + ' PRODUCT SUCCESS!');
          //  this.router.navigate(['/product-dashboard/listing']);
          //  this.getProducts();
          this.stepperGoto(1);

          return;
        } else {
          this.toaster.error('Something went wrong!');
        }
      });
  }














  editProduct(sellItemForm1, type) {
    let formData = sellItemForm1.form.value;

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let barcode = Math.floor(100000000 + Math.random() * 900000000);
    this.requestData = this.productDetails;

    this.requestData.productHsnCode = formData.productHsnCode ? formData.productHsnCode.toString() : '';
    this.requestData.productHowToUse = '';
    this.requestData.productId = this.productDetails
      ? this.productDetails.productId
        ? this.productDetails.productId
        : 0
      : 0,
      this.requestData.productMeasure = 'le-' + (formData.length ? formData.length : 0) + ':wi-' + (formData.width ? formData.width : 0) + ':he-' + (formData.height ? formData.height : 0) + ':we-' + (formData.weight ? formData.weight : 0),
      this.requestData.productTags = this.model.productTags ? this.model.productTags.join(":") : '',
      this.requestData.token = {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: localStorage.getItem('deviceFingerPrint'),
        },
        loginToken: localStorage.getItem('loginToken'),
      };
    this.requestData.trackInventory = true;
    this.requestData.sellerId = t.emailAddr;
    this.requestData.sellerName = t.userFirstName + ' ' + t.userLastName;
    this.requestData.sellerProfilePic = t.urlProfilePic;
    this.requestData.shippingAmount = 0;
    this.requestData.shippingCost = 0;
    this.requestData.storeId = this.merchantStoreData.storeId;
    // this.requestData.alernateId = 'dummy';
    // this.requestData.manufacturerId = 0;
    this.requestData.message = '';
    this.requestData.featuredProduct = false;
    this.requestData.hasOffer = false;
    this.requestData.hotSelling = false;
    this.requestData.interestPercentage = 0;
    this.requestData.new = true;
    this.requestData.newArrival = false;
    this.requestData.product = true;
    this.requestData.productAvailableQuantity = parseInt(this.model.productAvailableQuantity);
    this.requestData.productPurchasePrice = 0;
    this.requestData.productWSP = 0;
    this.requestData.productWSPMinQuantity = 0;
    this.requestData.taxInclusive = false;
    this.requestData.taxOnPurchase = 0;








    if (type === 'edit') {
      this.requestData.listMedia = this.model.listMedia;
      //editing the exiting product
      this.requestData.productMeasure = this.productDetails.hasOwnProperty('productMeasure') ? (this.productDetails.productMeasure ? this.productDetails.productMeasure : '') : '';
      this.requestData.productTags = this.model.productTags.length ? this.model.productTags : '';
      this.requestData.listMedia = this.model.listMedia;
      //  this.requestData.status = this.selected;
      this.requestData.manufacturerName = this.getManName(this.requestData.manufacturerId) ? this.getManName(this.requestData.manufacturerId) : '';
      this.requestData.productHsnCode = this.model.productHsnCode ? this.model.productHsnCode.toString() : '';
      this.requestData.productHowToUse = "";
      this.requestData.refurbished = this.refurbished;
      this.requestData.new = this.new;
      this.requestData.sellerId = t.emailAddr; //
      this.requestData.sellerName = t.userFirstName + ' ' + t.userLastName; //
      this.requestData.sellerProfilePic = t.urlProfilePic; //
      this.requestData.used = this.model.used ? this.model.used : false; //
      // this.requestData.taxInclusive = this.model.taxInclusive ? this.model.taxInclusive : false;
      this.requestData.taxInclusive = false;
    }

    if (this.productDetails) {
      if (this.productDetails.listMedia) {
        if (this.productDetails.listMedia.length) {
          this.requestData.listMedia = this.productDetails.listMedia;
        }
      }
    }

    this.store
      .addDummyNewProduct(this.merchantStoreData, this.requestData, type)
      .subscribe((res: any) => {
        if (res) {
          this.productDetails = res;
          if (this.productDetails) {
            this.setProductModel();
          }
          //  sellItemForm.reset();
          this.successToast(type.toUpperCase() + ' PRODUCT SUCCESS!');
          // this.router.navigate(['/list-detail?productId=' + this.productDetails.productId]);


          var navigationExtras = {
            queryParams: { productId: this.productDetails.productId },
          };
          this.router.navigate(['/list-detail'], navigationExtras);
          //  this.getProducts();
          // this.stepperGoto(2);

          return;
        } else {
          this.toaster.error('Something went wrong!');
        }
      });
  }
  offerPriceCheck(productOfferPrice, sellItemForm) {

    if (
      parseFloat(this.model.productOfferPrice) >
      parseFloat(this.model.productSellingPrice)
    ) {
      this.toaster.error(
        'Oops, Product offer price should be smaller than product selling price!'
      );
      sellItemForm.controls.productOfferPrice.setErrors({ 'invalid': true });

      return;
    }



  }

  minSellingPriceCheck(productMinSellingPrice, sellItemForm) {
    if (
      parseFloat(this.model.productMinSellingPrice) >
      parseFloat(this.model.productSellingPrice)
    ) {
      this.toaster.error(
        'Oops, Product minimum selling price should be smaller than product selling price!'
      );
      sellItemForm.controls.productMinSellingPrice.setErrors({ 'invalid': true });
      return;
    }


    if (
      parseFloat(this.model.productMinSellingPrice) >
      parseFloat(this.model.productOfferPrice)
    ) {
      this.toaster.error(
        'Oops, Product minimum selling price should be smaller than product offer price!'
      );
      sellItemForm.controls.productMinSellingPrice.setErrors({ 'invalid': true });
      return;
    }
  }

  resetMinBuyingQuantity(sellItemForm) {
    this.model.productAvailableQuantity = this.removeDots(
      this.model.productAvailableQuantity
    )
    setTimeout(() => {
      if (
        parseFloat(this.model.minBuyingQuantity) >
        parseFloat(this.model.productAvailableQuantity)
      ) {
        this.model.minBuyingQuantity = 1;
        sellItemForm.controls.minBuyingQuantity.setErrors({ 'invalid': true });
      }
    }, 500);
  }

  minAvailableQtyCheck(sellItemForm) {
    this.model.minBuyingQuantity = this.removeDots(
      this.model.minBuyingQuantity
    )

    if (
      parseFloat(this.model.minBuyingQuantity) >
      parseFloat(this.model.productAvailableQuantity)
    ) {

      this.toaster.error(
        'Oops, Product minimum buying quantity should be lesser than available quantity!'
      );
      setTimeout(() => {
        sellItemForm.controls.minBuyingQuantity.setErrors({ 'invalid': true });
      }, 500);

      // sellItemForm.controls.minBuyingQuantity.setErrors({ 'invalid': true });
      return;
    }
  }

  skuCheck(sku, sellItemForm) {
    this.model.productBarCode = this.capitalizeFirstLetter(sku);
    this.isProductBarcodeUnique(this.model.productBarCode, sellItemForm);
  }
  isProductBarcodeUnique(barcode, sellItemForm) {
    let data = {
      barcode: barcode
    }
    this.store.isProductBarcodeUnique(data).subscribe((res: any) => {
      if (!res) {
        this.toaster.error(
          'Oops, Product barcode should be unique!'
        );
        sellItemForm.controls.productBarCode.setErrors({ 'invalid': true });

      }
    });
  }
  shortNameCheck(shortName, sellItemForm) {
    this.model.shortName = this.CamelCase(shortName);
    this.isProductShortnameUnique(this.model.shortName, sellItemForm);
  }

  isProductShortnameUnique(shortname, sellItemForm) {
    let data = {
      shortname: shortname
    }
    this.store.
      isProductShortnameUnique(data).subscribe((res: any) => {
        if (!res) {
          this.toaster.error(
            'Oops, Product shortname should be unique!'
          );
          sellItemForm.controls.shortName.setErrors({ 'invalid': true });
        }
      });
  }


  selectedCategoryName: any = '';
  selectedCategoryId: any;
  selectedSubCategoryName: any = '';
  selectedSubCategoryId: any = '';
  selectCategory(event) {

    this.model.subCategory = null;
    this.model.subCategoryname = null;
    this.selectedSubCategoryName = null;
    this.selectedSubCategoryId = null
      ;
    this.cat = this.store.categories$[event];
    this.selectedCategoryName = this.store.categories$[event].categoryName;
    this.selectedCategoryId = this.store.categories$[event].categoryId;
  }
  selectSubCategory(event) {

    this.selectedSubCategoryName = event.subCategoryName;
    this.selectedSubCategoryId = event.subCategoryId;
  }
  stepperGoto(i) {
    this.stepper.selectedIndex = i;
  }
  segmentChanged(event) {
    this.TAB_SELECTED = event.detail.value;
  }

  toggleDisplayDiv() {
    this.pickUpAddressToggle = !this.pickUpAddressToggle;
  }
}
